//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 04/09/2024
// Version : 1
// Description : para editar tanques
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import Title from "../Title";
import { logEventos } from "../../services/services";
import moment from "moment/moment";

function EditarTanque(props) {
  const [datosTanque, setDatosTanque] = useState(props.datos); // viene del motivos_salida {editar}
  const [disBtn, setDisBtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [editar, setEditar] = useState(props.editar); //Estado q mostrará o no el form de edicion de choferes
  const [disabledGuardar, setDisabledGuardar] = useState(false);

  useEffect(() => {
    setDatosTanque({
      ...props.datos,
    });
  }, [props.datos]);

  const handleClose = () => {
    setEditar(!editar);
    if (props.onClose) {
      props.onClose();
    }
  };

  //------------- CALCULO DE FECHAS PROXIMAS --------------------//
  useEffect(() => {
    // ULTIMA REVISION QUINQUENAL 5 AÑOS
    const fechaUltima = moment(datosTanque.fecha_ultima);
    const fechaProxUltima = fechaUltima.add(5, "years").format("YYYY-MM-DD");

    // VALVULA DE SEGURIDAD 2 AÑOS
    const fechaRevValSeg = moment(datosTanque.fecha_rev_val_seg);
    const fechaProxRevValSeg = fechaRevValSeg
      .add(2, "years")
      .format("YYYY-MM-DD");

    // VALVULA DE EXCESO 1 AÑO
    const fechaRevValExc = moment(datosTanque.fecha_rev_val_exc);
    const fechaProxRevValExc = fechaRevValExc
      .add(1, "years")
      .format("YYYY-MM-DD");

    // REVISION DE MANGUERA 6 MESES
    const fechaRevMan = moment(datosTanque.fecha_rev_man);
    const fechaProxRevMan = fechaRevMan.add(6, "months").format("YYYY-MM-DD");

    setDatosTanque((prevDatos) => ({
      ...prevDatos,
      prox_ultima: fechaProxUltima,
      prox_rev_val_seg: fechaProxRevValSeg,
      prox_rev_val_exc: fechaProxRevValExc,
      prox_rev_man: fechaProxRevMan,
    }));
  }, [
    datosTanque.fecha_ultima,
    datosTanque.fecha_rev_val_seg,
    datosTanque.fecha_rev_val_exc,
    datosTanque.fecha_rev_man,
  ]);

  //Funcion para guardar
  const guardarEditar = async (e) => {
    // logEventos("Guardar editar registro", "Cumpleaños", "Se guardo la edición", localStorage.getItem("user"))
    setDisabledGuardar(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanque_rehabilitacion/" + datosTanque.id_tanque,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          matricula: datosTanque.matricula,
          num_auditoria: datosTanque.num_auditoria,
          fabricante: datosTanque.fabricante,
          patente: datosTanque.patente,
          volumen: datosTanque.volumen,
          datos: datosTanque.datos,
          fecha_ultima: datosTanque.fecha_ultima,
          fecha_rev_val_seg: datosTanque.fecha_rev_val_seg,
          fecha_rev_val_exc: datosTanque.fecha_rev_val_exc,
          fecha_rev_man: datosTanque.fecha_rev_man,
          prox_ultima: datosTanque.prox_ultima,
          prox_rev_val_seg: datosTanque.prox_rev_val_seg,
          prox_rev_val_exc: datosTanque.prox_rev_val_exc,
          prox_rev_man: datosTanque.prox_rev_man,
          usuario: localStorage.getItem("user"),
        }),
      }
    );
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };
  //funcion para almacenar
  const almacenar = (e) => {
    setDatosTanque({
      ...datosTanque,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  // console.log("datos editar", datosTanque);

  return (
    <div>
      <Dialog
        open={props.editar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
          titulo={`Editar tanque`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarEditar} autoComplete="off">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="matricula"
                      label="Matrícula"
                      variant="outlined"
                      value={datosTanque.matricula}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="num_auditoria"
                      label="N° Auditoría"
                      variant="outlined"
                      value={datosTanque.num_auditoria}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="fabricante"
                      label="Fabricante"
                      variant="outlined"
                      value={datosTanque.fabricante}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="patente"
                      label="Patente"
                      variant="outlined"
                      value={datosTanque.patente}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="volumen"
                      label="Vol.(m3)"
                      variant="outlined"
                      value={datosTanque.volumen}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="datos"
                      label="Datos"
                      variant="outlined"
                      value={datosTanque.datos}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Última Revisión Quinquenal"
                      name="fecha_ultima"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_ultima).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Última revisión valvulas seguridad"
                      name="fecha_rev_val_seg"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_rev_val_seg).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} mb={3}>
                    <TextField
                      style={style}
                      label="Última revisión válvulas exceso"
                      name="fecha_rev_val_exc"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_rev_val_exc).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6} mb={3}>
                    <TextField
                      style={style}
                      label="Última revisión mangueras"
                      name="fecha_rev_man"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTanque.fecha_rev_man).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default EditarTanque;
