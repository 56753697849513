//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 1/10/2024
// Version : 1
// Description : Grafico de reclamos por motivos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import { PieChart } from "@mui/x-charts/PieChart";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Button from "@mui/material/Button";
import Dialog_motivos from "./Dialog_motivos";

function Grafico_motivos_mobile() {
  const [cantidadXmotivo, setCantidadXmotivo] = useState([]);

  const obtenerCantidadXmotivo = () => {
    fetch(UrlApi + "/reclamos/cantidadPorMotivo")
      .then((data) => data.json())
      .then((data) => setCantidadXmotivo(data));
  };

  useEffect(() => {
    obtenerCantidadXmotivo();
  }, []);

  // console.log("CANTIDAD X MOTIVO", cantidadXmotivo);

  //------------------ Dialog --------------------------//
  const [abrirDialog, setAbrirDialog] = useState(false);

  const handleOpenDialog = () => {
    setAbrirDialog(true);
  };

  const handleCloseDialog = () => {
    setAbrirDialog(false);
  };
  //-------------------------------------------------//

  // Ordenar los datos de mayor a menor por cantidad de reclamos
  const cantidadXmotivoOrdenado = [...cantidadXmotivo].sort(
    (a, b) => b.cantidad_reclamos - a.cantidad_reclamos
  );

  // Calcular el total de reclamos
  const totalReclamos = cantidadXmotivoOrdenado.reduce(
    (total, item) => total + item.cantidad_reclamos,
    0
  );

  // Colores para cada segmento del gráfico
  const colors = [
    "#B8C1AE",
    "#F1A095",
    "#F1D295",
    "#A8CEF2",
    "#90B2EE",
    "#E7EEAA",
    "#AAEEEA",
    "#71F1EA",
    "#EECAD7",
    "#EBA3BC",
    "#D0F1B4",
    "#A6D7A6",
    "#8AB5A9",
    "#F25C47",
    "#4B7EB6",
    "#80B0EC",
    "#BA9BBD",
    "#C7DBD4",
  ];

  // Transformar los datos para el PieChart, incluyendo el color y el porcentaje
  const data = (cantidadXmotivoOrdenado || []).map((item, index) => ({
    label: `${item.descripcion_motivo} (${(
      (item.cantidad_reclamos / totalReclamos) *
      100
    ).toFixed(2)}%)`,
    value: item.cantidad_reclamos,
    color: colors[index % colors.length], // Asignar color basado en el índice
  }));

  // Estilo para el contenedor del gráfico
  const paperStyle = {
    padding: "10px",
    width: "100%", // Ajustar al 100% del ancho disponible
    maxWidth: "800px", // Limitar el ancho máximo a 430px
  };

  // Estilo para el contenedor del título y el botón
  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px", // Ajuste de margen
  };

  return (
    <div style={{ width: "100%", height: "100px", marginTop: 300 }}>
      {" "}
      {/* Ajuste de la altura del contenedor principal */}
      <Paper
        elevation={3}
        style={{
          ...paperStyle,
          flex: 1,
        }}
      >
        <div style={headerStyle}>
          <Typography
            style={{ fontWeight: "bold", fontSize: 20, color: "#333" }} // Ajustar el tamaño de la fuente
          >
            Porcentaje de motivos
          </Typography>
          <Button
            variant="contained"
            size="small"
            endIcon={<FullscreenIcon />}
            onClick={handleOpenDialog}
            style={{ backgroundColor: "#007bff", color: "#fff" }}
          >
            Expandir
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "300px", // Ajustar la altura del gráfico
          }}
        >
          <PieChart
            style={{ width: "200%", height: "100%" }}
            series={[
              {
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "#ddd",
                },
                data,
              },
            ]}
            color={data.map((d) => d.color)}
            slotProps={{
              legend: {
                hidden: true, // Oculta la leyenda predeterminada
              },
            }}
          />
        </div>
        {abrirDialog && (
          <Dialog_motivos
            abrirDialog={abrirDialog}
            onClose={handleCloseDialog}
            data={data}
          />
        )}
      </Paper>
    </div>
  );
}

export default Grafico_motivos_mobile;
