import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  TextField,
  Stack,
  DialogTitle,
  Autocomplete,
  IconButton,
  Button,
  InputAdornment,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { grey } from "@mui/material/colors";
import PinIcon from "@mui/icons-material/Pin";
import moment from "moment";
import Notify from "../../Notify";
import Alerta from "../../Alerta";
import CallIcon from "@mui/icons-material/Call";
import {getData, getCodeArg, postData} from "../../../services/library";

function Addchofer(props) {
  const [datosFormulario, setDatosformulario] = useState({
    id_sisven: null,
    nombre_apellido: null,
    dni: null,
    legajo: null,
    nro_licencia: null,
    vencimiento_licencia: null,
    examen_carga: null,
    usuario: localStorage.getItem("user"),
    activo: 1,
    negocio: null,
    username: null,
    telefono: null,
    pto_vta_hipergas:"",
    pto_vta_autogas:""
  });

  // const [puntosVenta, setPuntosVenta] = useState()
  const [uso, setUso] = useState(null);
  const [usuarios, setUsuarios] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [codArg, setCodArg] = useState("");
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const [ptosVtaHiper, setPtosVtaHiper]=useState();
  const [ptosVtaAuto, setPtosVtaAuto]=useState();

  useEffect(() => {
    getData("/desplegables/usoVehiculo", setUso);
    getData(
      "/choferes/xusernameypuesto/" + datosFormulario.username,
      setUsuarios
    );
    getCodeArg(setCodArg);
    getData("/puntos_venta/seleccionpuntooriginal/1", setPtosVtaHiper)
    getData("/puntos_venta/seleccionpuntooriginal/4", setPtosVtaAuto)
  }, []);

  const almacenar = (e) => {
    setDatosformulario({
      ...datosFormulario,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };

  async function guardarChofer(e){
    setDisBtn(true)
    postData(e, "/choferes/postandupdate", {
      id_sisven: datosFormulario.id_sisven,
      nombre_apellido: datosFormulario.nombre_apellido,
      dni: datosFormulario.dni,
      usuario: datosFormulario.usuario,
      activo: 1,
      legajo: datosFormulario.legajo,
      nro_licencia: datosFormulario.nro_licencia,
      vencimiento_licencia:
        datosFormulario.vencimiento_licencia !== ""
          ? datosFormulario.vencimiento_licencia
          : null,
      examen_carga:
        datosFormulario.examen_carga !== ""
          ? datosFormulario.examen_carga
          : null,
      negocio: datosFormulario.negocio,
      username: datosFormulario.username,
      telefono: datosFormulario.telefono
        ? codArg + datosFormulario.telefono
        : null,
      pto_vta_autogas: datosFormulario.pto_vta_autogas || null,
      pto_vta_hipergas: datosFormulario.pto_vta_hipergas || null,
    }, setNotificacion);
  }

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  return (
    <div>
      <br></br>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Cargar nuevo chofer
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={guardarChofer} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container spacing={1} direction={{ xs: "column", sm: "row" }}>
              <Grid item xs={6}>
                <TextField
                  size={size}
                  placeholder="Nombre completo"
                  style={style}
                  focused
                  color="primary"
                  required
                  name="nombre_apellido"
                  label="Nombre completo"
                  variant="outlined"
                  value={
                    datosFormulario.nombre_apellido &&
                    datosFormulario.nombre_apellido.replace(/\b\w/g, (l) =>
                      l.toUpperCase()
                    )
                  }
                  inputProps={{ maxLength: 100 }}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessibilityNewIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  //required
                  color="primary"
                  placeholder="ID de Sisven"
                  type="number"
                  name="id_sisven"
                  label="ID SISVEN"
                  variant="outlined"
                  value={datosFormulario.id_sisven}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBoxIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  focused
                  placeholder="DNI"
                  color="primary"
                  style={style}
                  type="number"
                  required
                  name="dni"
                  label="DNI"
                  variant="outlined"
                  value={datosFormulario.dni}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  required
                  placeholder="Legajo"
                  type="number"
                  name="legajo"
                  label="Legajo"
                  variant="outlined"
                  value={datosFormulario.legajo}
                  onChange={almacenar}
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  placeholder="Nro licencia"
                  type="number"
                  required
                  name="nro_licencia"
                  label="Nro licencia"
                  variant="outlined"
                  value={datosFormulario.nro_licencia}
                  onChange={almacenar}
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PinIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  type="date"
                  placeholder="Vencimiento licencia"
                  name="vencimiento_licencia"
                  label="Vencimiento licencia"
                  variant="outlined"
                  value={moment(datosFormulario.vencimiento_licencia).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InsertInvitationIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  style={style}
                  focused
                  type="date"
                  placeholder="Exámen cargas"
                  name="examen_carga"
                  label="Examen cargas"
                  variant="outlined"
                  value={moment(datosFormulario.examen_carga).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  style={style}
                  focused
                  color="primary"
                  required
                  size={size}
                >
                  <InputLabel id="lbl_negocio">Negocio</InputLabel>
                  <Select
                    name="negocio"
                    label="Negocio "
                    value={datosFormulario.negocio}
                    onChange={almacenar}
                  >
                    {uso
                      ? uso.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.valor}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  style={style}
                  focused
                  color="primary"
                  required
                  size={size}
                >
                  <InputLabel id="lbl_username">Username</InputLabel>
                  <Select
                    name="username"
                    label="Username"
                    value={datosFormulario.username}
                    onChange={almacenar}
                  >
                    {usuarios
                      ? usuarios.map((elemento) => (
                          <MenuItem
                            key={elemento.username}
                            value={elemento.username}
                          >
                            {elemento.username}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  disabled={!ptosVtaHiper}
                  size={size}
                  focused
                  disableClearable
                  id="puntoventa"
                  noOptionsText={"Punto de Venta NO DISPONIBLE"}
                  options={ptosVtaHiper}
                  autoHighlight
                  getOptionLabel={(ptosVtaHiper) =>
                    ptosVtaHiper.id_punto + "- Suc." + ptosVtaHiper.nombre
                  }
                  onChange={(event, value) =>
                    setDatosformulario({
                      ...datosFormulario,
                      pto_vta_hipergas: value.id_punto,
                    })
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.id_punto} - Suc. {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      focused
                      size={size}
                      color="primary"
                      style={style}
                      variant="outlined"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Punto de Venta HIPERGAS"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  disabled={!ptosVtaAuto}
                  size={size}
                  focused
                  disableClearable
                  id="puntoventa"
                  noOptionsText={"Punto de Venta NO DISPONIBLE"}
                  options={ptosVtaAuto}
                  autoHighlight
                  getOptionLabel={(ptosVtaAuto) =>
                    ptosVtaAuto.id_punto + "- Suc." + ptosVtaAuto.nombre
                  }
                  onChange={(event, value) =>
                    setDatosformulario({
                      ...datosFormulario,
                      pto_vta_autogas: value.id_punto,
                    })
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.id_punto} - Suc. {option.nombre}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      focused
                      size={size}
                      color="primary"
                      style={style}
                      variant="outlined"
                      placeholder="Tipee para buscar..."
                      {...params}
                      label="Punto de Venta AUTOGAS"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size={size}
                  focused
                  placeholder="Teléfono"
                  color="primary"
                  style={style}
                  type="text"
                  required
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  value={datosFormulario.telefono}
                  onChange={almacenar}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{codArg}</InputAdornment>
                    ),
                  }}
                  helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                disabled={disBtn}
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar Chofer
              </Button>
            </Grid>
          </Box>
        </form>
        <br/>
        <br/>
      </Container>
      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={() => {
            handleClose();
          }}
          severity={notificacion.severity}
          duracion={1000}
        />
      ) : null}
    </div>
  );
}

export default Addchofer;
