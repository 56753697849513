import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../../../Navbar";
import {
  Container,
  Typography,
  ListItemIcon,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import EvStationIcon from "@mui/icons-material/EvStation";
import { Delete } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Divider from "@mui/material/Divider";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Comment from "@mui/icons-material/Comment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

//COMPONENTES PROPIOS
import FormBajaCliente from "../baja/FormBajaCliente";
import FichaCliente from "../../datoscliente/FichaCliente"; // modificamos esto para que no se rompa
import AddPedidos4 from "../../pedidos/altapedido/AddPedidos4";
import DialogEdicion from "../modificacion/DialogEdicion";
import ModalMotivoInactivo from "../../abmclientes/baja/ModalMotivoInactivo";
import Title from "../../../../Title";
import { getData, putData } from "../../../../../services/library";
import ConfirmDialog from "../../../../ConfirmDialog";
import NuevoReclamo from "../../../../granel/reclamos/NuevoReclamo";
import TabPanel from "../../abmclientes/alta/TabPanel";
import Saldos from "../../datoscliente/Saldos";

function Clientes() {
  const [clientes, setClientes] = useState(); //aca se almacenan los datos que me trae la api
  const [alta, setAlta] = useState(false); //Estado q mostrará o no la pantalla de alta
  const [tabla, setTabla] = useState(true); //Estado q mostrará o no la tabla (pantalla inicial) dnd estan los clientes
  const [editar, setEditar] = useState(false); //Estado q mostrará o no el form de edicion de clientes
  const [ficha, setFicha] = useState(false); //Estado q mostrará o no el form de edicion de clientes
  const [baja, setBaja] = useState(false); //Abre formulario
  const [addPedido, setAddPedido] = useState(false); //Estado q mostrará o no el form de edicion de clientes
  const [dataCliente, setDataCliente] = useState({});
  const [marcarActivo, setMarcarActivo] = useState(false);
  const [modalMotivo, setModalMotivo] = useState(false);
  const [addReclamo, setAddReclamo] = useState(false);
  const [opensaldo, setopensaldo] = useState(false);

  //Tabla
  const [hiddenmuestrainactivo, setHiddenmuestrainactivo] = useState(false);
  const [hiddenmuestraactivos, setHiddenmuestraactivos] = useState(true);

  //  Funcion para traer los clientes a la tabla : ACTIVOS
  const obtenerclientesInactivos = () => {
    setHiddenmuestrainactivo(true);
    setHiddenmuestraactivos(false);
    getData("/clientes", setClientes);
  };

  //Funcion para traer los clientes a la tabla TODOS :ACTIVOS E INACTIVOS
  const obtenerclientes = () => {
    setHiddenmuestraactivos(true);
    setHiddenmuestrainactivo(false);
    getData("/clientes/activos", setClientes);
  };

  //Use effect para que actualice la tabla cada vez que se marca un cliente como activo/inactivo, y actualizarle el color
  //si no pongo ambos, no se actualiza correctamente
  //Este actualiza cuando edito algo o lo añado
  useEffect(() => {
    obtenerclientes();
  }, [tabla]);

  //Funcion q se ejecuta cuando damos clic a nuevo:
  const handleClickNuevo = () => {
    setAlta(true); //pongo alta en true para q muestre el componente Addcliente
    setTabla(false); //tabla en false para que no se muestre la tabla
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "sisven_id",
        header: "Sisven",
        size: 85,
      },
      {
        accessorKey: "id",
        header: "Cliente",
        size: 85,
      },
      {
        accessorKey: "razon_social",
        header: "Razón Social",
        size: 200,
      },
      {
        accessorKey: "direccion_fiscal",
        header: "Dirección Fiscal",
        size: 200,
      },
      {
        accessorKey: "cuit",
        header: "Cuit/DNI",
        size: 100,
      },
      // {
      //   accessorKey: "sucursal",
      //   header: "Sucursal",
      //   size: 100,
      // },
      {
        accessorKey: "empresa",
        header: "Empresa",
        size: 100,
      },
      {
        accessorKey: "condicion",
        header: "Condicion Pago",
      },
    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 15,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary,
          info: {
            main: "rgb(255,122,0)",
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000",
          },
        },
        typography: {
          button: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  async function setActiveClient(e) {
    putData(
      e,
      "/clientes/" + dataCliente.id,
      {
        activo: 1,
      },
      setNotificacion
    );
    obtenerclientes();
  }

  return (
    <div>
      <Navbar />
      {tabla && clientes ? (
        <Container maxWidth="xl">
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={clientes}
                enableRowActions
                initialState={initialState}
                positionActionsColumn="last"
                muiTableHeadCellProps={tableHeadCellProps}
                localization={MRT_Localization_ES}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    // console.log(row.original)
                      setDataCliente(row.original);
                      setTabla(false);
                      setFicha(true);
                  },
                  sx: {
                      cursor: 'pointer'
                  }
              })}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor:
                      row.original.activo === 1 ? "#b3e6b3" : "#efc3c3",
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={false}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                enableDensityToggle={false}
                layoutMode="grid"
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon color="asd" />}
                      onClick={handleClickNuevo}
                    >
                      Nuevo Cliente
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      <PeopleIcon sx={{ fontSize: 20 }} />
                      Clientes
                    </Typography>
                  </>
                )}
                renderToolbarInternalActions={({ table }) => (
                  <>
                    {!hiddenmuestrainactivo ? (
                      <Tooltip title="Muestra Inactivos">
                        <IconButton
                          onClick={() => obtenerclientesInactivos()}
                          color="error"
                        >
                          <PersonOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {!hiddenmuestraactivos ? (
                      <Tooltip title="Mostrar ACTIVOS" color="success">
                        <IconButton onClick={() => obtenerclientes()}>
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderRowActionMenuItems={({ closeMenu, row, index }) => [
                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? null : "none",
                    }}
                    disabled={row.original.activo === 1}
                    onClick={() => {
                      closeMenu();
                      setDataCliente(row.original);
                      setMarcarActivo(true);
                      console.log("Quiero volvr a poner activo");
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="success" />
                    </ListItemIcon>
                    Marcar Activo
                  </MenuItem>,

                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? "none" : null,
                    }}
                    disabled={row.original.activo === 0}
                    onClick={() => {
                      closeMenu();
                      setDataCliente(row.original);
                      setEditar(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <BorderColorIcon color="primary" />
                    </ListItemIcon>
                    Editar
                  </MenuItem>,

                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? "none" : null,
                    }}
                    disabled={row.original.activo === 0}
                    onClick={() => {
                      closeMenu();
                      setDataCliente(row.original);
                      setBaja(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Delete color="error" />
                    </ListItemIcon>
                    Marcar Inactivo
                  </MenuItem>,

                  <Divider light />,

                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? null : "none",
                    }}
                    disabled={row.original.activo === 1}
                    onClick={() => {
                      closeMenu();
                      setDataCliente(row.original);
                      setModalMotivo(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Comment color="primary" />
                    </ListItemIcon>
                    Ver Motivo
                  </MenuItem>,

                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? "none" : null,
                    }}
                    disabled={row.original.activo === 0}
                    onClick={() => {
                      closeMenu();
                      setDataCliente(row.original);
                      setTabla(false);
                      setFicha(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <RemoveRedEyeIcon color="success" />
                    </ListItemIcon>
                    Administrar Datos De Cliente
                  </MenuItem>,

                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? "none" : null,
                    }}
                    disabled={row.original.activo === 0}
                    onClick={() => {
                      closeMenu();
                      setTabla(false);
                      setAddPedido(true);
                      setDataCliente(row.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <EvStationIcon color="secondary" />
                    </ListItemIcon>
                    Cargar Pedido
                  </MenuItem>,

                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? "none" : null,
                    }}
                    disabled={row.original.activo === 0}
                    onClick={() => {
                      closeMenu();
                      setTabla(false);
                      setAddReclamo(true);
                      setDataCliente(row.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <PendingActionsIcon color="error" />
                    </ListItemIcon>
                    Cargar Reclamo
                  </MenuItem>,
                  <MenuItem
                    style={{
                      display: row.original.activo === 0 ? "none" : null,
                    }}
                    disabled={row.original.activo === 0}
                    onClick={() => {
                      closeMenu();
                      setTabla(true);
                      setopensaldo(true);
                      setDataCliente(row.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <ReceiptLongIcon color="success" />
                    </ListItemIcon>
                    Visualizar saldos
                  </MenuItem>,
                ]}
              />
            </ThemeProvider>
          </Box>
          <br />
          <br />
        </Container>
      ) : null}
      {alta ? <TabPanel alta={setAlta} tabla={setTabla} /> : null}

      {baja ? (
        <FormBajaCliente
          open={baja}
          setOpen={setBaja}
          cliente={dataCliente}
          tabla={setTabla}
          actualizar={obtenerclientes}
        />
      ) : null}

      {editar ? (
        <DialogEdicion
          open={editar}
          setOpen={setEditar}
          cliente={dataCliente}
          actualizar={obtenerclientes}
        />
      ) : null}

      {ficha ? (
        <FichaCliente
          ficha={setFicha}
          tabla={setTabla}
          datos={dataCliente} //En realidad esto seria "cliente", como en los otro ternarios.
          actualizar={obtenerclientes}
        />
      ) : null}

      {addPedido ? (
        <Container maxWidth="lg">
          <Title
            titulo={`Nuevo Pedido: ${dataCliente.razon_social}`}
            handleClose={() => {
              setAddPedido(false);
              setTabla(true);
            }}
          />
          <AddPedidos4
            datosCli={dataCliente}
            addPedido={setAddPedido}
            tabla={setTabla}
          />
        </Container>
      ) : null}

      {marcarActivo ? (
        <ConfirmDialog
          open={marcarActivo}
          setOpen={setMarcarActivo}
          title={"Marcar Cliente Activo"}
          text={"Confirme que desea volver a activar este cliente."}
          notificacion={notificacion}
          setNotificacion={setNotificacion}
          guardar={setActiveClient}
        />
      ) :
      null}
      {modalMotivo ? (
        <ModalMotivoInactivo
          open={modalMotivo}
          setOpen={setModalMotivo}
          cliente={dataCliente}
        />
      ) : null}
      {addReclamo ? (
        <Container maxWidth="lg">
          <Title
            titulo={`Nuevo Reclamo: ${dataCliente.razon_social}`}
            handleClose={() => {
              setAddReclamo(false);
              setTabla(true);
            }}
          />
          <NuevoReclamo
            dataCliente={dataCliente}
            addReclamo={setAddReclamo}
            tabla={setTabla}
            handleClose={() => {
              setAddReclamo(false);
              setTabla(true);
            }}
          />
        </Container>
      ) : null}
      {opensaldo ? (
        <Saldos
          open={opensaldo}
          setopen={setopensaldo}
          dataCliente={dataCliente}
          setDataCliente={setDataCliente}
          tabla={setTabla}
        />
      ) : null}
    </div>
  );
}

export default Clientes;
