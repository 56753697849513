import React from "react";
import { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../../../services/apirest";
import { IconButton, Autocomplete, Tooltip, Grid, InputLabel, Button, InputAdornment, Box, TextField, Select, FormControl, MenuItem } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";
import DialogRepartos from "./DialogRepartos";
import ModalAddlistaprecio from "./ModalAddlistaprecio";
import AddPrecioEspecialDireccion from '../../../../lista_precios/granel/preciosEspeciales/AddPrecioEspecialDireccion';
import ModalRepetido2 from "./ModalRepetido2";
import ModalInfoDireccion from "./ModalInfoDireccion";
import DialogPHVencida from "./DialogPHVencida";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormTanqueRapido from "./FormTanqueRapido";
import BarraDatosPedido from "./BarraDatosPedido";
import Notify from "../../../../Notify";
import Alerta from "../../../../Alerta";
import AlertWButtonCapYPH from "./AlertWButtonCapYPH"
import AlertRepartoAsignado from "./AlertRepartoAsignado"
import { enviarsms } from "../../../../../services/services"
import VerificacionDomicilio from "./Mapas/VerificacionDomicilio"

function AddPedidos4(props) {
  //Datos del pedido
  const [datosPedido, setDatosPedido] = useState({
    id_origen: 30,
    cantidad: "",
    porcentaje: "",
    pesos: "",
    id_estado: "",
    id_cliente: props.datosCli.id,
    id_direccionentrega: "",
    id_tanque: "",
    fecha_requerida: "",
    observaciones: "",
    usuario: localStorage.getItem("user"),
    kgacargar: "",
    prioritario: 0,
    fecha_probable_entrega: "",
    nro_reparto: "",
    tqobat: "",
    id_bateria: "",
    idsucursal_atiende: "",
    precio_iva: "",
    precio_siniva: "",
    uni_fact: ""
  });
  //Condicion de pago 1 (contado),2 (pago anticipado), 3 (transferencia) --> Arranca aprobado(21). Si el pedido se asigna a un reparto, pasa a asignado(23)
  //Si el cliente
  // const [cliContado, setCliContado] = useState(
  //   props.datosCli.id_condicionpago === 1 ||
  //     props.datosCli.id_condicionpago === 2 ||
  //     props.datosCli.id_condicionpago === 5
  // ); // Si es verdadero, setea cliContado en true para luego poder preguntar en el seteo del estado.

  //Desplegables
  const [origenPedido, setOrigenPedido] = useState([{}]);
  const [direcciones, setDirecciones] = useState([{}]);
  const [tanques, setTanques] = useState([{}]); // aca guardo los tanques que me traigo
  const [sucursales, setSucursales] = useState([{}]);

  const [showModalMapa, setShowModalMapa] = useState(false);

  //Seleccionados
  const [direccion, setDireccion] = useState(""); //Aca se guardaran TODOS los datos de la direccion de entrega del pedido, para poder mostrarlos en pantalla.
  const [tanqueSel, setTanqueSel] = useState({
    //Aqui se inicializan los datos que vamos a utilizar del tanque al que se le cargará el pedido.
    capacidad: "",
    delivery_stop: "",
    id_direccionentrega: "",
    id_tanque: "",
    idbateria: "",
    nro: "",
    nro_serie: "",
    vto_pruebahidraulica: "",
  });

  //Si selecciono un tanque, necesitaré chequear si ese tanque está solo o en batería,
  const [tanquesxbat, setTanquesxbat] = useState(null); //Acá guardo todos los tanques que están en batería.
  const [pedidoRepetido, setPedidoRepetido] = useState();

  //REPARTOS
  const [dataDelivery, setDataDelivery] = useState({}); // GUARDARA LOS DATOS DEL REPARTO QUE SE UTILIZARÁ O NO
  const [isNewDelivery, setIsNewDelivery] = useState(false); //este booleano, indicará el si el reparto que se usará es nuevo o no para hacer el post a repartos o no.

  //Mostrar o no pantallas.
  const [showAltaTanque, setShowAltaTanque] = useState(false); //Pone en true o false la pantalla de carga de un nuevo tanque.
  const [dialogReparto, setDialogReparto] = useState(); //Muestra o no el formulario para asignar el pedido a un reparto.
  const [showInfoDireccion, setShowInfoDireccion] = useState(false);
  const [PHVencida, setPHVencida] = useState(false);
  const [noPH, setNoPH] = useState(false);

  //Manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [colormensaje, setColorMensaje] = useState(""); //define el color que tendrá la notificación
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [deshabilitarGuardar, setDeshabilitarGuardar] = useState(false);
  const [segmentos, setSegmentos] = useState([]);
  const [segSelected, setSegselectd] = useState(null);

  const selectedSegmento = useMemo(() => {
    return segmentos ? segmentos.find((s) => s.id === props.datosCli.id_segmento) : null;
  }, [props.datosCli.id_segmento, segmentos]);

  useEffect(() => {
    if (selectedSegmento) {
      setSegselectd(selectedSegmento);
      // console.log("Selected segmento:", selectedSegmento);
    }
  }, [selectedSegmento]);

  useEffect(() => {
    fetch(UrlApi + "/segmentos")
      .then((data) => data.json())
      .then((data) => setSegmentos(data));
  }, []);


  //Obtengo datos de la api para rellenar los select
  const obtenerOrigen = () => {
    fetch(UrlApi + "/desplegables/origenpedido")
      .then((data) => data.json())
      .then((data) => setOrigenPedido(data));
  };

  //Obtengo direcciones filtrando por id de cliente
  const obtenerDirecciones = () => {
    fetch(UrlApi + "/direccionesentrega/cli/" + props.datosCli.id)
      .then((data) => data.json())
      .then((data) => setDirecciones(data));
  };

  //obtengo los tanques filtrando por direccion de entrega
  const obtenerTanques = () => {
    fetch(UrlApi + "/tanques/dirent/" + direccion.id)
      .then((data) => data.json())
      .then((data) => setTanques(data));
  };

  //Buscar si el pedido está repetido en caso de que sea para un solo tanque:
  const buscarPedidoRepeTq = () => {
    fetch(UrlApi + "/pedidos/repe/" + datosPedido.id_cliente + "/" + direccion.id + "/" + tanqueSel.id_tanque)
      .then((data) => data.json())
      .then((data) => setPedidoRepetido(data));
  };

  const buscarPedidoRepeBat = () => {
    fetch(UrlApi + "/pedidos/bat/" + tanqueSel.idbateria)
      .then((data) => data.json())
      .then((data) => setPedidoRepetido(data)); //Guardo el pedido repetido
  };

  //obtengo los sucursales
  const obtenerSucursales = () => {
    fetch(UrlApi + "/sucursales/tipoPlanta")
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerOrigen();
    obtenerSucursales();
  }, []);

  //Escuchando el id del cliente, obtengo las direcciones
  useEffect(() => {
    obtenerDirecciones();
  }, [props.datosCli.id]);

  //   Cada vez que la dirección seleccionada cambia:
  //     - obtenemos los tanques que tenemos en ese domicilio
  //     - reiniciamos los valores cantidad y porcentaje
  //     - reiniciamos los valores del tanque seleccionado
  useEffect(() => {
    setDataDelivery({}) //Cada vez qe cambio la dirección de entrega, reinicio los datos del delivery
    setIsNewDelivery(false)
    direccion && obtenerTanques(); //Una vez que tengo la dirección, obtengo los tanques
    datosPedido.porcentaje = "";
    datosPedido.cantidad = "";
    setTanqueSel(Object.fromEntries(Object.keys(tanqueSel).map((clave) => [clave, ""]))); //Inicio de nuevo el json con sus claves pero con los valores vacios
    setCapMaxKg(null)
    setTanquesxbat(null)
    setDatosPedido({ ...datosPedido, idsucursal_atiende: direccion.id_sucursal })
  }, [direccion]);

  //***************************************************************************************************/

  //Busco LOS TANQUES QUE ESTÁN EN LA MISMA BATERÍA QUE TIENE EL TANQUE SELECCIONADO.
  const obtenerTqXBat = () => {
    // console.log("Voy a buscar los otros tanques que hay en esta batería.")
    fetch(UrlApi + "/tanques/bateria/" + tanqueSel.idbateria)
      .then((data) => data.json())
      .then((data) => setTanquesxbat(data));
    // console.log(UrlApi + "/tanques/bateria/" + tanqueSel.idbateria);
  };

  const [capMaxKg, setCapMaxKg] = useState(null); // Capacidad maxima del tanque o la bateria en Kg
  const [disCant, setDisCant] = useState(false); //Habilita o deshabilita el TextField para ingreso de cantidad d kg
  const [kgCarga, setkgCarga] = useState(null); // Acá se va a guardar el resultado del cálculo, es decir, los kg q habrá que cargarle al cliente.
  const [tksSinCap, setTksSinCap] = useState([]); //Cuando se use una batería de tanque, acá se guardarán los nros d serie de los tanques que no tienen capacidad registrada.
  const [tksSinPHOVencida, setTksSinPHOVencida] = useState([]); // Este estado guarda los nro_serie de aquellos tanques en batería que no tengan la prueba hidraulica, o la tengan vencida.
  const [disPorc, setDisPorc] = useState(false);

  useEffect(() => {
    //si o si: 
    setCapMaxKg(null)
    setkgCarga(null);
    setDatosPedido({ ...datosPedido, porcentaje: "", cantidad: "" });
    setTksSinCap([]) //Esto
    setTksSinPHOVencida([]) //Esto

    const hayValores = Object.values(tanqueSel).some((value) => !!value); //Acá guardo si hay valores o no en tanqueSel
    if (hayValores) {
      checkTq();
    }
  }, [tanqueSel]);


  //como trabaja esto --> en primer lugar, tengo un useEffect que escucha a tanqueSel. si el json tanqueSel tiene algun valor, entonces va a hacer el chequeo del tanque. 
  // en ese chequeo, lo primero que hace es fijarse si el tanque tiene idbateria (para saber si está en una batería con otros tanques). 
  //si el tanque está en batería, entonces 


  //Función dónde compruebo todos los datos del tanque:
  // 1- Si está en bateria --> Busco los otros tanques de la bateria
  // 2- Si tiene vencimiento de PH registrada. la tiene -> comprobar q no esté vencida. no la tiene --> pedir q se cargue
  // 3- Si tiene capacidad  registrada
  const checkTq = () => {
    // setCapMaxKg(null);
    // setDatosPedido({ ...datosPedido, porcentaje: "", cantidad: "" });
    // setkgCarga(null);
    // setTksSinCap([]);
    // setTanquesxbat(null);

    // SI EL TANQUE ESTÁ EN BATERIA, voy a buscar los datos d los otros q se encuentran en la misma batería
    if (tanqueSel.idbateria) {
      // console.log("El tanque está en bateria.")
      // console.log("IdBateria: " + tanqueSel.idbateria)
      obtenerTqXBat(); //obtiene los tanques x bateria y los guarda en tanquesxbat
      buscarPedidoRepeBat();
      // SI EL TANQUE NO ESTÁ EN BATERÍA
    } else {
      buscarPedidoRepeTq();
      //COMPRUEBO SI TIENE REGISTRADA LA CAPACIDAD
      if (!tanqueSel.capacidad || tanqueSel.capacidad === 0) {
        //Si el tanque no tiene capacidad registrada, debo deshabilitar el ingreso de %
        setDisCant(false);
        setDisPorc(true);
      } else {
        //El tanque tiene capacidad registrada
        setDisCant(false);
        setDisPorc(false);
        setCapMaxKg(tanqueSel.capacidad * 1 * 1000 * 0.85 * 0.52); // Calculo la capacidad maxima del tanque en kg
      }
    }

    //COMPRUEBO EL VENCIMIENTO DE LA PRUEBA HIDRÁULICA
    const today = moment(new Date()).format("YYYY-MM-DD");
    // console.log("Today: " + today);
    if (tanqueSel.vto_pruebahidraulica) {
      if (tanqueSel.vto_pruebahidraulica < today) {
        setPHVencida(true);
      }
    } else {
      //Si no hay prueba hidráulica registrada:
      setNoPH(true);
    }
  };

  useEffect(() => {
    tanquesxbat && tanquesxbat.length > 0 && checkBat();
  }, [tanquesxbat]);


  //aca lo q falta seria q distinga nro d serie y fabricante tb, porqe si hay 2 tanques con el mismo nro de serie pero de distinto fabricante,
  // que no tienen cargada su capacidad o PH, o la tienen vencida, solo muestra 1.

  const checkBat = () => {
    // console.log("Voy a hacer un chequeo de los tanques que tengo en la bateria..")
    const today = moment(new Date()).format("YYYY-MM-DD");
    tanquesxbat.map((tanque) => {
      if (!tanque.capacidad || (tanque.capacidad === 0)) {
        // console.log("tanque que no tiene capacidad: " + tanque.nro_serie)
        tksSinCap &&
          (!tksSinCap.includes(tanque.nro_serie)) &&
          tksSinCap.push(tanque.nro_serie);
      }
      if (!tanque.vto_pruebahidraulica || tanque.vto_pruebahidraulica < today) {
        // Si no hay prueba hidráulica en algún tanque
        // console.log("tanq q no tiene PH o se vencio: " + tanque.nro_serie)
        tksSinPHOVencida &&
          (!tksSinPHOVencida.includes(tanque.nro_serie)) &&
          tksSinPHOVencida.push(tanque.nro_serie);
      }
    });
    // Seteo la capacidad máxima de kg que le puedo cargar al pedido (capacidad al 85%)
    setCapMaxKg(
      tanquesxbat
        .map((item) => item.capacidad * 1 * 1000 * 0.85 * 0.52)
        .reduce((acumulador, valorActual) => acumulador + valorActual, 0)
        .toFixed(2)
    );
  };

  const calcularKGCantidad = () => {
    if (datosPedido.cantidad) {
      setkgCarga(datosPedido.cantidad);
      setDatosPedido({ ...datosPedido, porcentaje: "" });
      setDisPorc(true);
    } else {
      setDisPorc(false);
    }
  };

  const calcularKGPorc = () => {
    if (datosPedido.porcentaje) {
      if (tanqueSel && tanqueSel.idbateria && tanquesxbat) {
        // SI EL TANQUE ESTÁ EN BATERÍA
        setkgCarga(
          tanquesxbat
            .map(
              (item) =>
                (item.capacidad * 1 * 1000 * 0.85 -
                  item.capacidad *
                  1 *
                  1000 *
                  ((datosPedido.porcentaje * 1) / 100)) *
                0.52
            )
            .reduce((acumulador, valorActual) => acumulador + valorActual, 0)
            .toFixed(0)
        );
      }
      if (tanqueSel && !tanqueSel.idbateria) {
        //Si el tanque no está en batería, calculo los kg q debo cargar de la siguiente manera:
        setkgCarga(
          (
            (tanqueSel.capacidad * 1 * 1000 * 0.85 -
              tanqueSel.capacidad *
              1 *
              1000 *
              ((datosPedido.porcentaje * 1) / 100)) *
            0.52
          ).toFixed(0)
        );
      }
    } else {
      setDisCant(false);
    }
  };

  useEffect(() => {
    calcularKGCantidad();
  }, [datosPedido.cantidad]);

  useEffect(() => {
    calcularKGPorc();
  }, [datosPedido.porcentaje]);

  useEffect(() => {
    if (!datosPedido.porcentaje && !datosPedido.cantidad) {
      setkgCarga(null);
    }
  }, [datosPedido.porcentaje, datosPedido.cantidad]);

  //---------------------------------------------------------------------------------------------------------------------------------------------------------//
  //Funcion para almacenar los datos DEL PEDIDO
  const almacenar = (e) => {
    setDatosPedido({
      ...datosPedido,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------------//
  //FUNCION PARA GUARDAR UN PEDIDO
  async function guardarPedido(e) {
    setDeshabilitarGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/pedidos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_origen: datosPedido.id_origen,
        cantidad: datosPedido.cantidad === "" ? null : datosPedido.cantidad,
        porcentaje:
          datosPedido.porcentaje === "" ? null : datosPedido.porcentaje,
        // id_estado: cliContado ? (dataDelivery.nro_reparto ? 23 : 21) : 19,
        id_estado: 19,
        id_cliente: props.datosCli.id,
        id_direccionentrega: direccion.id,
        id_tanque: tanqueSel.id_tanque,
        fecha_probable_entrega: dataDelivery.fecha_salida_reparto
          ? dataDelivery.fecha_salida_reparto
          : null,
        fecha_requerida: datosPedido.fecha_requerida === "" ? null : datosPedido.fecha_requerida,
        observaciones: datosPedido.observaciones === "" ? null : datosPedido.observaciones,
        usuario: localStorage.getItem("user"),
        kgacargar: kgCarga,
        nro_reparto: dataDelivery.nro_reparto ? dataDelivery.nro_reparto : null,
        id_bateria:
          tanquesxbat && tanquesxbat.length > 0 ? tanqueSel.idbateria : null,
        idsucursal_atiende: datosPedido.idsucursal_atiende,
        kgacargar_original: kgCarga,
        precio_iva: datosPedido.precio_iva,
        precio_siniva: datosPedido.precio_siniva,
        uni_fact: props.datosCli.unidad_fact
      }),
    });
    if (response.ok === false) {
      //NO GUARDO
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          if (json.message === "OK") {
            if (isNewDelivery) {
              //Si hay un nuevo reparto, entonces voy a ejecutar la funcion para guardar ese reparto.
              // console.log("hay un nuevo reparto");
              guardarReparto(e);
            } else {
              setNotificacion(true);
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setMensaje("Guardado");
              setSeveritySnack("success");
              //aca pasa cuando se guarda bien el pedido y tengo qe preguntar si está asignado a un reparto para enviar el sms
              dataDelivery.nro_reparto && dataDelivery.id_estadoreparto === 8 && dataDelivery.telefono_choferinicia && enviarsms(dataDelivery.telefono_choferinicia, `Nuevo pedido para: ${props.datosCli.razon_social.trim()}. \n Refresque App.`)
            }
          } else {
            if (isNewDelivery) {
              // console.log("A continuacion debo guardar el reparto");
              //Si hay un nuevo reparto, entonces voy a ejecutar la funcion para guardar ese reparto.
              // guardarReparto(e);
            } else {
              setNotificacion(true);
              setOpenSnack(true);
              setColorMensaje("#D4EFDF");
              setSeveritySnack("error");
              setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
            }
          }
        } else if (json.sqlMessage) {
          // NO GUARDO
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        //GUARDO
        if (error.toString().includes("is not valid JSON")) {
          // guardarReparto(e);
          // console.log("A continuacion debo guardar el reparto");
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  };

  async function guardarReparto(e) {
    const response = await fetch(UrlApi + "/repartos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        usuario: localStorage.getItem("user"),
        id_vehiculo: dataDelivery.id_vehiculo,
        fecha_salida_reparto: dataDelivery.fecha_salida_reparto,
        nro_reparto: dataDelivery.nro_reparto,
        estado: 6,
        id_sucursal: direccion.id_sucursal,
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------------//
  //Estados para pantalla
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const handleClose = () => {
    if (props.vengo === "PedidosCli") {
      props.alta(false);
      props.tabla(true);
    } else {
      props.addPedido(false);
      props.tabla(true);
    }
  };

  const [precio, setPrecio] = useState([]); //donde guardo la lista de precios que obtengo del back
  const [precioIva, setPrecioIva] = useState(0); //para guardar el calculo del precio con iva
  const [precionormal, setPrecioNormal] = useState(0); // para guardar el calculo del precio sin iva
  const [dialogPrecioEspecial, setdialogPrecioEspecial] = useState(false); //si tiene precio especial pero no está cargado  
  const [dialogListaPrecio, setdialogListaPrecio] = useState(false); //si no tiene precio vigente
  const [cambieDir, setCambieDir] = useState(false)
  const [dialogPrecioEspecialCerrado, setDialogPrecioEspecialCerrado] = useState(false);
  const [dialogListaPrecioCerrado, setDialogListaPrecioCerrado] = useState(false);

  const getPrecios = async () => {
    if (!direccion) return;

    try {
      let url;
      // console.log("url", `/precios_especiales/vigente/cliente/${props.datosCli.id}`);
      
      if (props.datosCli.precio_especial === 1) {
        // console.log("entro en precio especuial");
        
        // if (dialogPrecioEspecialCerrado) return; // No continuar si el diálogo ya fue cerrado
        url = `/precios_especiales/vigente/cliente/${props.datosCli.id}`;
        
        const response = await fetch(UrlApi + url);
        const data = await response.json();
        setPrecio(data);

        if (data.length === 0 && !dialogPrecioEspecialCerrado) {
          setdialogPrecioEspecial(true);
        }
      }
      else {
        // console.log("ento en precio comun");
        
        url = `/lista_precios/fechavigente/${props.datosCli.id_empresa}/${direccion.id_sucursal}/${props.datosCli.id_segmento}/${direccion.id_producto}`;
        const response = await fetch(UrlApi + url);
        const data = await response.json();
        setPrecio(data);

        if (data.length === 0 && !dialogListaPrecioCerrado) {
          setdialogListaPrecio(true);
        }
      }
    } catch (error) {
      console.error('Error obteniendo precios:', error);
      if (!dialogListaPrecioCerrado) {
        setdialogListaPrecio(true);
      }
    }
  };

  console.log("precio", precio);
  

  const calcularPrecios = () => {
    //esta funcion la tengo que modificar porque en caso de que no haya ya directamente voy a poder abrir el modal para cargar precio segun sea necesario
    let precioConIva = 0;
    let precioSinIva = 0;

    if (precio && precio.length > 0) {
      if (props.datosCli.unidad_fact === 215) {
        precioConIva = precio[0].precio_kilo_con_iva ?? precio[0].kg_con_iva;
        precioSinIva = precio[0].precio_kilo_sin_iva ?? precio[0].kg_sin_iva;

        if (precioConIva != null && precioSinIva != null) {
          setPrecioIva(precioConIva * kgCarga);
          setPrecioNormal(precioSinIva * kgCarga);
        }
      } else if (props.datosCli.unidad_fact === 216) {
        let cantidadLitros = null;

        // Determinar densidad en función del id_empresa
        if (props.datosCli.id_empresa === 1) {
          cantidadLitros = kgCarga / direccion.densidad_hipergas;
        } else {
          cantidadLitros = kgCarga / direccion.densidad_autogas;
        }

        precioConIva = precio[0].precio_litro_con_iva ?? precio[0].lt_con_iva;
        precioSinIva = precio[0].precio_litro_sin_iva ?? precio[0].lt_sin_iva;

        if (precioConIva != null && precioSinIva != null) {
          setPrecioIva(precioConIva * cantidadLitros);
          setPrecioNormal(precioSinIva * cantidadLitros);
        }
      }

      // Guardar en datosPedido
      setDatosPedido((prevDatosPedido) => ({
        ...prevDatosPedido,
        precio_iva: precioConIva,
        precio_siniva: precioSinIva,
      }));
    }
  };

  useEffect(() => {
    if (direccion) {
      getPrecios();
      // console.log("asjoaoks");

    }
  }, [props.datosCli.precio_especial, direccion, dialogListaPrecio, dialogPrecioEspecial, cambieDir]);

  useEffect(() => {
    if (precio.length > 0 && direccion) {
      calcularPrecios();
    }
  }, [precio, kgCarga]);

  const onCloseDialog = () => {
    setDialogPrecioEspecialCerrado(true);
    setdialogPrecioEspecial(false);
    // getPrecios()
  };

  const handleCloseDialogListaPrecio = () => {
    setDialogListaPrecioCerrado(true);
    setdialogListaPrecio(false);
    // getPrecios()
  };

  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          handleClose={handleClose}
          severity={severitySnack}
        />
      ) : null}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={"error"} />
      ) : null}

      <BarraDatosPedido
        tanqueSel={tanqueSel}
        tanquesxbat={tanquesxbat}
        tksSinCap={tksSinCap}
        capMaxKg={capMaxKg}
        kgCarga={kgCarga}
        sucursal={direccion.sucursal}
        condicionPago={props.datosCli.condicion}
        direccion={direccion}
        precioIva={precioIva}
        precionormal={precionormal}
        segmento={props.datosCli.segmento}
        segmentos={segmentos}
        datosCli={props.datosCli}
        getPrecios={getPrecios}
        segSelected={segSelected}
        setSegselectd={setSegselectd}
      />
      {tksSinCap.length !== 0 || tksSinPHOVencida.length !== 0 ? (
        <AlertWButtonCapYPH
          tksSinCap={tksSinCap}
          setTksSinCap={setTksSinCap}
          tksSinPHOVencida={tksSinPHOVencida}
          setTksSinPHOVencida={setTksSinPHOVencida}
        />
      ) : null}
      {tanqueSel && !tanqueSel.idbateria && tanqueSel.capacidad === 0 ? (
        <Alerta
          titulo={"Atención!"}
          mensaje={
            "El tanque seleccionado no tiene capacidad registrada. Solo será posible ingresar la cantidad de KG solicitados."
          }
          severity={"warning"}
        />
      ) : null}

      <form onSubmit={guardarPedido}>
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <Grid container spacing={2} alignItems="center">
            {/* <Grid item xs={3}>
                <FormControl style={style} focused required>
                  <InputLabel>Origen Pedido</InputLabel>
                  <Select
                    name="id_origen"
                    value={datosPedido.id_origen}
                    label="Origen Pedido"
                    onChange={almacenar}
                  >
                    {origenPedido
                      ? origenPedido.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}
            <Grid item xs={6.5}>
              {/* <FormControl style={style} focused required>
                  <InputLabel>Direccion De Entrega</InputLabel>
                  <Select
                    name="direccion"
                    value={direccion}
                    label="Dirección de entrega"
                    onChange={(e) => setDireccion(e.target.value)}
                  >
                    {direcciones
                      ? direcciones.map((direccion) => (
                          <MenuItem key={direccion.id} value={direccion}>
                            {direccion.direccion_entrega} -{" "}
                            {direccion.nombre_direccion}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl> */}
              <Autocomplete
                disableClearable
                disabled={!direcciones}
                options={direcciones}
                onChange={(event, newValue) => {
                  setDireccion(newValue)
                  setCambieDir(true)
                  // Reiniciar los estados de los diálogos para que puedan abrirse nuevamente si es necesario
                  setDialogPrecioEspecialCerrado(false);
                  setDialogListaPrecioCerrado(false);

                  // Reiniciar la bandera de precios consultados para forzar la nueva consulta
                  // setPreciosConsultados(false);
                }}
                getOptionLabel={(direccion) => direccion.direccion_entrega}
                // getOptionDisabled={(tanque) => tanque.delivery_stop === 1}
                renderInput={(params) => (
                  <TextField
                    required
                    style={style}
                    focused
                    {...params}
                    label="Dirección"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1.5}>
              <Button
                variant="text"
                size="small"
                onClick={() => setShowModalMapa(true)}
                disabled={!direccion}
              >
                {direccion.verificada === 0
                  ? "Verificar Domicilio"
                  : "Ver Domicilio"}
              </Button>

            </Grid>
            {/* AUTOCOMPLETE */}
            <Grid item xs={3}>
              <Autocomplete
                disableClearable
                disabled={!direccion}
                value={tanqueSel}
                groupBy={(option) => (option.nro ? option.nro : "Sin Bateria")}
                onChange={(event, newValue) => {
                  setTanqueSel(newValue);
                }}
                options={tanques}
                getOptionLabel={(tanque) => tanque.nro_serie}
                getOptionDisabled={(tanque) => tanque.delivery_stop === 1}
                renderInput={(params) => (
                  <TextField
                    required
                    style={style}
                    focused
                    {...params}
                    label="Tanque"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={0.5}>
              <Tooltip title="Crear Nuevo Tanque En Domicilio">
                <IconButton
                  disabled={!direccion}
                  size="large"
                  color="primary"
                  onClick={() => {
                    setShowAltaTanque(true);
                    setTanqueSel({
                      capacidad: "",
                      delivery_stop: "",
                      id_direccionentrega: "",
                      id_tanque: "",
                      idbateria: "",
                      nro: "",
                      nro_serie: "",
                      vto_pruebahidraulica: "",
                    });
                  }}
                >
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={style}
                required={
                  (!datosPedido.cantidad && !datosPedido.porcentaje) ||
                  datosPedido.cantidad
                }
                disabled={datosPedido.porcentaje || dataDelivery.nro_reparto}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 5,
                  readOnly: !tanqueSel.nro_serie,
                }} //solo 2 numeros
                // NO CAMBIAR. ESTA BIEN
                //ver q pasa cuando no hay capoacidades y tengo q caargar cantidad
                error={
                  datosPedido.cantidad &&
                  tanqueSel &&
                  tanqueSel.capacidad &&
                  (datosPedido.cantidad * 1 === 0 ||
                    datosPedido.cantidad * 1 > capMaxKg * 1)
                }
                helperText={
                  datosPedido.cantidad &&
                    tanqueSel &&
                    tanqueSel.capacidad &&
                    (datosPedido.cantidad * 1 === 0 ||
                      datosPedido.cantidad * 1 > capMaxKg * 1)
                    ? `Máximo: ${capMaxKg} kg. Mínimo: 0 kg.`
                    : ""
                }
                focused
                color="primary"
                placeholder="Cantidad en kg"
                name="cantidad"
                label="Cantidad"
                onChange={almacenar}
                value={datosPedido.cantidad}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">kg</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={style}
                required={
                  (!datosPedido.cantidad && !datosPedido.porcentaje) ||
                  datosPedido.porcentaje
                } // es requerido cuando no esta ninguna de las dos
                disabled={
                  datosPedido.cantidad ||
                  !tanqueSel.capacidad ||
                  dataDelivery.nro_reparto ||
                  (tanqueSel.idbateria && tksSinCap && tksSinCap.length > 0)
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 2,
                  readOnly: !tanqueSel.nro_serie,
                }} //solo 2 numeros
                focused
                color="primary"
                placeholder="Porcentaje"
                name="porcentaje"
                error={datosPedido.porcentaje >= 85}
                helperText={
                  datosPedido.porcentaje >= 85 ? "Debe ser menor al 85%" : ""
                }
                label="Porcentaje"
                onChange={almacenar}
                value={datosPedido.porcentaje}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={3}>
                <TextField
                  style={style}
                  disabled={true} // Para habilitar cuando haya una listad de precios, tengo q pasar disabled={disabledpesos}
                  type="number"
                  focused
                  color="primary"
                  placeholder="Pesos"
                  name="pesos"
                  id="pesos"
                  label="Pesos "
                  onChange={almacenar}
                  value={datosPedido.pesos}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
            <Grid item xs={3}>
              <TextField
                style={style}
                focused
                color="primary"
                id="date"
                label="Fecha requerida de entrega"
                name="fecha_requerida"
                type="date"
                onChange={almacenar}
                value={moment(datosPedido.fecha_requerida).format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: moment(new Date()).format("YYYY-MM-DD"),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl
                style={style}
                focused
                color="primary"
                required
              // disabled={
              //   datosPedido.id_estado !== 19 && datosPedido.id_estado !== 21
              // }
              >
                <InputLabel>Atendido Por Sucursal: </InputLabel>
                <Select
                  name="idsucursal_atiende"
                  value={datosPedido.idsucursal_atiende}
                  onChange={almacenar}
                  label="Atendido Por Sucursal: "
                >
                  {sucursales
                    ? sucursales.map((sucursal) => (
                      <MenuItem key={sucursal.id} value={sucursal.id}>
                        {sucursal.nombre}
                      </MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={style}
                focused
                color="primary"
                label="Observaciones"
                name="observaciones"
                onChange={almacenar}
                value={datosPedido.observaciones}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/* {cliContado &&
              (datosPedido.cantidad || datosPedido.porcentaje) &&
              tanqueSel ? (
                <Grid m={1} container justifyContent="center">
                  <Grid item>
                    <Button
                      onClick={() => setDialogReparto(true)}
                      variant="contained"
                      color="primary"
                      startIcon={<LocalShippingIcon />}
                      disabled={dataDelivery.nro_reparto}
                    >
                      Asignar a una rendición
                    </Button>
                  </Grid>
                </Grid>
              ) : null} */}
            {dialogReparto ? (
              <DialogRepartos
                open={dialogReparto}
                setOpen={setDialogReparto} //abre o no el modal
                direccion={direccion} //Con el id de sucursal de la direccion, busco los repartos disopnibles para esa sucursal.
                kgCarga={kgCarga}
                setDataDelivery={setDataDelivery}
                setIsNewDelivery={setIsNewDelivery}
              />
            ) : null}
          </Grid>
        </Box>

        {dataDelivery.nro_reparto ? (
          <AlertRepartoAsignado
            dataDelivery={dataDelivery}
            setDataDelivery={setDataDelivery}
            isNewDelivery={isNewDelivery}
            setIsNewDelivery={setIsNewDelivery}
            sucursal={direccion.sucursal}
          />
        ) : null}

        <Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Button variant="contained" onClick={handleClose} color="error">
              Volver
            </Button>
            {/* No debe poder guardarse el pedido cuando: 
                - No tengo ni Cantidad ni % 
                - La cantidad de KG solicitados, supera la capacidad del tq/bateria O ES = 0 
                - El porcentaje solicitado es mayor al 85%
                - No tengo tanque seleccionado
                 */}
            <Button
              disabled={
                kgCarga * 1 <= 0 ||
                (datosPedido.cantidad &&
                  (capMaxKg !== null || capMaxKg * 1 !== 0) &&
                  datosPedido.cantidad * 1 > capMaxKg * 1) ||
                deshabilitarGuardar ||
                !kgCarga ||
                precio.length === 0  // Esta condición deshabilita el botón si `precio` es un array vacío
              }
              variant="contained"
              type="submit"
              color="success"
            >
              Guardar Pedido
            </Button>
          </Grid>
        </Box>
      </form>
      {pedidoRepetido && pedidoRepetido.length > 0 ? (
        <ModalRepetido2
          datosCliente={props.datosCli}
          pedidoRepetido={pedidoRepetido}
          setRepetido={setPedidoRepetido}
          setTanque={setTanqueSel}
        />
      ) : null}
      {showInfoDireccion ? (
        <ModalInfoDireccion
          direccion={direccion}
          open={showInfoDireccion}
          setOpen={setShowInfoDireccion}
        />
      ) : null}
      {showAltaTanque ? (
        <FormTanqueRapido
          datosCliente={props.datosCli}
          datosPedido={datosPedido}
          open={showAltaTanque}
          setOpen={setShowAltaTanque}
          direccion={direccion}
          actualizar={obtenerTanques}
          setTanque={setTanqueSel}
        />
      ) : null}
      {PHVencida ? (
        <DialogPHVencida
          title={`La prueba hidráulica del tanque N° Serie: ${tanqueSel.nro_serie
            } venció el día ${moment(tanqueSel.vto_pruebahidraulica).format(
              "DD-MM-YYYY"
            )}`}
          texto={
            "A continuación, puede actualizar la fecha de vencimiento de prueba hidráulica."
          }
          open={PHVencida}
          setOpen={setPHVencida}
          tanque={tanqueSel}
          setTanque={setTanqueSel}
          obtenerTanques={obtenerTanques}
          setTanqueSel={setTanqueSel}
          tanques={tanques}
        />
      ) : null}
      {noPH ? (
        <DialogPHVencida
          title={`El tanque N° Serie: ${tanqueSel.nro_serie} no tiene vencimiento de prueba hidráulica registrada.`}
          texto={"A continuación, puede registrar el vencimiento:"}
          open={noPH}
          setOpen={setNoPH}
          tanque={tanqueSel}
          setTanque={setTanqueSel}
          obtenerTanques={obtenerTanques}
          setTanqueSel={setTanqueSel}
          tanques={tanques}
        />
      ) : null}


      {dialogPrecioEspecial ? (
        <AddPrecioEspecialDireccion
          open={dialogPrecioEspecial}
          onClose={onCloseDialog}
          setOpen={setdialogPrecioEspecial}
          direccion={direccion}
          datos={props.datosCli}
        actualizar={getPrecios}
        />
      ) : null}

      {dialogListaPrecio ? (
        <ModalAddlistaprecio
          open={dialogListaPrecio}
          onClose={handleCloseDialogListaPrecio}
          setOpen={setdialogListaPrecio}
          direccion={direccion}
          datos={props.datosCli}
        actualizar={getPrecios}
        />
      ) : null}


      {showModalMapa ? (
        <VerificacionDomicilio
          direccion={direccion}
          open={showModalMapa}
          setOpen={setShowModalMapa}
        />
      ) : null}

      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default AddPedidos4;

//modificaciones 8/10 - saque el select de origen pedidos
// y puse ver domicilio al lado del select
//puse en barra pedidos el segmento del cliente y un boton para modificar en caso de ser necesario
//por ahora lo dejo asi y despues lo modifico en caso de ser necesario para volver a obtener precis