import React from "react";
import { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../../../services/apirest";
import Box from "@mui/material/Box";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Divider from "@mui/material/Divider";
import { Container, ListItemIcon, Grid, Button, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import PropaneIcon from "@mui/icons-material/Propane";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import ConfirmDialog from "../../../../Dialogs/ConfirmDialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Notify from "../../../../Notify";
import SendIcon from '@mui/icons-material/Send';

import {enviarNC} from "./savefunctions"


function Comprobantes(props) {
  const [comprobantes, setComprobantes] = useState();
  const [row, setRow]=useState();

  const obtenerComprobantes=()=>{
    // fetch(UrlFacturacion + "/facturas/cliente/" + props.cliente.id)
    fetch(UrlApi+ "/comprobantes/cliente/"+props.cliente.id)
    .then((data) => data.json())
    .then((data) => setComprobantes(data));
  }

  useEffect(() => {
    obtenerComprobantes();
  }, []);

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
  });

  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre_comprobante", //access nested data with dot notation
        header: "Comprobante",
      },
      {
        accessorKey: "nro_comprobante", //access nested data with dot notation
        header: "Nro",
      },
      {
        accessorKey: "fecha_emision", //access nested data with dot notation
        header: "Fecha Emision",
      },
      {
        accessorKey: "importe_total", //access nested data with dot notation
        header: "Importe",
      },
      {
        accessorKey: "estado", //access nested data with dot notation
        header: "Estado",
      },
    ],
    []
  );

  const anular = (row) => {
    setRow(row)
    setConfirmDialog({
      open: true,
      color: "#ed710b",
      borderColor: "#ed710b",
      icon: <WarningAmberIcon sx={{fontSize:40}} />,
      title: "ANULAR FACTURA",
      subtitle: <Typography variant="subtitle1" color="initial">Se hará una <b>nota de crédito</b> por el total de la factura.</Typography>,
      confirmText: "Presione 'confirmar' para guardar.",
      btnText:"Confirmar"
    });
  };

  async function save(e){
    await enviarNC(row, setNotify, setConfirmDialog)
    obtenerComprobantes();
  }

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          fontSize: 14,
          color: "black",
          fontWeight: "normal",
          paddingTop: 1,
          paddingBottom: 1,
          backgroundColor: "#D4E6F1",
          borderColor: "#FDFEFE",
        },
      },
      size: 60,
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      //   pageSize: direcciones && direcciones.length > 5 ? 10 : 5,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      paddingTop: 1,
      paddingBottom: 1,
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  return (
    <div>
      {comprobantes && (
        <>
          <Container maxWidth="xl" disableGutters>
            <Box boxShadow={3}>
              <MaterialReactTable
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                localization={MRT_Localization_ES}
                columns={columns}
                data={comprobantes}
                enableColumnFilterModes={false}
                enableColumnOrdering={false}
                enableGrouping={false}
                enableRowActions
                initialState={initialState}
                enableRowSelection={false}
                positionActionsColumn="last"
                enableHiding={false}
                muiTableHeadCellProps={tableHeadCellProps}
                positionExpandColumn="first"
                // renderDetailPanel={({ row }) => (
                //   <DetalleDir
                //     direccion={row.original}
                //     actulizar={obtenerDirecciones}
                //     cliente={dataCliente}
                //   />
                // )}

                // muiTableBodyCellProps={({ row }) => ({
                //   align: "left",
                //   sx: {
                //     backgroundColor:
                //       row.original.activo === 1 ? "#b3e6b3" : "#efc3c3",
                //     color: "black",
                //     borderColor: "black",
                //   },
                // })}

                enableExpandAll={false}
                muiTableBodyCellProps={({ row }) => ({
                  align: "center",
                  sx: {
                    backgroundColor:
                      row.original.id_estado === 260 ? "#efc3c3" : "#ffffff",
                    color: "black",
                    borderColor: "#FDFEFE",
                  },
                })}
                enableColumnActions={false}
                enableColumnFilters={false}
                displayColumnDefOptions={displayColumnDefOptions}
                enableDensityToggle={false}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <>
                    <MenuItem
                      key={0}
                      onClick={() => {
                        closeMenu();
                        window.open(
                          `http://192.168.34.102:8121/generate-pdf/${row.original.id_factura}?enviar=0`
                        );
                      }}
                    >
                      <ListItemIcon>
                        <PictureAsPdfIcon color="secondary" />
                      </ListItemIcon>
                      Visualizar
                    </MenuItem>
                    <MenuItem
                      key={0}
                      onClick={() => {
                        closeMenu();
                        window.open(
                          `http://192.168.34.102:8121/generate-pdf/${row.original.id_factura}?enviar=1`
                        );
                      }}
                    >
                      <ListItemIcon>
                        <SendIcon color="primary" />
                      </ListItemIcon>
                      Enviar Por Mail
                    </MenuItem>
                    <MenuItem
                      key={0}
                      disabled={row.original.id_estado === 260}
                      onClick={() => {
                        closeMenu();
                        anular(row.original);
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <DoDisturbOnIcon color="warning" />
                      </ListItemIcon>
                      Anular
                    </MenuItem>
                  </>
                ]}
              />
            </Box>
            <br />
            <br />
            {confirmDialog.open && (
              <ConfirmDialog
                open={confirmDialog.open}
                color={confirmDialog.color}
                icon={confirmDialog.icon}
                title={confirmDialog.title}
                subtitle={confirmDialog.subtitle}
                confirmText={confirmDialog.confirmText}
                notify={notify}
                setNotify={setNotify}
                setOpen={(open) =>
                  setConfirmDialog({ ...confirmDialog, open: open })
                }
                guardar={save}
                btnText={confirmDialog.btnText}
              />
            )}
          </Container>
        </>
      )}
    </div>
  );
}

export default Comprobantes;
