//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 3/1/2024

// Version : 1

// Description : Historial de ingreso con importación a CSV
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/

import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { Box, Typography, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material";

// Boton desplegable
import Button from "@mui/material/Button";

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";

function Historial_ingreso(props) {
  //CSV
  const [ingresosCSV, setIngresosCSV] = useState();

  const obtenerIngresosCSV = () => {
    fetch(UrlApi + "/stock_transacciones/ingresoCSV/" + props.sucursal)
      .then((data) => data.json())
      .then((data) => setIngresosCSV(data));
  };
  //console.log("csv", ingresosCSV);

  useEffect(() => {
    obtenerIngresosCSV();
  }, []);

  useEffect(() => {
    if (ingresosCSV && ingresosCSV.length > 0) {
      const ingresosOrdenados = ingresosCSV.sort(
        (a, b) => new Date(b.fecha_transaccion) - new Date(a.fecha_transaccion)
      );
      setIngresosCSV([...ingresosOrdenados]); // Actualiza el estado con el array ordenado
    }
  }, [ingresosCSV]);

  //************************************************************************************************************************************************ */
  const columns2 = useMemo(
    // TABLA INGRESO
    () => [
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Almacén", accessorKey: "almacen_nombre_destino", size: 100 },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 100 },
      { header: "Cantidad", accessorKey: "cantidad_recibida", size: 55 },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 200 },
      {
        header: "Pto. de Venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 70,
      },
      { header: "Remito Nro.", accessorKey: "nro_remito_numero", size: 65 },
    ],
    []
  );

  const columnsIngresoCSV = useMemo(
    // TABLA INGRESO
    () => [
      { header: "Artículo", accessorKey: "nombre_articulo", size: 100 },
      { header: "Almacén ", accessorKey: "almacen_nombre_destino", size: 100 },
      { header: "Cantidad Ingresada", accessorKey: "cantidad_recibida", size: 55 },
      { header: "Detalle", accessorKey: "detalle_transaccion", size: 200 },
      {
        header: "Pto.de Venta",
        accessorKey: "nro_remito_puntodeventa",
        size: 70,
      },
      { header: "Nro.Remito", accessorKey: "nro_remito_numero", size: 65 },
      { header: "Proveedor", accessorKey: "razon_social", size: 70 },
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Usuario", accessorKey: "usuario", size: 65 },
    ],
    []
  );
  //************************************************************************************************************************************************ */
  //CSV ingreso
  const csvOptionsIngresos = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Historial de Ingresos de Artículos de la Sucursal ${props.nombreSuc}`,
    headers: columnsIngresoCSV.map((c) => c.header),
  };

  const csvExporterI = new ExportToCsv(csvOptionsIngresos);

  const handleExportDataI = () => {
    csvExporterI.generateCsv(ingresosCSV);
  };

  return (
    <div>
      <Box mt={2} mb={4}>
        <ThemeProvider theme={props.tableTheme}>
          <MaterialReactTable
            enableExpandAll={false}
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={props.tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={props.initialState}
            muiSearchTextFieldProps={props.muiSearchTextFieldProps}
            columns={columns2}
            data={props.ingresos} //con lo q la completas (esto cambia siempre)
            enableStickyFooter
            // enableEditing
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
                <Grid item xs={6} ml={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<FileDownloadIcon />}
                    onClick={handleExportDataI}
                    style={{
                      backgroundColor: blueGrey[700],
                      color: "white",
                    }}
                    size="small"
                  >
                    Exportar a CSV
                  </Button>
                </Grid>
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Ingresos de Artículos
                </Typography>
              </>
            )}
          />
        </ThemeProvider>
      </Box>
    </div>
  );
}

export default Historial_ingreso;
