import React, { useEffect, useState } from "react";
import { postData, putData } from '../../../../services/library';
import { Typography, Grid, Box, Card, TextField, InputAdornment, CardContent, Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";

export default function DialogActualizar(props) {
    const { datosExisten, open } = props;

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const [datoslista, setDatoslista] = useState({
        id_sucursal: datosExisten.id_sucursal,
        id_segmento: datosExisten.id_segmento,
        precio_litro_con_iva: "",
        precio_litro_sin_iva: "",
        precio_kilo_con_iva: "",
        precio_kilo_sin_iva: "",
        vigencia: "",
        id_producto: datosExisten.id_producto,
        usuario: localStorage.getItem('user'),
        fin_vigencia: ""
    });

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const [porcentaje, setPorcentaje] = useState();
    const [desactivarPorcentaje, setDesactivarPorcentaje] = useState(false);
    const [desactivarPesos, setDesactivarPesos] = useState(false);
    const [aumento, setAumento] = useState();
    const [disabledGuardar, setDisabledGuardar] = useState(true);
    const fechaVigencia = moment(datoslista.vigencia);
    const fechaFinVigencia = fechaVigencia.subtract(1, "day");
    // const fechaFinVigenciaFormat = datosExisten.vigencia === "" ? "dd/mm/aaaa" : fechaFinVigencia.format("DD/MM/YYYY");
    const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");

    const almacenar = (e) => {
        setDatoslista({
            ...datoslista,
            [e.target.name]: e.target.value,
        });
    };

    //AUMENTO EN PESO ******//
    const handleAumentoChange = (e) => {
        const nuevoAumento = e.target.value;
        setAumento(nuevoAumento);
        setDesactivarPorcentaje(nuevoAumento !== "");

        if (!isNaN(nuevoAumento) && nuevoAumento !== "") {
            setDatoslista({
                ...datoslista,
                precio_litro_con_iva: datosExisten.precio_litro_con_iva + parseFloat(nuevoAumento),
                precio_litro_sin_iva: datosExisten.precio_litro_sin_iva + parseFloat(nuevoAumento),
                precio_kilo_con_iva: datosExisten.precio_kilo_con_iva + parseFloat(nuevoAumento),
                precio_kilo_sin_iva: datosExisten.precio_kilo_sin_iva + parseFloat(nuevoAumento),
            });
        } else {
            setDatoslista({
                ...datoslista,
                precio_litro_con_iva: datosExisten.precio_litro_con_iva,
                precio_litro_sin_iva: datosExisten.precio_litro_sin_iva,
                precio_kilo_con_iva: datosExisten.precio_kilo_con_iva,
                precio_kilo_sin_iva: datosExisten.precio_kilo_sin_iva,
            });
        }
    };

    //*AUMENTO EN PORCENTAJE ***************************//  
    const handlePorcentajeChange = (e) => {
        const nuevoPorcentaje = e.target.value;
        setPorcentaje(nuevoPorcentaje);
        setDesactivarPesos(nuevoPorcentaje !== "");

        if (!isNaN(nuevoPorcentaje) && nuevoPorcentaje !== "") {
            const porcentajeNumerico = parseFloat(nuevoPorcentaje) / 100;

            const nuevoPrecioLitroConIva = datosExisten.precio_litro_con_iva * (1 + porcentajeNumerico);
            const nuevoPrecioLitroSinIva = datosExisten.precio_litro_sin_iva * (1 + porcentajeNumerico);
            const nuevoPrecioKiloConIva = datosExisten.precio_kilo_con_iva * (1 + porcentajeNumerico);
            const nuevoPrecioKiloSinIva = datosExisten.precio_kilo_sin_iva * (1 + porcentajeNumerico);

            setDatoslista({
                ...datoslista,
                precio_litro_con_iva: nuevoPrecioLitroConIva.toFixed(4),
                precio_litro_sin_iva: nuevoPrecioLitroSinIva.toFixed(4),
                precio_kilo_con_iva: nuevoPrecioKiloConIva.toFixed(4),
                precio_kilo_sin_iva: nuevoPrecioKiloSinIva.toFixed(4),
            });
        } else {
            setDatoslista({
                ...datoslista,
                precio_litro_con_iva: datosExisten.precio_litro_con_iva,
                precio_litro_sin_iva: datosExisten.precio_litro_sin_iva,
                precio_kilo_con_iva: datosExisten.precio_kilo_con_iva,
                precio_kilo_sin_iva: datosExisten.precio_kilo_sin_iva,
            });
        }
    };

    //ESTA FUNCION ES PARA GUARDAR, DEBERIA MODIFICARLA MAS ADELATE PARA HACER UNA TRANSACCION
    const putPrecios = async (e) => {
        //cargo el nuevo precio
        postData(e, '/lista_precios',
            {
                id_sucursal: datosExisten.id_sucursal,
                id_segmento: datosExisten.id_segmento,
                id_producto: datosExisten.id_producto,
                precio_litro_con_iva: datoslista.precio_litro_con_iva,
                precio_litro_sin_iva: datoslista.precio_litro_sin_iva,
                precio_kilo_con_iva: datoslista.precio_kilo_con_iva,
                precio_kilo_sin_iva: datoslista.precio_kilo_sin_iva,
                vigencia: datoslista.vigencia,
                usuario: localStorage.getItem("user"),
                id_empresa: datosExisten.id_empresa
            },
            setNotificacion
        )
        //luego le pongo fecha de fin de vigencia a la lista 
        putData(e, `/lista_precios/${datosExisten.id_lista}`,
            {
                fin_vigencia: fechaFinVigenciaPut,
                usuario: localStorage.getItem('user')
            },
            setNotificacion
        )

        logEventos(
            "Guardar actualización del precio",
            "LISTA DE PRECIOS GRANEL",
            "Actualiza cierre de vigencia en pedido seleccionado y crea otro ",
            localStorage.getItem("user")
        );
    };

    useEffect(() => {
        //manejo el estado del boton -> si no hay un aumento en pesos o porcentaje que no se habilite el bton
        //despues tengo que manejar las validaciones: por ejemplo la fecha de  inicio vigencia no peude ser menor a la que está
        if ((!aumento && !porcentaje) || !datoslista.vigencia) {
            setDisabledGuardar(true);
        } else {
            setDisabledGuardar(false);
        }
    }, [aumento, porcentaje, datoslista.vigencia]);

    const handleClose = () =>{
        open(false);
        props.actualizar();
        props.tabla(true);
    };

    // console.log("datosExisten.vigencia",datosExisten.vigencia);
    
    
    return (
        <div>
            <Dialog open={open} fullWidth maxWidth="xl" onClose={handleClose}>
                {datosExisten && (
                    <Box sx={{ padding: 2 }}>
                        {/* Card para Precio Vigente */}
                        <Card variant="outlined" sx={{ mb: 1, backgroundColor: "#F4F6F6" }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
                                    Precio Vigente
                                </Typography>
                                {/* Precios en un solo renglón */}
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                    {[
                                        { label: "Precio litro sin IVA", value: datosExisten.precio_litro_sin_iva || 0 },
                                        { label: "Precio litro con IVA", value: datosExisten.precio_litro_con_iva || 0 },
                                        { label: "Precio kilo sin IVA", value: datosExisten.precio_kilo_sin_iva || 0 },
                                        { label: "Precio kilo con IVA", value: datosExisten.precio_kilo_con_iva || 0 },
                                    ].map((item, index) => (
                                        <Grid item xs={12} sm={6} md={2} key={index}>
                                            <TextField
                                                fullWidth
                                                style={style}
                                                focused
                                                size="small"
                                                variant="outlined"
                                                label={item.label}
                                                value={item.value}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    ))}
                                    {/* Fechas de Vigencia */}
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            variant="outlined"
                                            label="Entrada en Vigencia"
                                            value={moment(datosExisten.vigencia).format("DD-MM-YYYY") || ""}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            variant="outlined"
                                            label="Fin de Vigencia"
                                            value={fechaFinVigenciaFormat || ""}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid> */}
                                </Grid>
                            </CardContent>

                            <CardContent>
                                <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
                                    Aumento
                                </Typography>
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            color="secondary"
                                            label="Aumento en pesos"
                                            variant="outlined"
                                            type="number"
                                            value={aumento}
                                            onChange={handleAumentoChange}
                                            disabled={desactivarPesos}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>


                                        <TextField
                                            fullWidth
                                            size="small"
                                            style={style}
                                            focused
                                            type="number"
                                            color="secondary"
                                            label="Aumento en porcentaje"
                                            variant="outlined"
                                            value={porcentaje}
                                            onChange={handlePorcentajeChange}
                                            disabled={desactivarPorcentaje}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><PercentIcon /></InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <CardContent>
                                <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
                                    Precio Con Aumento
                                </Typography>
                                {/* Precios con aumento en un solo renglón */}
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                    {[
                                        { label: "Precio litro sin IVA", name: "precio_litro_sin_iva", value: datoslista.precio_litro_sin_iva || 0 },
                                        { label: "Precio litro con IVA", name: "precio_litro_con_iva", value: datoslista.precio_litro_con_iva || 0 },
                                        { label: "Precio kilo sin IVA", name: "precio_kilo_sin_iva", value: datoslista.precio_kilo_sin_iva || 0 },
                                        { label: "Precio kilo con IVA", name: "precio_kilo_con_iva", value: datoslista.precio_kilo_con_iva || 0 },
                                    ].map((item, index) => (
                                        <Grid item xs={12} sm={6} md={2} key={index}>
                                            <TextField
                                                fullWidth
                                                style={style}
                                                focused
                                                size="small"
                                                variant="outlined"
                                                label={item.label}
                                                name={item.name}
                                                value={item.value}
                                                onChange={almacenar}
                                                InputProps={{
                                                    readOnly: true,
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <AttachMoneyIcon /></InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                    {/* Fecha Minima */}
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            variant="outlined"
                                            label="Fecha de entrada en vigencia"
                                            type="date"
                                            inputProps={{ min: moment(datosExisten.vigencia).format("YYYY-MM-DD") }}
                                            name="vigencia"
                                            value={datoslista.vigencia || ""}
                                            onChange={almacenar}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    mt={2}
                                >
                                    <Button variant="contained"
                                        onClick={() => open(false)}
                                        color="error">
                                        Volver
                                    </Button>

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color={"success"}
                                        onClick={putPrecios}
                                        disabled={disabledGuardar}
                                    >
                                        Actualizar
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                        {notificacion && notificacion.open ? (
                            <Notify
                                mensaje={notificacion.mensaje}
                                open={notificacion.open}
                                color={notificacion.color}
                                handleClose={handleClose}
                                severity={notificacion.severity}
                            />
                        ) : null}
                    </Box>
                )}
            </Dialog>

        </div>
    )

}