import React from "react";
import { Grid, TextField } from '@mui/material';

export default function FormPreciosEspeciales(props) {
    const { style, size, almacenar, datoslista } = props;

    return (
        <>
            {datoslista &&
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    mt={1}
                >
                     <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="lt_sin_iva"
                            label="Precio litro sin IVA"
                            variant="outlined"
                            value={isNaN(datoslista.lt_sin_iva) ?  0 : datoslista.lt_sin_iva}
                            onChange={almacenar}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="lt_con_iva"
                            label="Precio litro con IVA"
                            variant="outlined"
                            value={isNaN(datoslista.lt_con_iva) ? 0 : datoslista.lt_con_iva}
                            onChange={almacenar}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="kg_sin_iva"
                            label="Precio kilo sin IVA"
                            variant="outlined"
                            value={isNaN(datoslista.kg_sin_iva) ? 0 : datoslista.kg_sin_iva}
                            onChange={almacenar}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            name="kg_con_iva"
                            label="Precio kilo con IVA"
                            variant="outlined"
                            value={isNaN(datoslista.kg_con_iva) ? 0 : datoslista.kg_con_iva}
                            onChange={almacenar}
                        />
                    </Grid>
                   
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size={size}
                            style={style}
                            focused
                            color="primary"
                            required
                            type="date"
                            name="vigencia"
                            label="Vigencia"
                            variant="outlined"
                            value={datoslista.vigencia}
                            onChange={almacenar}
                        />
                    </Grid>
                </Grid>
            }
        </>
    )
}
//----- Component --------------------------------------------------------/
// Developer :  Candela Grosso
// Creation Date: 12/09/2024
// Version : 1
// Description : formulario para agregar precios, solo tngo los textfields y eso
//========================================================================/