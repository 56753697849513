//----- Component --------------------------------------------------------/

// Developer : Nicolas Pascucci / Daiana Curcio

// Creation Date : 21/11/2023

// Version : 1

// Description : Creación y edición de familias de productos. Boton que lleva a componente donde se ven los articulos correspondientes a esas familias

//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/
import Navbar from "../Navbar";
import React, { useState, useEffect, useMemo } from "react";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
// //ARTICULOS
import Articulos_cargas from "./Articulos_cargas";

// ICONOS
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import SellIcon from "@mui/icons-material/Sell";

//Notification
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import Title from "../Title";
import { logEventos } from "../../services/services";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Familias_lista() {
  let navigate = useNavigate();
  const [familias, setFamilias] = useState();
  const [datosFamilias, setDatosFamilias] = useState({
    id_familia: null,
    nro1: "",
    nro2: "",
    nro3: "",
    descripcion: "",
    usuario: localStorage.getItem("user"),
  });

  const [datosArticulos, setDatosArticulos] = useState({
    id_articulo: null,
    id_familia: null,
    codigo: "",
    nombre: "",
    id_unidadmedida: null,
    usuario: localStorage.getItem("user"),
  });
  const [tabla, setTabla] = useState(true);
  const [update, setUpdate] = useState(false);
  const [cargando, setCargando] = useState(false);

  //Notification
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [openSnackEditar, setOpenSnackEditar] = React.useState(false);

 // console.log("DATOS DE FAMILIA", datosFamilias);

  //ARTICULOS
  const [openArticulos, setOpenArticulos] = useState(false);
  const [datosFamiliasRow, setDatosFamiliasRow] = useState({
    descripcion: "",
  });
  const [abrirDialog, setAbrirDialog] = useState(false);

  useEffect(() => {
    logEventos("Acceso al menú stock familias de artículos", "Stock Familias de Artículos", "Agregar nueva familia y cargarle nuevos artículos", localStorage.getItem("user"))
}, [])
  //*************************************************************************************************************************************/
  //funcion para obtener familias
  const obtenerFamilias = () => {
    fetch(UrlApi + "/stock_familias")
      .then((data) => data.json())
      .then((data) => setFamilias(data));
  };

  //funcion que espera los cambios de la funcion de obtener familias
  useEffect(() => {
    obtenerFamilias();
  }, []);

  //*************************************************************************************************************************************/

  //funcion para obtener unidad de medida
  const [unidadMedida, setUnidadMedida] = useState("");

  const obtenerUnidadDeMedida = () => {
    fetch(UrlApi + "/unidad_medida")
      .then((data) => data.json())
      .then((data) => setUnidadMedida(data));
  };

 // console.log("trae las unidades de medida", unidadMedida);

  //funcion que espera los cambios de la funcion de obtener unidad de medida
  useEffect(() => {
    obtenerUnidadDeMedida();
  }, []);

  //*************************************************************************************************************************************/

  //funcion para obtener sucursales
//   const [sucursales, setSucursales] = useState("");

//   const obtenerSucursales = () => {
//     fetch(UrlApi + "/stock_sucursales")
//       .then((data) => data.json())
//       .then((data) => setSucursales(data));
//   };

//  //console.log("trae las sucursales", sucursales);

//   //funcion que espera los cambios de la funcion de obtener proveedores
//   useEffect(() => {
//     obtenerSucursales();
//   }, []);
  //*************************************************************************************************************************************/

  // VER ARTICULOS EN FAMILIA (DIALOG)
  const handleClickOpenArticulos = (row) => {
    setOpenArticulos(true);
    setDatosFamiliasRow(row);
    //setDatosFamilias(row);
    setAbrirDialog(true);
  };

  const handleCloseArticulos = () => {
    setOpenArticulos(false);
  };
  //*************************************************************************************************************************************/
  //CSV
  const [familiasArtCSV, setFamiliasArtCSV] = useState();

  const obtenerFamiliasArtCSV = () => {
    fetch(UrlApi + "/stock_familias/familiasDeArtCSV/")
      .then((data) => data.json())
      .then((data) => setFamiliasArtCSV(data));
  };
 // console.log("csv", familiasArtCSV);

  useEffect(() => {
    obtenerFamiliasArtCSV();
  }, [tabla]);

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrearFamilia, setOpenCrearFamilia] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrearFamilia = (row) => {
    setOpenCrearFamilia(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosFamilias.id_familia = null;
    datosFamilias.nro1 = "";
    datosFamilias.nro2 = "";
    datosFamilias.nro3 = "";
    datosFamilias.descripcion = "";
  };

  const handleCloseCrearFamilia = () => {
    setOpenCrearFamilia(false);
  };

  //*************************************************************************************************************************************/
  // DIALOG PARA EDITAR LOS DATOS
  const [openEditarFamilia, setOpenEditarFamilia] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenEditarFamilia = (row) => {
    setOpenEditarFamilia(true);
    setDatosFamilias(row);
  };

  const handleCloseEditarFamilia = (row) => {
    setOpenEditarFamilia(false);
    // setDatosFamilias(row);
  };

  // EDITAR
  const handleCloseSnackEditarFamilia = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackEditar(false);
    setOpenEditarFamilia(false);
    setUpdate(false);
    setOpenDialogOnOff(false);
  };

  //*************************************************************************************************************************************/

  // DIALOG PARA CREAR LOS DATOS
  const [openCrearArticulo, setOpenCrearArticulo] = React.useState(false);

  // USANDO ROW TOMO LOS DATOS DE ESA FILA
  const handleClickOpenCrearArticulo = (row) => {
    setOpenCrearArticulo(true);
    // AL ABRIR EL DIALOG El TEXTFIELDS SE VACIAN
    datosArticulos.id_articulo = null;
    datosArticulos.id_familia = null;
    datosArticulos.codigo = "";
    datosArticulos.nombre = "";
    datosArticulos.descripcion = "";
    // datosArticulos.id_sucursal = null;
    datosArticulos.id_unidadmedida = null;
    // datosArticulos.valor_minimo = "";
    // datosArticulos.alerta_minima = "";
    // datosArticulos.cantidad_articulo = "";
  };

  const handleCloseCrearArticulo = () => {
    setOpenCrearArticulo(false);
  };

  //*************************************************************************************************************************************/

  // EDITAR
  const handleCloseSnackEditar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackEditar(false);
    setUpdate(false);
    setOpenDialogOnOff(false);
  };

  //*************************************************************************************************************************************/
  const [openDialogOnOff, setOpenDialogOnOff] = React.useState(false);

  //Snackbar Notificacion
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setUpdate(false);
    setOpenCrearFamilia(false);
    setOpenEditarFamilia(false);
    setOpenCrearArticulo(false);
    setOpenDialogOnOff(false);
    setCargando(false);
  };

  //*************************************************************************************************************************************/
  const columns = useMemo(
    () => [
      { header: "N° 1", accessorKey: "nro1", size: 70 },
      { header: "N° 2", accessorKey: "nro2", size: 70 },
      { header: "N° 3", accessorKey: "nro3", size: 70 },
      { header: "ID", accessorKey: "id_familia", size: 85, enableHiding: true },
      { header: "Descripción", accessorKey: "descripcion", size: 130 },
    ],
    []
  );

  const columnsCSV = useMemo(
    () => [
      { header: "N° 1", accessorKey: "nro1", size: 70 },
      { header: "N° 2", accessorKey: "nro2", size: 70 },
      { header: "N° 3", accessorKey: "nro3", size: 70 },
      { header: "Descripción", accessorKey: "descripcion", size: 130 },
      { header: "Usuario Creador", accessorKey: "usuario", size: 130 },
    ],
    []
  );

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //CSV
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    filename: `Listado de Familias de Artículos`,
    headers: columnsCSV.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(familiasArtCSV);
  };

  //*************************************************************************************************************************************/

  //Funcion para guardar al crear
  const guardarCrearFamilia = async (e) => {
    logEventos("Guardar nueva familia de artículos", "Stock Familias de Articulos", "Se guardo la nueva familia", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_familias", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_familia: datosFamilias.id_familia,
        nro1: datosFamilias.nro1,
        nro2: datosFamilias.nro2,
        nro3: datosFamilias.nro3,
        descripcion: datosFamilias.descripcion,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setUpdate(true);
          setCargando(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La familia se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la familia");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // PARA LIMPIAR LOS DATOS
    handleClickOpenCrearFamilia();
  };
 // console.log("POST CREAR", datosFamilias);
  //*************************************************************************************************************************************/
  //Funcion para guardar AL EDITAR
  const guardarEditarFamilia = async (e) => {
    logEventos("Guardar edición de familia", "Stock Familias de Articulos", "Se editó familia", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_familias/" + datosFamilias.id_familia, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        // id_familia: datosFamilias.id_familia,
        nro1: datosFamilias.nro1,
        nro2: datosFamilias.nro2,
        nro3: datosFamilias.nro3,
        descripcion: datosFamilias.descripcion,
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          // PONGO EL UPDATE EN TRUE PARA QUE LA TABLA SE ACTUALICE CON CADA ACTUALIZACION EN LOS DATOS
          setUpdate(true);
          setColorMensaje("#D4EFDF");
          setMensaje("La familia se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar la familia");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log(datosFamilias);
  };
 // console.log("POST EDITAR", datosFamilias);

  // ----------- ARTICULOS: -----------

  //Funcion para guardar al crear
  const guardarCrearArticulo = async (e) => {
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_articulos", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_articulo: datosArticulos.id_articulo,
        id_familia: datosArticulos.id_familia,
        codigo: datosArticulos.codigo,
        nombre: datosArticulos.nombre,
        descripcion: datosArticulos.descripcion,
        // id_sucursal: datosArticulos.id_sucursal,
        id_unidadmedida: datosArticulos.id_unidadmedida,
        // valor_minimo: datosArticulos.valor_minimo,
        // alerta_minima: datosArticulos.alerta_minima,
        // cantidad_articulo: datosArticulos.cantidad_articulo
        // uuid_articulo:uuid_articulo, //adjunto
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setUpdate(true);
          setCargando(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El artículo se guardó exitosamente !");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el artículo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // PARA LIMPIAR LOS DATOS
    handleClickOpenCrearArticulo();
  };
 // console.log("POST CREAR", datosArticulos);

  //*************************************************************************************************************************************/

  const almacenar = (e) => {
    setDatosFamilias({
      ...datosFamilias,
      [e.target.name]: e.target.value,
    });
  };

  const almacenar2 = (e) => {
    setDatosArticulos({
      ...datosArticulos,
      [e.target.name]: e.target.value,
    });
  };
  //*************************************************************************************************************************************/

  // FUNCION QUE SE EJECUTA CADA VEZ QUE HAY UN CAMBIO EN LA BASE DE DATOS
  // SIRVE PARA QUE LA TABLA MOSTRADA SE ACTUALICE EN TIEMPO REAL
  useEffect(() => {
    if (update) obtenerFamilias();
  }, [update]);
  //*************************************************************************************************************************************/
  // ESTILO PARA LA TABLA

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };
  
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: { id_familia: false },
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {familias && tabla ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                  },
                })}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={familias} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>                   
                  <Grid item xs={6} ml={4}>
                  <Button
                   variant="contained"
                   color="primary"
                   endIcon={<FileDownloadIcon />}
                    onClick={handleExportData}
                    style={{ backgroundColor: blueGrey[700], color: "white" }}
                    size="small"
                  >
                    Exportar a CSV
                  </Button>
                  </Grid>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddBoxTwoToneIcon />}
                      onClick={handleClickOpenCrearFamilia}
                      size="small"
                    >
                      NUEVA FAMILIA
                    </Button>
                    <Typography variant="poster" fontSize={26}>
                      Familias de Artículos
                    </Typography>
                  </>
                )}
                renderRowActions={({ row, table }) => (
                  //las acciones del costado editar y ver

                  <div>
                    <Tooltip arrow placement="top" title="Editar Familia">
                      <IconButton
                        onClick={() =>
                          handleClickOpenEditarFamilia(row.original)
                        }
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="bottom" title="Ver Artículos">
                      <IconButton
                        onClick={() =>
                          navigate(
                            "/articulos_cargas/" + row.original.id_familia,
                            { state: row.original }
                          )
                        } // para verlo en otra pantalla
                        color="primary"
                      >
                        <SellIcon />
                      </IconButton>
                    </Tooltip>
                    {/* Dialog ver almacenes*/}
                    {abrirDialog ? (
                      <Articulos_cargas
                        row={datosFamiliasRow}
                        abrirDialog={setAbrirDialog}
                      />
                    ) : null}
                  </div>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>

      {/* AGREGAR FAMILIA */}
      <Dialog
        open={openCrearFamilia}
        onClose={handleCloseCrearFamilia}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
        titulo={`Agregar Nueva Familia`}
            handleClose={() => {
              handleCloseCrearFamilia()
            }}
            />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarCrearFamilia} autoComplete="off">
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={4} py={1} mb={1}>
                    <TextField
                      label="Número 1"
                      name="nro1"
                      size="small"
                      placeholder="01"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 2,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosFamilias.nro1}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1} mb={1}>
                    <TextField
                      label="Número 2"
                      name="nro2"
                      size="small"
                      placeholder="00"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 2,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosFamilias.nro2}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1} mb={1}>
                    <TextField
                      label="Número 3"
                      name="nro3"
                      size="small"
                      placeholder="00"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 2,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosFamilias.nro3}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      fullWidth
                      focused
                      required
                      size="small"
                      placeholder="Descripción"
                      name="descripcion"
                      label="Descripción"
                      variant="outlined"
                      value={datosFamilias.descripcion}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseCrearFamilia}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>

      {/* GUARDAR AL CREAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {/* EDITAR ITEM */}
      <Dialog
        open={openEditarFamilia}
        onClose={handleCloseEditarFamilia}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
            titulo={`Editar Familia`}
            handleClose={() => {
              handleCloseEditarFamilia()
            }}
          />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >

          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarEditarFamilia} autoComplete="off">
                <Grid container mb={1} spacing={1}>
                  <Grid item xs={4} py={1} mb={1}>
                    <TextField
                      label="Número 1"
                      name="nro1"
                      size="small"
                      placeholder="01"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 2,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosFamilias.nro1}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1} mb={1}>
                    <TextField
                      label="Número 2"
                      name="nro2"
                      size="small"
                      placeholder="00"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 2,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosFamilias.nro2}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={4} py={1} mb={1}>
                    <TextField
                      label="Número 3"
                      name="nro3"
                      size="small"
                      placeholder="00"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 2,
                      }}
                      focused
                      required
                      fullWidth
                      value={datosFamilias.nro3}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12} py={1} mb={1}>
                    <TextField
                      fullWidth
                      focused
                      required
                      size="small"
                      placeholder="Descripción"
                      name="descripcion"
                      label="Descripción"
                      variant="outlined"
                      value={datosFamilias.descripcion}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>

                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseEditarFamilia}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>

      {/* GUARDAR AL EDITAR */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openSnackEditar}
          autoHideDuration={1500}
          onClose={handleCloseSnackEditar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackEditar}
            severity="success"
            sx={{ width: "100%" }}
            style={{ backgroundColor: colormensaje, color: "black" }}
          >
            {mensaje}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default Familias_lista;
