import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../../services/apirest";
import Notify from "../../../../Notify";
import Stack from "@mui/material/Stack";

function DialogBaja(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [colormensaje, setColorMensaje] = useState(""); //define el color que tendrá la notificación
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [severitySnack, setSeveritySnack] = useState();
  const [disBtn, setDisBtn] = useState();

  async function marcarInactivo(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/direccionesentrega/" + props.direccion.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          activo: 0,
        }),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api"
          );
          props.actualizar();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA Api a Json ");
          props.actualizar();
        } else {
          alert("ERROR DESCONOCIDO: " + error);
        }
      }
    }
  }

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            handleClose={handleClose} //aca le pasamos la funcion que tengamos para cerrar el componente
            severity={severitySnack}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              ¿Desea dar de baja este domicilio de entrega?
            </Typography>
          </DialogTitle>
        </Box>
        {/* hola */}
        <DialogContent>
          <DialogContentText>
            {props.direccion
              ? `Usted está por dar de baja el domicilio ${props.direccion.direccion_entrega} (${props.direccion.provincia}, ${props.direccion.localidad}). Presione CONFIRMAR si desea finalizar la operación.`
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <br></br>
          <Stack spacing={45} direction="row">
            <Button variant="contained" onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button
              onClick={marcarInactivo}
              variant="contained"
              color="success"
              disabled={disBtn}
            >
              Confirmar
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogBaja;
