import React, { useState, useEffect, useMemo } from "react";
import { getData, putData } from "../../../../../services/library";
import { logEventos } from "../../../../../services/services";
import { Dialog, DialogContent, FormControl, MenuItem, Select, InputLabel, DialogContentText, TextField, Grid, Box, Typography, Button, Autocomplete, Alert, AlertTitle } from '@mui/material';
import Notify from "../../../../Notify";

export default function ModalSegmento(props) {

    const [segmentoTemporal, setSegmentoTemporal] = useState(props.segSelected);

    useEffect(() => {
        // Cada vez que se abre el modal, se inicializa el segmento temporal con el actual
        setSegmentoTemporal(props.segSelected);
    }, [props.segSelected]);

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const updateSegmento = async (e) => {
        putData(e,
            `/clientes/${props.datos.id}`,
            {
                id_segmento: segmentoTemporal.id,  // Guardamos el segmento temporal
                // id_subsegmento:null,
                usuario: localStorage.getItem('user')
            },
            setNotificacion,
            props.actualizar(),
            // Actualizamos el segmento seleccionado en `AddPedidos`
            props.setSegselectd(segmentoTemporal)
        );
        logEventos('Cambio de segmento en cliente', 'Addpedido', 'Cliente granel', localStorage.getItem('user'));

    };


    return (
        <Dialog maxWidth="md" fullWidth open={props.open} onClose={props.onCerrar} >
            <DialogContent>
                {/* <Alert severity="warning">
                    <AlertTitle>Atención</AlertTitle>
                    El segmento de este cliente es desconocido.-
                </Alert> */}
                <DialogContentText>
                    <Box p={2}>
                        <Typography variant="caption" display="block" gutterBottom>
                            Elegir un segmento para modificar.-
                        </Typography>
                    </Box>
                </DialogContentText>
                <form autoComplete="off" onSubmit={updateSegmento}>
                    <Autocomplete
                        value={segmentoTemporal}
                        disableClearable

                        options={props.segmentos}
                        onChange={(event, newValue) => setSegmentoTemporal(newValue)}  // Cambiamos el estado temporal
                        getOptionLabel={(option) => option.segmento}
                        renderInput={(params) => (
                            <TextField {...params} style={style} label="Segmentos" variant="outlined" />
                        )}
                    />
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        mt={2}
                    >
                        <Button variant="contained"
                            onClick={() => {
                                props.onClose()

                            }}
                            color="error">
                            Volver
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            color={"success"}
                        >
                            Actualizar
                        </Button>
                    </Grid>
                </form>
            </DialogContent>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={props.onClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>

    )



}