//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 05/08/2024
// Version : 1
// Description : tabla de reclamos por motivos
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useEffect, useState } from "react";
import { UrlApi } from "../../../../services/apirest";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";

function Tabla_motivos() {
  const [cantidadXmotivo, setCantidadXmotivo] = useState([]);

  const obtenerCantidadXmotivo = () => {
    fetch(UrlApi + "/reclamos/cantidadPorMotivo")
      .then((data) => data.json())
      .then((data) => setCantidadXmotivo(data));
  };
  useEffect(() => {
    obtenerCantidadXmotivo();
  }, []);

  // console.log("CANTIDAD X MOTIVO", cantidadXmotivo);

  // Ordenar los datos de mayor a menor por cantidad de reclamos
  const cantidadXmotivoOrdenado = [...cantidadXmotivo].sort(
    (a, b) => b.cantidad_reclamos - a.cantidad_reclamos
  );

  // Calcular el total de reclamos
  const totalReclamos = cantidadXmotivoOrdenado.reduce(
    (total, item) => total + item.cantidad_reclamos,
    0
  );

  // Transformar los datos para el PieChart, incluyendo el porcentaje
  const data = (cantidadXmotivoOrdenado || []).map((item) => ({
    label: `${item.descripcion_motivo} (${(
      (item.cantidad_reclamos / totalReclamos) *
      100
    ).toFixed(2)}%)`,
    value: item.cantidad_reclamos,
  }));

  const data2 = (cantidadXmotivoOrdenado || []).map((item) => ({
    descripcion: item.descripcion_motivo,
    cantidad: item.cantidad_reclamos,
  }));

  // tabla 1
  const paperStyle1 = {
    padding: "10px",
    margin: "20px",
    width: "500px",
  };

  const HeaderCell = styled(TableCell)({
    marginTop: "2px",
    fontSize: 13,
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#2471A3",
    borderColor: "white",
    padding: "6.3px",
  });

  return (
    <div>
      <Paper
        elevation={10}
        style={{
          ...paperStyle1,
          flex: 1,
          marginLeft: 370,
        }}
      >
        <TableContainer style={{ maxHeight: "40vh" }}>
          <Typography
            align="center"
            style={{ fontWeight: "bold", fontSize: 20 }}
          >
            Motivos de los reclamos
          </Typography>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <HeaderCell align="center" sx={{ border: "1px solid black" }}>
                  <b>Motivos</b>
                </HeaderCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#2B76A6",
                    color: "white",
                    border: "1px solid black",
                  }}
                >
                  Cantidad
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2.map((row, index) => (
                <TableRow key={index} sx={{ border: "1px solid black" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    {row.descripcion}
                  </TableCell>
                  <TableCell align="center" sx={{ border: "1px solid black" }}>
                    {row.cantidad}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default Tabla_motivos;
