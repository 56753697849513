//----- Component --------------------------------------------------------/

// Developer : Daiana Curcio / Nicolas Pascucci

// Creation Date : 3/1/2024

// Version : 1

// Description : Historial de todas las transacciones con vista de adjuntos
//

//------Changes --------------------------------------------------------------------- -------------//

// Change Date :

// Change by :

// Change description:

//

// Change Date :

// Change by :

// Change description:

//

//========================================================================/

import React, { useState, useMemo,useEffect } from "react";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { UrlApi } from "../../services/apirest";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Tooltip,
  DialogTitle,
} from "@mui/material";
import { ThemeProvider } from "@mui/material";
// Boton desplegable
import Button from "@mui/material/Button";
//CSV
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { blueGrey } from "@mui/material/colors";
//ADJUNTAR
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadStock from "./FileUploadStock"; //ADJUNTAR IMAGEN

function Historial_todas(props) {
  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN

  // //CSV
  // const [todasCSV, setTodasCSV] = useState();

  // const obtenerTodasCSV = () => {
  //   fetch(UrlApi + "/stock_transacciones/todasprimeravistaCSV/" + props.sucursal)
  //     .then((data) => data.json())
  //     .then((data) => setTodasCSV(data));
  // };
  // console.log("csv", todasCSV);

  // useEffect(() => {
  //   obtenerTodasCSV();
  // }, []);

  // useEffect(() => {
  //   if (todasCSV && todasCSV.length > 0) {
  //     const todasOrdenadas = todasCSV.sort(
  //       (a, b) => new Date(b.fecha_transaccion) - new Date(a.fecha_transaccion)
  //     );
  //     setTodasCSV([...todasOrdenadas]); // Actualiza el estado con el array ordenado
  //   }
  // }, [todasCSV]);

  //************************************************************************************************************************************************ */
  const columns6 = useMemo(
    // TABLA TODAS
    () => [
      {
        header: "Fecha",
        accessorKey: "fecha_transaccion",
        size: 60,
        type: "date",
        format: "YYYY-MM-DD HH:mm",
      },
      { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
      { header: "Tipo", accessorKey: "tipo_transaccion", size: 110 },
      { header: "Usuario", accessorKey: "usuario", size: 100 },
      {
        header: "id_transaccion",
        accessorKey: "id_transaccion",
        size: 20,
        enableHiding: true,
      },
      {
        header: "sucursal_destino",
        accessorKey: "sucursal_destino",
        size: 20,
        enableHiding: true,
      },
      {
        header: "nombre_suc_destino",
        accessorKey: "nombre_suc_destino",
        size: 20,
        enableHiding: true,
      },
      {
        header: "almacen_origen",
        accessorKey: "almacen_origen",
        size: 20,
        enableHiding: true,
      },
      {
        header: "nombre_alm_origen",
        accessorKey: "nombre_alm_origen",
        size: 20,
        enableHiding: true,
      },
      {
        header: "id_suc_origen",
        accessorKey: "id_suc_origen",
        size: 20,
        enableHiding: true,
      },
      {
        header: "nombre_suc_origen",
        accessorKey: "nombre_suc_origen",
        size: 20,
        enableHiding: true,
      },
      {
        header: "almacen_destino",
        accessorKey: "almacen_destino",
        size: 20,
        enableHiding: true,
      },
      {
        header: "cantidad_enviada",
        accessorKey: "cantidad_enviada",
        size: 20,
        enableHiding: true,
      },
      {
        header: "cantidad_recibida",
        accessorKey: "cantidad_recibida",
        size: 20,
        enableHiding: true,
      },
      {
        header: "cantidad_pendiente",
        accessorKey: "cantidad_pendiente",
        size: 20,
        enableHiding: true,
      },
      {
        header: "detalle_transaccion",
        accessorKey: "detalle_transaccion",
        size: 20,
        enableHiding: true,
      },
      {
        header: "nro_remito_puntodeventa",
        accessorKey: "nro_remito_puntodeventa",
        size: 20,
        enableHiding: true,
      },
      {
        header: "nro_remito_numero",
        accessorKey: "nro_remito_numero",
        size: 20,
        enableHiding: true,
      },
      {
        header: "en_transito",
        accessorKey: "en_transito",
        size: 20,
        enableHiding: true,
      },
      {
        header: "estado_transito",
        accessorKey: "estado_transito",
        size: 20,
        enableHiding: true,
      },
      {
        header: "fecha_recibido",
        accessorKey: "fecha_recibido",
        size: 20,
        type: "date",
        format: "YYYY-MM-DD",
        enableHiding: true,
      },
      {
        header: "detalle_confirmacion",
        accessorKey: "detalle_confirmacion",
        size: 20,
        enableHiding: true,
      },
      {
        header: "razon_social",
        accessorKey: "razon_social",
        size: 20,
        enableHiding: true,
      },
      {
        header: "descripcion_motivo",
        accessorKey: "descripcion_motivo",
        size: 20,
        enableHiding: true,
      },
      {
        header: "nombre_alm_destino",
        accessorKey: "nombre_alm_destino",
        size: 20,
        enableHiding: true,
      },
      {
        header: "uuid",
        accessorKey: "uuid_documentacion",
        size: 20,
        enableHiding: true,
      },
      {
        header: "pendiente_palabra",
        accessorKey: "estado_pendiente_palabra",
        size: 20,
        enableHiding: true,
      },
    ],
    []
  );

  // const columnsTodasCSV = useMemo(
  //   // TABLA TODAS
  //   () => [
  //     { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
  //     { header: "Tipo de Transacción", accessorKey: "tipo_transaccion", size: 110 },
  //     {
  //       header: "Almacén de Origen",
  //       accessorKey: "nombre_alm_origen",
  //       size: 20,
  //     },
  //     {
  //       header: "Almacén de Destino",
  //       accessorKey: "nombre_alm_destino",
  //       size: 20,
  //     },
  //     {
  //       header: "Sucursal de Origen",
  //       accessorKey: "nombre_suc_origen",
  //       size: 20,
  //     },
  //     {
  //       header: "Sucursal de Destino",
  //       accessorKey: "nombre_suc_destino",
  //       size: 20,
  //     },
  //     {
  //       header: "Cantidad Enviada",
  //       accessorKey: "cantidad_enviada",
  //       size: 20,
  //     },
  //     {
  //       header: "Cantidad Recibida",
  //       accessorKey: "cantidad_recibida",
  //       size: 20,
  //     },
  //     {
  //       header: "Cantidad Pendiente",
  //       accessorKey: "cantidad_pendiente",
  //       size: 20,
  //     },
  //     {
  //       header: "Detalle Transacción",
  //       accessorKey: "detalle_transaccion",
  //       size: 20,
  //     },
  //     {
  //       header: "Detalle Confirmación",
  //       accessorKey: "detalle_confirmacion",
  //       size: 20,
  //     },
  //     {
  //       header: "Pto.de Venta",
  //       accessorKey: "nro_remito_puntodeventa",
  //       size: 20,
  //     },
  //     {
  //       header: "Nro.Remito",
  //       accessorKey: "nro_remito_numero",
  //       size: 20,
  //     },
  //     {
  //       header: "Estado",
  //       accessorKey: "estado_transito",
  //       size: 20,
  //     },
  //     {
  //       header: "Fecha de Transacción",
  //       accessorKey: "fecha_transaccion",
  //       size: 60,
  //       type: "date",
  //       format: "YYYY-MM-DD HH:mm",
  //     },
  //     {
  //       header: "Fecha de Recepción",
  //       accessorKey: "fecha_recibido",
  //       size: 20,
  //       type: "date",
  //       format: "YYYY-MM-DD",
  //     },
  //     {
  //       header: "Proveedor",
  //       accessorKey: "razon_social",
  //       size: 20,
  //     },
  //     {
  //       header: "Motivo de Salida",
  //       accessorKey: "descripcion_motivo",
  //       size: 20,
  //     },
  //     {
  //       header: "Convertible a:",
  //       accessorKey: "nombre_convertible",
  //       size: 20,
  //     },
  //     {
  //       header: "Estado de Cant.Pendientes",
  //       accessorKey: "estado_pendiente_palabra",
  //       size: 20,
  //     },
  //     { header: "Usuario", accessorKey: "usuario", size: 100 },
  //   ],
  //   []
  // );
  // const columnsTodasCSV = useMemo(
  //   // TABLA TODAS
  //   () => [
  //     { header: "Artículo", accessorKey: "nombre_articulo", size: 110 },
  //     { header: "Tipo de Transacción", accessorKey: "tipo_transaccion", size: 110 },
  //     {
  //       header: "Almacén de Origen",
  //       accessorKey: "nombre_alm_origen",
  //       size: 20,
  //     },
  //     {
  //       header: "Almacén de Destino",
  //       accessorKey: "nombre_alm_destino",
  //       size: 20,
  //     },
  //     {
  //       header: "Sucursal de Origen",
  //       accessorKey: "nombre_suc_origen",
  //       size: 20,
  //     },
  //     {
  //       header: "Sucursal de Destino",
  //       accessorKey: "nombre_suc_destino",
  //       size: 20,
  //     },
  //     {
  //       header: "Cantidad Enviada",
  //       accessorKey: "cantidad_enviada",
  //       size: 20,
  //     },
  //     {
  //       header: "Cantidad Recibida",
  //       accessorKey: "cantidad_recibida",
  //       size: 20,
  //     },
  //     {
  //       header: "Cantidad Pendiente",
  //       accessorKey: "cantidad_pendiente",
  //       size: 20,
  //     },
  //     {
  //       header: "Detalle Transacción",
  //       accessorKey: "detalle_transaccion",
  //       size: 20,
  //     },
  //     {
  //       header: "Detalle Confirmación",
  //       accessorKey: "detalle_confirmacion",
  //       size: 20,
  //     },
  //     {
  //       header: "Pto.de Venta",
  //       accessorKey: "nro_remito_puntodeventa",
  //       size: 20,
  //     },
  //     {
  //       header: "Nro.Remito",
  //       accessorKey: "nro_remito_numero",
  //       size: 20,
  //     },
  //     {
  //       header: "Estado",
  //       accessorKey: "estado_transito",
  //       size: 20,
  //     },
  //     {
  //       header: "Fecha de Transacción",
  //       accessorKey: "fecha_transaccion",
  //       size: 60,
  //       type: "date",
  //       format: "YYYY-MM-DD HH:mm",
  //     },
  //     {
  //       header: "Fecha de Recepción",
  //       accessorKey: "fecha_recibido",
  //       size: 20,
  //       type: "date",
  //       format: "YYYY-MM-DD",
  //     },
  //     {
  //       header: "Proveedor",
  //       accessorKey: "razon_social",
  //       size: 20,
  //     },
  //     {
  //       header: "Motivo de Salida",
  //       accessorKey: "descripcion_motivo",
  //       size: 20,
  //     },
  //     {
  //       header: "Convertible a:",
  //       accessorKey: "nombre_convertible",
  //       size: 20,
  //     },
  //     {
  //       header: "Estado de Cant.Pendientes",
  //       accessorKey: "estado_pendiente_palabra",
  //       size: 20,
  //     },
  //     { header: "Usuario", accessorKey: "usuario", size: 100 },
  //   ],
  //   []
  // );
  // //************************************************************************************************************************************************ */
  //CSV TODAS
  // const csvOptions = {
  //   fieldSeparator: ",",
  //   quoteStrings: '"',
  //   decimalSeparator: ".",
  //   showLabels: true,
  //   useBom: true,
  //   useKeysAsHeaders: false,
  //   filename: `Historial de Todas las Transacciones de Sucursal ${props.nombreSuc}`,
  //   headers: columnsTodasCSV.map((c) => c.header),
  // };
  // const csvExporter = new ExportToCsv(csvOptions);

  // const handleExportData = () => {
  //   csvExporter.generateCsv(todasCSV);
  // };
  // //************************************************************************************************************************************************ */
  const initialStateTodas = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    sorting: [
      {
        id: "fecha_transaccion",
        desc: true,
      },
    ],
    pagination: { pageIndex: 0, pageSize: 10 },
    columnVisibility: {
      id_transaccion: false,
      id_articulo: false,
      sucursal_destino: false,
      nombre_suc_destino: false,
      almacen_origen: false,
      nombre_alm_origen: false,
      id_suc_origen: false,
      nombre_suc_origen: false,
      almacen_destino: false,
      cantidad_enviada: false,
      cantidad_recibida: false,
      cantidad_pendiente: false,
      detalle_transaccion: false,
      nro_remito_puntodeventa: false,
      nro_remito_numero: false,
      en_transito: false,
      estado_transito: false,
      fecha_recibido: false,
      detalle_confirmacion: false,
      razon_social: false,
      descripcion_motivo: false,
      nombre_alm_destino: false,
      uuid_documentacion: false,
      estado_pendiente_palabra: false,
    },
  };
  // //************************************************************************************************************************************************ */
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(false);
  };

  return (
    <div>
      <Box mt={2} mb={4}>
        <ThemeProvider theme={props.tableTheme}>
          <MaterialReactTable
            enableExpandAll={false}
            enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
            enableGrouping // para permitir agrupar x estado x ej
            layoutMode="grid"
            muiTableHeadCellProps={props.tableHeadCellProps}
            muiTableBodyCellProps={({ row }) => ({
              align: "left",
              sx: {
                color: "black",
                borderColor: "black",
              },
            })}
            enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
            enableColumnFilters={false} //desactiva filtros x  columna
            enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
            enableDensityToggle={false} //
            localization={MRT_Localization_ES} //idioma
            enableColumnDragging={false} //para arrastrar columnas
            initialState={initialStateTodas}
            muiSearchTextFieldProps={props.muiSearchTextFieldProps}
            columns={columns6}
            data={props.todas} //con lo q la completas (esto cambia siempre)
            enableStickyFooter
            enableEditing
            positionActionsColumn="last" // posicion de la columna de acciones al final
            // ACCIONES
            renderToolbarInternalActions={(
              { table } // boton para nuevo , al lado del buscar
            ) => (
              <>
              {/* <Grid item xs={6} ml={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<FileDownloadIcon />}
                    onClick={handleExportData}
                    style={{
                      backgroundColor: blueGrey[700],
                      color: "white",
                    }}
                    size="small"
                  >
                    Exportar a CSV
                  </Button>
                </Grid> */}
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}
            renderTopToolbarCustomActions={() => (
              <>
                <Typography variant="poster" fontSize={26}>
                  Todas las Transacciones de la Sucursal
                </Typography>
              </>
            )}
            renderRowActions={({ row, table }) => (
              //las acciones del costado editar y ver
              <div>
                {row.original.id_accion === 200 ||
                row.original.id_accion === 201 ||
                row.original.id_accion === 209 ? null : (
                  <div>
                    {/* ADJUNTAR ARCHIVOS */}
                    <Tooltip arrow placement="bottom" title="Ver adjunto">
                      <Button
                        backgroundColor="#ffffff"
                        size="small"
                        onClick={() => {
                          setNewUuid(row.original.uuid_documentacion);
                          setOpenCloseAdj(!openCloseAdj);
                        }}
                      >
                        <AttachFileIcon />
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
            renderDetailPanel={({ row }) => (
              <>
                {row.original.id_accion === 158 ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Guardado en"
                                name="almacen_destino"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_destino}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad"
                                name="cantidad_recibida"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_recibida}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="..."
                                label="Detalle"
                                name="detalle_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.detalle_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Punto de Venta"
                                name="nro_remito_puntodeventa"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_puntodeventa}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Número de Remito"
                                name="nro_remito_numero"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_numero}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Proveedor"
                                name="razon_social"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.razon_social}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : row.original.id_accion === 159 ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Sale de"
                                name="almacen_origen"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_origen}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad"
                                name="cantidad_enviada"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_enviada}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="..."
                                label="Detalle"
                                name="detalle_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.detalle_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Punto de Venta"
                                name="nro_remito_puntodeventa"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_puntodeventa}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Número de Remito"
                                name="nro_remito_numero"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_numero}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Motivo"
                                name="descripcion_motivo"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.descripcion_motivo}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : row.original.id_accion === 176 ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Sale de"
                                name="almacen_origen"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_origen}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Se Guarda en"
                                name="almacen_destino"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_destino}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad"
                                name="cantidad_enviada"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_enviada}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="..."
                                label="Detalle"
                                name="detalle_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.detalle_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : row.original.id_accion === 160 &&
                  row.original.sucursal_destino === props.sucursal ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Viene de"
                                name="nombre_suc_origen"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_suc_origen}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Guardado en"
                                name="almacen_destino"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_destino}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad enviada"
                                name="cantidad_enviada"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_enviada}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad recibida"
                                name="cantidad_recibida"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_recibida}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad pendiente"
                                name="cantidad_pendiente"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_pendiente}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="..."
                                label="Detalle"
                                name="detalle_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.detalle_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Punto de Venta"
                                name="nro_remito_puntodeventa"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_puntodeventa}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Número de Remito"
                                name="nro_remito_numero"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_numero}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Estado de la transferencia"
                                name="estado_transito"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.estado_transito}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha de transferencia"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha de recibo"
                                name="fecha_recibido"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_recibido}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="..."
                                label="Detalle de confirmación"
                                name="detalle_confirmacion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.detalle_confirmacion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            {row.original.cantidad_pendiente > 0 ? (
                              <Grid item xs={2.7} py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="..."
                                  label="Pendientes"
                                  name="estado_pendiente_palabra"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value={row.original.estado_pendiente_palabra}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : row.original.id_accion === 160 &&
                  row.original.sucursal_destino !== props.sucursal ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Hacia"
                                name="nombre_suc_destino"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_suc_destino}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Salió de"
                                name="almacen_origen"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_origen}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad enviada"
                                name="cantidad_enviada"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_enviada}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad recibida"
                                name="cantidad_recibida"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_recibida}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad pendiente"
                                name="cantidad_pendiente"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_pendiente}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="..."
                                label="Detalle"
                                name="detalle_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.detalle_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Punto de Venta"
                                name="nro_remito_puntodeventa"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_puntodeventa}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Número de Remito"
                                name="nro_remito_numero"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nro_remito_numero}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Estado de la transferencia"
                                name="estado_transito"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.estado_transito}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha de transferencia"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha de recibo"
                                name="fecha_recibido"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_recibido}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="..."
                                label="Detalle de confirmación"
                                name="detalle_confirmacion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.detalle_confirmacion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            {row.original.nro_remito_numero === null ? (
                              <Grid item xs={2.7} py={1} mb={1}>
                                <TextField
                                  fullWidth
                                  placeholder="..."
                                  label="Motivo de transferencia"
                                  name="motivo_transferencia"
                                  variant="filled"
                                  size="small"
                                  focused
                                  value="DEVOLUCIÓN"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : row.original.id_accion === 200 ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Almacenado en"
                                name="almacen_origen"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_origen}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad enviada"
                                name="cantidad_enviada"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_enviada}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Fecha de Consumo"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : row.original.id_accion === 201 ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Ajuste en"
                                name="almacen_origen"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_origen}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Nueva Cantidad"
                                name="cantidad_recibida"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_recibida}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Hecho el día"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : row.original.id_accion === 209 ? (
                  <>
                    <Container>
                      <br />
                      <DialogTitle
                        style={{
                          color: "white",
                          backgroundColor: "#4abced",
                          height: "10px",
                          marginTop: "-30px",
                          width: "272%",
                        }}
                      >
                        <Grid mt={-1.5}>{row.original.tipo_transaccion}</Grid>
                      </DialogTitle>
                      <br />
                      <Box display="block" width="283%">
                        <Box border={2} px={2} pt={2} borderRadius={4} mt={-2}>
                          <Grid container maxWidth="x1" spacing={1} ml={4.5}>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Almacén de Origen"
                                name="nombre_alm_origen"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_origen}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Almacén de Destino"
                                name="nombre_alm_destino"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_alm_destino}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Cantidad Convertida"
                                name="cantidad_enviada"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.cantidad_enviada}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="Pendiente"
                                label="Artículo Convertido a :"
                                name="nombre_convertible"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.nombre_convertible}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={2.7} py={1} mb={1}>
                              <TextField
                                fullWidth
                                placeholder="dd/MM/YYYY"
                                label="Hecho el día"
                                name="fecha_transaccion"
                                variant="filled"
                                size="small"
                                focused
                                value={row.original.fecha_transaccion}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : null}
              </>
            )}
          />
        </ThemeProvider>
      </Box>

      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadStock
          fileId={newUuid}
          openclose={true}
          mostrar={false}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </div>
  );
}

export default Historial_todas;
