/*****Desarrollo */
export const AuthApi = 'https://devauthapi.netizar.com/api/' //27017
export const UrlApi = 'https://devapi.netizar.com/api' //27020
//export const UrlApi = "http://localhost:27020/api";
export const MailApi = 'https://uservices.netizar.com' //puerto 3535
export const SmsApi = 'https://sms.netizar.com/api' // puerto 3537
export const TelemetriaApi = 'http://prod.netizar.com:3536/api'
export const titulonavbar = 'DevExt'
export const UrlApp = 'https://dev.netizar.com/'
// export const UrlApiEnvasado = "http://192.168.34.65:9000/api";
export const UrlApiEnvasado = 'http://localhost:9000/api'

export const UrlFacturacion= "http://localhost:3001"