// Developer     : Magalí Perea
// Creation Date :
// Version       :
// Description   : Este componente contiene datos y funciones necesarias para guardar una nueva factibilidad.

import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Box, Snackbar, Alert, AlertTitle } from "@mui/material";
import { v4 as uuidv4 } from "uuid"; // Me da un único ID para poner en el comprobante

import FormCliente from "../formularios/FormCliente";
import FormDir from "../formularios/FormDir";
import FormTanques from "../formularios/FormTanques";
import FormArtefactos from "../formularios/FormArtefactos";
import FormCostos from "../formularios/FormCostos";
import FormDirAmpliacion from "../formularios/FormDirAmpliacion";
import PanelAnalisis from "../components/PanelAnalisis";
import TabPanel from "../components/TabPanel";
import ErrorModal from "../../ErrorModal";
import Notify from "../../Notify";
import TitleUploaded from "../../../components/TitleUploaded";

import { UrlApi } from "../../../services/apirest";
import { getCodeArg } from "../../../services/library";
import { postFactibilidades } from "../savefunctions";
import * as Validaciones from "../validaciones";
import * as Calc from "../calculos.js";

function AddFact(props) {
  //Es el uuid que utilizo para vincularme con el domicilio de entrega, los artefactos, archivos y todo lo que se relacione con la factibilidad.
  //Si la factibilidad es producto de una ampliación, utilizo el nro_cliente como identificador.
  //Si la factibilidad es producto de una nueva instalación para un nuevo cliente, genero uno nuevo.
  const [uuid, setUuid] = useState(uuidv4()); //Este UUID voy a usar para vincularme con el resto de las cosas que necesito.


  const usuario = localStorage.getItem("user");
  const [dataComercial, setDataComercial] = useState(); //Estado donde se guardarán los datos del comercial que sean necesarios.
  const [factConfig, setFactConfig] = useState(null); //Estado donde se guardarán los parámetros necesarios para los cálculos.
  const [valorDolar, setValorDolar] = useState(0);
  const [facturaespecial, setfacturaEspecial] = useState(false);
  const [factura_consfinal, setfactura_consfinal] = useState(false);
  //Estado donde se guardarán los datos de la factibilidad (mayormente, ingresados por el usuario)
  const [dataFact, setDataFact] = useState({
    factuuid: uuid,
    id_sucatiende: null,
    id_estado: null,
    precio_dolar: 0,
    costotranspxkm: null,
    precio_lt: null,
    precio_kg: null,
    canttk_05: null,
    canttk_1: null,
    canttk_2: null,
    canttk_4: null,
    canttk_7: null,
    canttk_otros: null,
    variacion_porcentual: "",
    motivo1: "",
    motivo2: "",
    motivo3: "",
    cantmotivo1: null,
    cantmotivo2: null,
    cantmotivo3: null,
    motivocli1: "",
    motivocli2: "",
    motivocli3: "",
    cantmotivocli1: null,
    cantmotivocli2: null,
    cantmotivocli3: null,
    dist_suc_cli: 0, //Distancia cliente-sucursal
    dist_prov_suc: 0, //Distancia proveedor-sucursal
    usuario_registra: usuario,
    id_tipofact: props.idtipofact,
    id_cliente_ampliacion: props.idtipofact !== 235 ? props.cliente.id : null,
    id_domicilio_ampliacion:
      props.idtipofact === 236 ? props.direccion.id : null,
  });

  //Estado donde se guardarán los datos del cliente al que se le confecciona la factibilidad.
  const [dataCliente, setDataCliente] = useState({
    razon_social: props.idtipofact !== 235 ? props.cliente.razon_social : "",
    cuit: "",
    situacion_iva: 27,
    direccion_fiscal: "",
    mails: "",
    telefono1: "",
    telefono2: "",
    telefono3: "",
    // id_sucursal: null,
    id_empresa: "",
    id_provincia: "",
    id_localidad: "",
    id_segmento: "",
    id_subsegmento: "",
    id_comercial: "",
    nro_cliente: uuid,
    id_tipocliente: 1,
    activo: 0,
    usuario: usuario,
    facturacion_especial: 0,
    factura_consfinal: 0,
    id_categoria:14
  });
  const [tipo, setTipo] = useState(1); // Guarda si el dato ingresado en el cliente es CUIT o DNI.
  const [esDomEntrega, setEsDomEntrega] = useState();

  //Estado donde se guardan los datos del domicilio de entrega para el que confeccionamos la factibilidad.
  const [dataDir, setDataDir] = useState(
    props.idtipofact === 236
      ? {
        id: props.direccion.id,
        direccion_entrega: "",
        direccion_entrega_mostrar: props.direccion.direccion_entrega,
        provincia: props.direccion.provincia,
        localidad: props.direccion.localidad,
        cliente: props.direccion.Cliente,
        latitud: !!props.direccion.latitud ? props.direccion.latitud : null,
        longitud: !!props.direccion.longitud
          ? props.direccion.longitud
          : null,
        nombre_direccion: props.direccion.nombre_direccion,
        id_sucursal:
          props.direccion.tipo_sucursal !== "planta"
            ? ""
            : props.direccion.id_sucursal,
        verificada: 1,
        mostrar_desplegable: props.direccion.tipo_sucursal !== "planta",
        sucursal: props.direccion.sucursal,
        id_provincia: props.direccion.id_provincia,
        id_localidad: props.direccion.id_localidad,
        id_producto: 1,        
        id_tipomoneda:props.direccion.id_tipomoneda
        // id_empresa:props.direccion.id_empresa || null,
      }
      : {
        direccion_entrega: "",
        contacto: "",
        telefono1: "",
        telefono2: "",
        telefono3: "",
        mails: "",
        latitud: "",
        longitud: "",
        id_cliente: (props.cliente && props.cliente.id) || null,
        id_provincia: "",
        id_localidad: "",
        usuario: usuario,
        activo: 0,
        nombre_direccion: "",
        id_sucursal: "", //Si es una ampliación en un domicilio que ya existe, le paso la sucursal
        id_producto: 1,
        verificada: 1,
        nro_dir: uuid,
        // id_empresa: "",
        id_tipomoneda: 2,
      }
  );

  const [dataSucursal, setDataSucursal] = useState({}); //Guarda los datos de la sucursal que abastecerá el domicilio de entrega. ES DECIR, DATOS DE LA SUCURSAL ELEGIDA EN EL FORMULARIO DE DIRECCION DE ENTREGA.

  //Estados donde se guardarán los artefactos que deben abastecerse.
  const [listaArtefactos, setListaArtefactos] = useState([
    {
      factuuid: uuid,
      artefacto: null,
      cantidad: null,
      kcalh: null,
      horasdia: null,
      diasanio: null,
      kcalsanual: null,
      consumoanual: null,
    },
  ]);

  //Estados donde se guardarán los cálculos realizados.
  const [calculos, setCalculos] = useState({
    preciokg_siniva: null,
    costot1: 0,
    costot2: 0,
    costotranstks: 0,
    costototaltks: 0,
    costosabsxlaempresa: 0,
    inversiontotal: 0,
    margen: 0,
    kganual: 0,
    kganualminimo: 0,
    cierreestimado: 0,
    cierreminimo: 0,
    costos_operativos_xtn_pesos: 0,
  });

  //Validaciones
  const [validado, setValidado] = useState({
    cliOK:
      props.formInicioData.id_tipofact === 236 ||
        props.formInicioData.id_tipofact === 237
        ? true
        : false, //
    dirOK: props.direccion && !!props.direccion.id_sucursal && !!props.direccion.latitud && !!props.direccion.longitud ? true : false,
    artOK: false,
    tksOK: false,
    costosOK: false,
  });

  const [validacionDiasHs, setValidacionDiasHs] = useState({
    errorHs: [],
    errorDias: [],
  });

  const [latLongParams, setLatLongParams] = useState({
    latOK: true,
    longOK: true,
    readOnly: false,
  });

  //Manejadores de componentes visuales.
  const [activeTab, setActiveTab] = useState(
    props.idtipofact === 235 ? "cliente" : "domicilio"
  );

  const [attach, setAttach] = useState(false);
  const [codArg, setCodArg] = useState("");
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [mapParams, setMapParams] = useState({
    zoom: 3,
    center: {
      lat: -38.416097,
      lng: -63.616672,
    },
  });

  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });

  const [showModalError, setShowModalError] = useState({
    open: false,
    title: "",
    description: "",
  });

  //Obtengo los datos de la sucursal seleccionada.
  const getDataSucursal = () => {
    fetch(UrlApi + "/sucursales/" + dataDir.id_sucursal)
      .then((data) => data.json())
      .then((data) => {
        setDataSucursal(data[0]);
        !dataDir.latitud &&
          !dataDir.longitud &&
          setMapParams({
            ...mapParams,
            center: {
              lat: data[0].latitud * 1,
              lng: data[0].longitud * 1,
            },
          });
      });
  };

  //Una vez que tengo la sucursal que se seleccionó en el formulario del domicilio de entrega, busco sus datos.
  //Los datos que necesito de la sucursal son:
  // - Distancia a boca de carga
  // - Latitud y longitud de la sucursal
  useEffect(() => {
    !!dataDir.id_sucursal && getDataSucursal();
  }, [dataDir.id_sucursal]);

  //Obtengo la configuración de la factibilidad.
  const getFactConfig = () => {
    fetch(UrlApi + "/factconfig")
      .then((data) => data.json())
      .then((data) => {
        setFactConfig(data[0]); // Guardo todo el registro en factConfig
        setDataFact({
          ...dataFact,
          precio_kg: data[0].precio_venta_x_kg, // lo inicializo como está en los parámetros, pero luego lo puedo modificar
        }); //
        setCalculos((calculos) => ({
          ...calculos,
          preciokg_siniva: parseFloat(
            data[0].precio_venta_x_kg / 1.105
          ).toFixed(4),
        }));
      });
  };

  //Obtiene el valor del dolar oficial llamando a esta api:
  const getValorDolar = () => {
    fetch("https://api.bluelytics.com.ar/v2/latest")
      .then((data) => data.json())
      .then((data) => {
        const valorDolar = data.oficial.value_sell;
        setValorDolar(valorDolar);
        setCalculos((calculos) => ({
          ...calculos,
          costos_operativos_xtn_pesos: parseInt(
            factConfig.costos_operativos * valorDolar
          ),
        }));
      });
  };

  const getComercial = () => {
    fetch(UrlApi + "/representantes/getid/" + usuario)
      .then((data) => data.json())
      .then((data) => {
        setDataCliente((dataCliente) => ({
          ...dataCliente,
          id_comercial: data.length > 0 ? data[0].id : 10,
        }));
        //aca lo que puede suceder es que el comercial que esté haciendo la factibilidad, no tenga asociado su usuario. entonces, como los datos que utilizo de el, no me los trae porque llamo al comercial por usuario, y este no tiene usuario registrado, directamente le ponga que el nombre es el que sigue:
        setDataComercial(data[0] || { nombre_apellido: "Desconocido" });
      });
  };

  useEffect(() => {
    getCodeArg(setCodArg);
    getFactConfig();
    getComercial();
  }, []);

  useEffect(() => {
    !!factConfig && getValorDolar();
  }, [factConfig]);

  useEffect(() => {
    props.formInicioData.id_tipofact === 235 && Validaciones.validarClientes(dataCliente, validado, setValidado);
  }, [dataCliente]);

  useEffect(() => {
    Validaciones.validarDirecciones(dataDir, validado, setValidado, props.formInicioData.id_tipofact)
  }, [dataDir]);

  useEffect(() => {
    Validaciones.validarCostos(dataFact, validado, setValidado);
    Calc.calcularCostosAbsXEmpresa(dataFact, calculos, setCalculos);
  }, [
    dataFact.motivo1,
    dataFact.cantmotivo1,
    dataFact.motivo2,
    dataFact.cantmotivo2,
    dataFact.motivo3,
    dataFact.cantmotivo3,
    dataFact.motivocli1,
    dataFact.motivocli2,
    dataFact.motivocli3,
    dataFact.cantmotivocli1,
    dataFact.cantmotivocli2,
    dataFact.cantmotivocli3,
  ]);

  // //------------------- ARTEFACTOS --------------- //
  const almacenarArtefactos = () => {
    const values = [...listaArtefactos];
    values.push({
      factuuid: uuid,
      artefacto: null,
      cantidad: null,
      kcalh: null,
      horasdia: null,
      diasanio: null,
      kcalsanual: null,
      consumoanual: null,
    });
    setListaArtefactos(values);
  };

  const deshacerAlmacenar = (index) => {
    const values = [...listaArtefactos];
    values.splice(index, 1);
    setListaArtefactos(values);

    const totalAnual = values.reduce(
      (total, artefacto) => total + parseFloat(artefacto.consumoanual || 0),
      0
    );
    setCalculos((calculos) => ({ ...calculos, kganual: totalAnual }));
  };

  const handleInputChange = (index, event) => {
    const values = [...listaArtefactos];
    const updatedValue = event.target.name;
    values[index][updatedValue] =
      event.target.value === "" ? null : event.target.value;
    //La siguiente línea, hace el cáculo de las kcal que consume al año el artefacto.
    values[index].kcalsanual =
      values[index].cantidad *
      values[index].kcalh *
      values[index].horasdia *
      values[index].diasanio;

    // La siguiente línea calcula el consumoanual en kg por cada artefacto:
    values[index].consumoanual = (values[index].kcalsanual / 12000).toFixed(2);

    // Calcular y actualizar el consumo total anual
    const totalAnual = values.reduce(
      (total, artefacto) => total + parseFloat(artefacto.consumoanual || 0),
      0
    );
    setCalculos((calculos) => ({
      ...calculos,
      kganual: totalAnual,
      costos_operativos_total:
        (totalAnual / 1000) * calculos.costos_operativos_xtn_pesos,
    }));

    //valido que las horas y los dias sean correctos
    if (event.target.name === "horasdia") {
      Validaciones.validarHs(
        index,
        event.target.value,
        validacionDiasHs,
        setValidacionDiasHs
      );
    } else if (event.target.name === "diasanio")
      Validaciones.validarDias(
        index,
        event.target.value,
        validacionDiasHs,
        setValidacionDiasHs
      );
  };

  //****************************************************ANALISIS PARA PANEL *****************************************************************/

  /* COSTOS DE TRANSPORTE */
  /*Costo t1 ==> Costo de transportar los kg desde la boca del productor/proveedor hasta la sucursal. 
  Cálculo: ((costo del transporte x km * distancia entre sucursal y proveedor)*2) / 21.000
  *2 Porque se calcula ida y vuelta. 
  /21.000 Es porque son la cantidad de kg que puede transportar el T1, y lo que queremos calcular es el costo de cuanto nos sale transportar cada kg.*/
  useEffect(() => {
    if (
      !!factConfig &&
      !!factConfig.costo_transporte_km &&
      !!dataSucursal.distanciabocacarga
    ) {
      Calc.calcularCostoT1(
        dataSucursal.distanciabocacarga,
        factConfig.costo_transporte_km,
        calculos,
        setCalculos
      );
    }
  }, [factConfig, dataSucursal.distanciabocacarga]);

  useEffect(() => {
    if (
      !!factConfig &&
      !!factConfig.costo_transporte_km &&
      !!dataFact.dist_suc_cli
    ) {
      Calc.calcularCostoT2(
        dataFact.dist_suc_cli,
        factConfig.costo_transporte_km,
        calculos,
        setCalculos
      );
    }
  }, [factConfig, dataFact.dist_suc_cli]);

  useEffect(() => {
    !!factConfig &&
      !!valorDolar &&
      Calc.calcularCostoTotalTks(
        dataFact,
        factConfig,
        valorDolar,
        calculos,
        setCalculos
      );
    Validaciones.validarTanques(dataFact, validado, setValidado);
  }, [
    dataFact.canttk_05,
    dataFact.canttk_1,
    dataFact.canttk_2,
    dataFact.canttk_4,
    dataFact.canttk_7,
    dataFact.canttk_otros,
    valorDolar,
  ]);

  useEffect(() => {
    if (!!factConfig && !!factConfig.costo_producto_tn) {
      Calc.calcularMargen(
        factConfig.costo_producto_tn,
        calculos.preciokg_siniva,
        calculos,
        setCalculos
      );
    }
  }, [factConfig, calculos.preciokg_siniva]);

  useEffect(() => {
    Calc.calcularInversionTotal(calculos, setCalculos);
  }, [
    calculos.costos_operativos_total,
    calculos.costosabsxlaempresa,
    calculos.costototaltks,
    calculos.costotranstks,
  ]);

  useEffect(() => {
    Calc.calcularConsumoMinimo(
      calculos,
      dataFact.variacion_porcentual,
      setCalculos
    );
  }, [calculos.kganual, dataFact.variacion_porcentual]);

  useEffect(() => {
    Calc.calcularCierreXVolEstimado(calculos, setCalculos);
    Calc.calcularCierreXVolMinimo(calculos, setCalculos);
    validarDiasHsFinal();
  }, [
    calculos.inversiontotal,
    calculos.kganual,
    calculos.kganualminimo,
    calculos.margen,
    calculos.costot1,
    calculos.costot2,
  ]);

  const validarDiasHsFinal = () => {
    //Se fija que las horas y los dias estén bien, y ademas que se haya calculado el consumo anual para saber si todo está bien.
    const horasvalidadas = validacionDiasHs.errorHs.every(
      (error) => error !== true
    );
    const diasvalidados = validacionDiasHs.errorDias.every(
      (error) => error !== true
    );
    const horasydias = horasvalidadas && diasvalidados;
    const OK = horasydias && !!calculos.kganual;
    setValidado({ ...validado, artOK: OK });
  };

  const handleClose = () => {
    setNotify({
      open: false,
      severity: "",
      color: "",
      mensaje: "",
    });
    window.location.reload()
  };

  const [openSnack, setOpenSnack] = useState(
    props.idtipofact === 236 &&
    (!props.direccion.latitud || !props.direccion.longitud)
  );

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // ---------------------------------- FUNCIONES PARA GUARDAR ---------------------------------------//

  const updatedDir = {
    id: dataDir.id,
    latitud: dataDir.latitud,
    longitud: dataDir.longitud,
    verificada: 1,
    id_sucursal: dataDir.id_sucursal,
    // id_empresa:dataDir.id_empresa
  };

  const factData = {
    factuuid: uuid,
    id_sucatiende: dataDir.id_sucursal,
    id_estado: 198,
    //Parámetros:
    precio_dolar: valorDolar,
    costotranspxkm: factConfig && factConfig.costo_transporte_km,
    precio_kg: dataFact.precio_kg,
    // costo_prod_tn: dataFact.costo_prod_tn,
    costo_prod_tn: factConfig && factConfig.costo_producto_tn,
    //INPUTS
    canttk_05: dataFact.canttk_05,
    canttk_1: dataFact.canttk_1,
    canttk_2: dataFact.canttk_2,
    canttk_4: dataFact.canttk_4,
    canttk_7: dataFact.canttk_7,
    canttk_otros: dataFact.canttk_otros,
    variacion_porcentual: dataFact.variacion_porcentual,
    motivo1: dataFact.motivo1,
    motivo2: dataFact.motivo2,
    motivo3: dataFact.motivo3,
    cantmotivo1: dataFact.cantmotivo1,
    cantmotivo2: dataFact.cantmotivo2,
    cantmotivo3: dataFact.cantmotivo3,
    motivocli1: dataFact.motivocli1,
    motivocli2: dataFact.motivocli2,
    motivocli3: dataFact.motivocli3,
    cantmotivocli1: dataFact.cantmotivocli1,
    cantmotivocli2: dataFact.cantmotivocli2,
    cantmotivocli3: dataFact.cantmotivocli3,
    // Distancias:
    dist_suc_cli: dataFact.dist_suc_cli, //Distancia cliente-sucursal
    dist_prov_suc: dataSucursal.distanciabocacarga, //Distancia proveedor-sucursal
    usuario_registra: dataFact.usuario_registra,
    id_tipofact: dataFact.id_tipofact,
  };

  //Armado de cuerpo para la consulta:
  const reqBody = {
    clienteData: props.formInicioData.id_tipofact === 235 ? {...dataCliente, 
      porc_perc_iva: parseInt(dataCliente.situacion_iva)===1 ? 1.75 : null , 
      facturacion_especial: parseInt(dataCliente.situacion_iva)===1 ? (dataCliente.facturacion_especial || null ): null,
      factura_consfinal: parseInt(dataCliente.situacion_iva) === 4 ? (dataCliente.factura_consfinal || null )  : null
    } : {}, //aca podria preguntar el tipo de factibilidad, y de acuerdo a eso mandarlo vacio o no.
    // dirData: dataDir,
    dirData:
      props.formInicioData.id_tipofact === 235 ||
        props.formInicioData.id_tipofact === 237
        ? dataDir
        : updatedDir,
    factData:
      props.formInicioData.id_tipofact !== 235
        ? {
          ...factData,
          id_cliente_ampliacion: dataFact.id_cliente_ampliacion,
          id_domicilio_ampliacion: dataFact.id_domicilio_ampliacion,
        }
        : factData,
    artData: listaArtefactos,
  };

  //La funcion de guardado esta desarrollada en el archivo savefunctions
  async function guardar(e) {
    setDisabledGuardar(true);
    postFactibilidades(
      e,
      "/factibilidades",
      reqBody,
      setNotify,
      factConfig.mail_autorizante,
      dataComercial.nombre_apellido,
      dataCliente.razon_social,
      dataDir.direccion_entrega,
      calculos.kganual,
      dataComercial.mail
    );
  }

  return (
    <div>
      <Container maxWidth="xl">
        <TitleUploaded
          title={"Nueva Factibilidad"}
          handleClose={() => {
            props.setShowAdd(false);
            props.setShowForm(true);
            props.setFormData({
              id_tipofact: "",
            });
            props.setDomicilios(null);
          }}
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={7000}
          onClose={handleCloseSnack}
        >
          <Alert severity="warning" variant="filled" onClose={handleCloseSnack}>
            <AlertTitle>Atención</AlertTitle>
            Es necesario ubicar la dirección de entrega en el mapa para poder
            obtener las coordenadas.
          </Alert>
        </Snackbar>
        {/* <form onSubmit={guardar}> */}
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={8.9}
            borderRadius={5}
            style={{ height: "473px" }}
            borderColor="#B2BABB"
            // backgroundColor="#ffff22"
          >
            <Paper
              variant="elevation"
              elevation={24}
              style={{ height: "473px", width: "100%", borderRadius: 20 }}
              px={5}
            >
              {notify.open ? (
                <Notify
                  open={notify.open}
                  color={notify.color}
                  severity={notify.severity}
                  mensaje={notify.mensaje}
                  handleClose={() => handleClose()}
                />
              ) : null}
              <TabPanel
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                validado={validado}
                dataFact={dataFact}
              />
              <Box>
                {activeTab === "cliente" ? (
                  <FormCliente
                    dataCliente={dataCliente}
                    setDataCliente={setDataCliente}
                    codArg={codArg}
                    tipo={tipo}
                    setTipo={setTipo}
                    cliOK={validado.cliOK}
                    esDomEntrega={esDomEntrega}
                    setEsDomEntrega={setEsDomEntrega}
                    setDataDir={setDataDir}
                    dataDir={dataDir}
                    setActiveTab={setActiveTab}
                    facturaespecial={facturaespecial}
                    setfacturaEspecial={setfacturaEspecial}
                    factura_consfinal={factura_consfinal}
                    setfactura_consfinal={setfactura_consfinal}
                  />
                ) : null}
                {activeTab === "domicilio" ? (
                  <>
                    {props.idtipofact === 235 || props.idtipofact === 237 ? (
                      <FormDir
                        dataSucursal={dataSucursal}
                        setDataSucursal={setDataSucursal}
                        dataCliente={dataCliente}
                        dataDir={dataDir}
                        setDataDir={setDataDir}
                        mapParams={mapParams}
                        setMapParams={setMapParams}
                        codArg={codArg}
                        dirOK={validado.dirOK}
                        dataFact={dataFact}
                        setDataFact={setDataFact}
                        latLongParams={latLongParams}
                        setLatLongParams={setLatLongParams}
                        setActiveTab={setActiveTab}
                      />
                    ) : (
                      <FormDirAmpliacion
                        dataSucursal={dataSucursal}
                        setDataSucursal={setDataSucursal}
                        dataCliente={dataCliente}
                        dataDir={dataDir}
                        setDataDir={setDataDir}
                        mapParams={mapParams}
                        setMapParams={setMapParams}
                        codArg={codArg}
                        dirOK={validado.dirOK}
                        dataFact={dataFact}
                        setDataFact={setDataFact}
                        latLongParams={latLongParams}
                        setLatLongParams={setLatLongParams}
                        setActiveTab={setActiveTab}
                        direccion={props.direccion}
                      />
                    )}
                  </>
                ) : null}
                {activeTab === "artefactos" ? (
                  <FormArtefactos
                    listaArtefactos={listaArtefactos}
                    setListaArtefactos={setListaArtefactos}
                    almacenarArtefactos={almacenarArtefactos}
                    deshacerAlmacenar={deshacerAlmacenar}
                    handleInputChange={handleInputChange}
                    uuid={uuid}
                    dataFact={dataFact}
                    setDataFact={setDataFact}
                    validacionDiasHs={validacionDiasHs}
                    setActiveTab={setActiveTab}
                  />
                ) : null}

                {activeTab === "tanques" ? (
                  <FormTanques
                    dataFact={dataFact}
                    setDataFact={setDataFact}
                    calculos={calculos}
                    setActiveTab={setActiveTab}
                  />
                ) : null}
                {activeTab === "costos" ? (
                  <FormCostos dataFact={dataFact} setDataFact={setDataFact} />
                ) : null}
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <PanelAnalisis
              attach={attach}
              setAttach={setAttach}
              validado={validado}
              dataFact={dataFact}
              setDataFact={setDataFact}
              calculos={calculos}
              setCalculos={setCalculos}
              disabledGuardar={disabledGuardar}
              setShowTable={props.setShowTable}
              setShowForm={props.setShowAdd}
              guardar={guardar}
              valorDolar={valorDolar}
              factConfig={factConfig && factConfig}
            />
          </Grid>
          {showModalError.open ? (
            <ErrorModal
              open={showModalError.open}
              handleClose={() =>
                setShowModalError({ open: false, title: "", description: "" })
              }
              title={showModalError.title}
              description={showModalError.description}
            />
          ) : null}
        </Grid>
        {/* </form> */}
      </Container>
    </div>
  );
}

export default AddFact;

//MODIFICACIONES BY KND
// 2 de agosto ->
//Agregué a new fact en el formClientes si tiene facturacion especial
//unos dias antes agregué a dataDir id_empresa
// 7 de agosto -> Agregué id_tipomoneda -
//pd: espero que ande todo bien plis no me despidan tengo un hijo