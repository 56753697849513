//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 12/09/2024
// Version : 1
// Description : agregar pertenencia
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  Autocomplete,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import { logEventos } from "../../services/services";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EastIcon from "@mui/icons-material/East";

function AgregarPertenencia(props) {
  const [datosPertenencia, setDatosPertenencia] = useState({
    id_pertenencia: null,
    id_tanque: null,
    id_grupo: null,
    estado_pertenencia: 1,
  });
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };

  const almacenar = (e) => {
    setDatosPertenencia({
      ...datosPertenencia,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const [tanques, setTanques] = useState();
  const [tanqueSeleccionado, setTanqueSeleccionado] = useState("");

  const obtenerTanques = () => {
    fetch(UrlApi + "/tanque_rehabilitacion/funciona")
      .then((data) => data.json())
      .then((data) => setTanques(data));
  };
  useEffect(() => {
    obtenerTanques();
  }, []);

  // console.log("TANQUESS", tanques);
  // console.log("TANQUE SELECCIONADO", tanqueSeleccionado);

  const [grupos, setGrupos] = useState();
  const [grupoSeleccionado, setGrupoSeleccionado] = useState("");

  const obtenerGrupos = () => {
    fetch(UrlApi + "/tanque_grupos/activos")
      .then((data) => data.json())
      .then((data) => setGrupos(data));
  };
  useEffect(() => {
    obtenerGrupos();
  }, []);

  // console.log("GRUPOS", grupos);
  // console.log("GRUPO SELECCIONADO", grupoSeleccionado);

  const [existe, setExiste] = useState();

  const obtenerExiste = () => {
    fetch(
      UrlApi +
        "/tanque_pertenencia/existePertenencia/" +
        tanqueSeleccionado.id_tanque +
        "/" +
        grupoSeleccionado.id_grupo
    )
      .then((data) => data.json())
      .then((data) => setExiste(data));
  };
  useEffect(() => {
    obtenerExiste();
  }, [tanqueSeleccionado, grupoSeleccionado]);

  // console.log("existe", existe);

  const guardarPertenencia = async (e) => {
    // logEventos("Guardar un nuevo grupo", "Configuración de Checklist", "Se guardo el grupo creado", localStorage.getItem("user"))
    setDisabledGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanque_pertenencia", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tanque: tanqueSeleccionado.id_tanque,
        id_grupo: grupoSeleccionado.id_grupo,
        estado_pertenencia: 1,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";

  useEffect(() => {
    if (
      !tanqueSeleccionado ||
      !grupoSeleccionado ||
      (existe && existe.length > 0)
    ) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [tanqueSeleccionado, grupoSeleccionado, existe]);

  return (
    <div>
      {" "}
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Cargar nueva pertenencia
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={guardarPertenencia} autoComplete="off">
          <Box
            backgroundColor="#F4F6F6"
            mt={2}
            p={3}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={3}>
                <Autocomplete
                  // disabled={!tanques}
                  disableClearable
                  size="small"
                  required
                  noOptionsText={"Tanque INEXISTENTE"}
                  options={tanques}
                  autoHighlight
                  getOptionLabel={(users) => users.patente}
                  onChange={(event, value) => setTanqueSeleccionado(value)}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.patente}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      focused
                      size="small"
                      placeholder="Tipee para buscar..."
                      label="Tanque movil"
                    />
                  )}
                />
              </Grid>
              <EastIcon />
              <Grid item xs={3}>
                <Autocomplete
                  // disabled={!grupos}
                  disableClearable
                  size="small"
                  required
                  noOptionsText={"Grupo INEXISTENTE"}
                  options={grupos}
                  autoHighlight
                  getOptionLabel={(users) => users.nombre_grupo}
                  onChange={(event, value) => setGrupoSeleccionado(value)}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.id}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.nombre_grupo}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      focused
                      size="small"
                      placeholder="Tipee para buscar..."
                      label="Grupo"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={handleClose} color="error">
                Volver
              </Button>

              <Button
                disabled={disabledGuardar}
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar pertenencia
              </Button>
            </Grid>
          </Box>
        </form>
      </Container>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}{" "}
    </div>
  );
}

export default AgregarPertenencia;
