//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 06/09/2024
// Version : 1
// Description : tabla de servicios para la info de tanque
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { Box,Container } from "@mui/material";
import moment from "moment";
import { UrlApi } from "../../services/apirest";
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

function TablaServicios(props) {
  const [datosTanque, setDatosTanque] = useState(props.datosTanque); // viene del motivos_salida {editar}
  const [serviciosXtanque, setServiciosXtanque] = useState();
  const [tabla, setTabla] = useState(true);

  const obtenerServicioXtanque = () => {
    fetch(UrlApi + "/tanque_servicio/servicioXtanque/" + datosTanque.id_tanque)
      .then((data) => data.json())
      .then((data) => setServiciosXtanque(data));
  };
//  console.log("SERVICIOS X TANQUE", serviciosXtanque);
  //ejecuta la funcion de obtener
  useEffect(() => {
    obtenerServicioXtanque();
  }, []);

 // console.log("DATOS TANQUE SERVICIOS", datosTanque);

  const columns = useMemo(
    () => [
      { header: 'Desde', accessorFn: (desde) => moment(desde.servicio_desde).format("DD-MM-YYYY"), size: 100 },
      { header: 'Hasta', accessorFn: (hasta) => moment(hasta.servicio_hasta).format("DD-MM-YYYY"), size: 100 },
      { header: "Detalle", accessorKey: "detalle_servicio", size: 200 },
      { header: "Motivo", accessorKey: "motivo_servicio", size: 200 },
      { header: "Lugar", accessorKey: "lugar_servicio", size: 100 },
    ],
    []
  );
  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "success",
    size: "small",
  };
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)" //background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const initialState = {
    //para inicializar la tabla, y la cantidad de paginas q se ven ej 5
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 5 },
    // sorting: [
    //     {
    //       id: 'servicio_desde', 
    //       desc: true,
    //     },
    //   ],
  };
  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  return (
    <div>
      <Container maxWidth="xl" mt={1} mb={2}>
        {serviciosXtanque && tabla ? (
          <Box mt={2} boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableColumnResizing
                enableExpandAll={false}
                muiTableHeadCellProps={tableHeadCellProps}
                enableToolbarInternalActions={true} //las acciones al lado del search (agregar y full screen)
                enableGrouping // para permitir agrupar x estado x ej
                layoutMode="grid"
                muiTableBodyCellProps={{
                  align: "left", //el contenido de la tabla
                }}
                enableColumnActions={true} //los puntitos de al lado de los titulos (acciones) -> hay que activarlo para agrupar x estado x ej
                enableColumnFilters={false} //desactiva filtros x  columna
                enableHiding={false} // opcion de ocultar columna pero activando el enablecolumaction
                enableDensityToggle={false} //
                localization={MRT_Localization_ES} //idioma
                enableColumnDragging={false} //para arrastrar columnas
                initialState={initialState}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={serviciosXtanque} //con lo q la completas (esto cambia siempre)
                enableStickyFooter
                enableSorting={true}
              //  enableEditing
                positionActionsColumn="last" // posicion de la columna de acciones al final
                // ACCIONES
                renderTopToolbarCustomActions={() => (
                  <>
                    <Typography variant="poster" fontSize={26}>
                      Servicio técnico del tanque
                    </Typography>
                  </>
                )}
                renderToolbarInternalActions={(
                  { table } // boton para nuevo , al lado del buscar
                ) => (
                  <>
                    <MRT_FullScreenToggleButton table={table} />
                  </>
                )}
              />
            </ThemeProvider>
            <br />
          </Box>
        ) : null}
      </Container>
    </div>
  );
}

export default TablaServicios;
