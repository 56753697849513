import {UrlApi} from "../../../../services/apirest"
import moment from 'moment'

export async function putClientes(e, borrariibb,codArg, id_cliente, body, setNotify) {
  e.preventDefault();
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return null;
    return phoneNumber.startsWith(codArg) ? phoneNumber : codArg + phoneNumber;
  };
  
  const sitIva= parseInt(body.situacion_iva)
  const reqBody = {
    dataCliente: {
      usuario: localStorage.getItem("user"),
      sisven_id: body.sisven_id === "" ? null : body.sisven_id,
      razon_social: body.razon_social,
      direccion_fiscal: body.direccion_fiscal,
      telefono1: formatPhoneNumber(body.telefono1),
      telefono2: formatPhoneNumber(body.telefono2),
      telefono3: formatPhoneNumber(body.telefono3),
      mails: body.mails,
      precio_especial: body.precio_especial,
      cuit: body.cuit,
      id_categoria: body.id_categoria,
      id_localidad: body.id_localidad,
      id_comercial: body.id_comercial,
      id_segmento: body.id_segmento,
      id_subsegmento: body.id_subsegmento,
      id_provincia: body.id_provincia,
      situacion_iva: sitIva,
      id_condicionpago: body.id_condicionpago,
      limite_credito: body.limite_credito === "" ? null : body.limite_credito,
      observaciones: body.observaciones,
      id_tipocliente: body.id_tipocliente,
      pesada_cliente: body.pesada_cliente,
      unidad_fact: body.unidad_fact || null,
      tipo_doc_afip: body.tipo_doc_afip,
      id_empresa:body.id_empresa || null , 
      ...(sitIva===1 ? {
        // aca cuando es responsable inscripto
        factura_consfinal: null,
        facturacion_especial: body.facturacion_especial || null ,
        porc_perc_iva: body.porc_perc_iva || 1.75,
        porc_exc_perc_iva: body.porc_exc_perc_iva || null,
        vto_exc_perc_iva: body.vto_exc_perc_iva ? moment(body.vto_exc_perc_iva).format("YYYY-MM-DD") : null,
      } : sitIva===4 ? { 
        // aca cuando es consumidor final
        factura_consfinal: body.factura_consfinal || null,
        facturacion_especial: null,
        porc_perc_iva: null,      
        porc_exc_perc_iva: null,
        vto_exc_perc_iva:null,
      } : { 
        // ninguna de las anteriores: 
        factura_consfinal: null,
        facturacion_especial: null,
        porc_perc_iva: null,
        porc_exc_perc_iva: null,
        vto_exc_perc_iva:null,
       
      })
    },
    borrariibb: borrariibb || 0,
  };


  const response = await fetch(
    UrlApi + "/clientes/updclientesandiibb/" + body.id,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    }
  );

  if (response.ok === false) {
    setNotify({
      open: true,
      severity: "error",
      color: "#FADBD8",
      mensaje: "Error al guardar",
    });
  } else {
    try {
      const json = await response.json();
      if (json.message) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else if (json.sqlMessage) {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    } catch (error) {
      if (error.toString().includes("is not valid JSON")) {
        setNotify({
          open: true,
          severity: "success",
          color: "#D4EFDF",
          mensaje: "Guardado",
        });
      } else {
        setNotify({
          open: true,
          severity: "error",
          color: "#FADBD8",
          mensaje: "Error al guardar",
        });
      }
    }
  }
}
