import React from "react";
import { useEffect, useState } from "react";
import { UrlApi } from "../../../services/apirest";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputMask from "react-input-mask";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { validarCuit } from "../validaciones";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getData } from "../../../services/library";

function FormCliente(props) {
  const [localidades, setLocalidades] = useState(null);
  const [segmentos, setSegmentos] = useState(null);
  const [subsegmentos, setSubsegmentos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [sitIva, setSitIva] = useState(null);
  const [empresas, setEmpresas] = useState();
  const [condicionesPago, setCondicionesPago] = useState(null);
  const [unidadesFact, setUnidadesFact] = useState(null);

  //Fetchs para traer datos de la api a los desplegables
  const obtenerSit = () => {
    fetch(UrlApi + "/condiciones_iva")
      .then((data) => data.json())
      .then((data) => setSitIva(data));
  };

  const obtenerlocalidades = () => {
    fetch(UrlApi + "/localidades/prov/" + props.dataCliente.id_provincia) //Esta ruta trae las localidades segun la prov seleccionada
      .then((data) => data.json())
      .then((data) => setLocalidades(data));
  };

  const obtenerEmpresas = () => {
    fetch(UrlApi + "/empresas") //Esta ruta trae las localidades segun la prov seleccionada
      .then((data) => data.json())
      .then((data) => setEmpresas(data));
  };

  const obtenersegmentos = () => {
    fetch(UrlApi + "/segmentos")
      .then((data) => data.json())
      .then((data) => setSegmentos(data));
  };

  const obtenersubsegmentos = () => {
    fetch(UrlApi + "/subsegmentos/seg/" + props.dataCliente.id_segmento) //Esta ruta trae los subsegmentos segun segmento seleccionado
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setSubsegmentos(data)); //seteo choferes los json
  };

  const obtenerprovincias = () => {
    fetch(UrlApi + "/provincias")
      .then((data) => data.json())
      .then((data) => setProvincias(data));
  };

  const almacenar = (e) => {
    props.setDataCliente({
      ...props.dataCliente,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  useEffect(() => {
    obtenersegmentos();
    obtenerprovincias();
    obtenerSit();
    obtenerEmpresas();
    getData("/condicion_pago", setCondicionesPago);
    getData("/desplegables/unidadFact", setUnidadesFact);
  }, []);

  useEffect(() => {
    !!props.dataCliente.id_provincia && obtenerlocalidades();
  }, [props.dataCliente.id_provincia]);

  useEffect(() => {
    !!props.dataCliente.id_segmento && obtenersubsegmentos();
  }, [props.dataCliente.id_segmento]);

  const [cambieProv, setCambieProv] = useState(false);

  useEffect(() => {
    if (cambieProv) {
      props.dataCliente.id_localidad = "";
    }
    obtenerlocalidades();
  }, [props.dataCliente.id_provincia, cambieProv]);

  const [cambieSeg, setCambieSeg] = useState(false);

  useEffect(() => {
    if (cambieSeg) {
      props.dataCliente.id_subsegmento = "";
    }
    props.dataCliente.id_segmento && obtenersubsegmentos();
  }, [props.dataCliente.id_segmento, cambieSeg]);

  const style = {
    backgroundColor: "#fff",
    width: "100%",
  };

  const variant = "standard";
  const readOnly = props.readOnly;
  const txtProps = {};

  // const setDomEntrega = (e) => {
  //   props.setEsDomEntrega(e.target.checked);
  //   // props.setDataDir({...props.dataDir, id_provincia:props.dataCliente.id_provincia, id_localidad:props.dataCliente.id_localidad, direccion_entrega:props.dataCliente.direccion_fiscal})
  // };

  useEffect(() => {
    if (!props.edicion) {
      if (props.esDomEntrega === true) {
        props.setDataDir((prevDataDir) => ({
          ...prevDataDir,
          id_provincia: props.dataCliente.id_provincia,
          id_localidad: props.dataCliente.id_localidad,
          telefono1: props.dataCliente.telefono1,
          telefono2: props.dataCliente.telefono2,
          telefono3: props.dataCliente.telefono3,
          mails: props.dataCliente.mails,
        }));
      } else {
        props.setDataDir({
          ...props.dataDir,
          id_provincia: props.dataDir.id_provincia || "",
          id_localidad: props.dataDir.id_localidad || "",
          // direccion_entrega: "",
          telefono1: props.dataDir.telefono1 || "",
          telefono2: props.dataDir.telefono2 || "",
          telefono3: props.dataDir.telefono3 || "",
          mails: props.dataDir.mails || "",
        });
      }
    }
  }, [
    props.esDomEntrega,
    props.dataCliente.id_provincia,
    props.dataCliente.id_localidad,
    props.dataCliente.telefono1,
    props.dataCliente.telefono2,
    props.dataCliente.telefono3,
    props.dataCliente.mails,
  ]);

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
        p={1}
        maxWidth="xl"
      >
        <Grid item xs={6}>
          <TextField
            autoFocus
            focused={txtProps.focused}
            required
            autoComplete="off"
            color="primary"
            variant={variant}
            style={style}
            type="text"
            name="razon_social"
            label="Razon Social"
            size="small"
            onChange={almacenar}
            value={
              props.dataCliente.razon_social &&
              props.dataCliente.razon_social.replace(/\b\w/g, (l) =>
                l.toUpperCase()
              )
            }
            inputProps={{ maxLength: 200, readOnly: readOnly }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
            focused={txtProps.focused}
          >
            <InputLabel>Cuit/Dni</InputLabel>
            <Select
              name="tipo_doc_afip"
              // value={props.tipo}
              value={props.dataCliente.tipo_doc_afip}
              label="Cuit/Dni"
              onChange={almacenar}
              // onChange={(event) => {
              //   props.setTipo(event.target.value);
              // }}
            >
              <MenuItem key={1} value={80}>
                Cuit
              </MenuItem>
              <MenuItem key={2} value={96}>
                Dni
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {props.dataCliente.tipo_doc_afip === 80 ? (
          // <Grid item xs={4}>
          <Grid item xs={3}>
            <InputMask
              mask="99-99999999-9"
              value={props.dataCliente.cuit}
              maskChar=" "
              onChange={(e) =>
                props.setDataCliente({
                  ...props.dataCliente,
                  cuit: e.target.value,
                })
              }
              onBlur={(e) =>
                validarCuit(e, props.setDataCliente, props.dataCliente)
              }
              disabled={readOnly}
            >
              {() => (
                <TextField
                  variant={variant}
                  size="small"
                  style={style}
                  type="text"
                  name="cuit"
                  label="Numero Cuit"
                  focused={txtProps.focused}
                />
              )}
            </InputMask>
          </Grid>
        ) : (
          // <Grid item xs={4}>
          <Grid item xs={3}>
            <InputMask
              mask="99.999.999"
              value={props.dataCliente.cuit}
              disabled={readOnly}
              maskChar=" "
              onChange={almacenar}
            >
              {() => (
                <TextField
                  variant={variant}
                  size="small"
                  style={style}
                  type="text"
                  name="cuit"
                  label="Numero Dni"
                  focused={txtProps.focused}
                />
              )}
            </InputMask>
          </Grid>
        )}
        <Grid
          item
          xs={
            parseInt(props.dataCliente.situacion_iva) === 4 ||
            parseInt(props.dataCliente.situacion_iva) === 1
              ? 4.5
              : 6
          }
        >
          <FormControl required style={style} size="small">
            <InputLabel>Condición de pago</InputLabel>
            <Select
              name="id_condicionpago"
              value={props.dataCliente.id_condicionpago}
              label="Condición de pago"
              onChange={almacenar}
              variant="standard"
              disabled={readOnly}
            >
              {condicionesPago
                ? condicionesPago.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.condicion}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={
            parseInt(props.dataCliente.situacion_iva) === 4 ||
            parseInt(props.dataCliente.situacion_iva) === 1
              ? 4.5
              : 6
          }
        >
          <FormControl
            style={style}
            size="small"
            variant={variant}
            disabled={readOnly}
            focused={txtProps.focused}
            required
          >
            <InputLabel> Situacion Iva</InputLabel>
            <Select
              required
              label="Situacion Iva"
              name="situacion_iva"
              value={props.dataCliente.situacion_iva}
              onChange={almacenar}
            >
              {sitIva
                ? sitIva.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.descripcion}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        {parseInt(props.dataCliente.situacion_iva) === 4 && (
          <Grid item xs={3}>
            <FormControlLabel
              labelPlacement="right"
              control={
                <Checkbox
                  disabled={readOnly}
                  checked={props.dataCliente.factura_consfinal === 1}
                  onChange={(e) =>
                    props.setDataCliente({
                      ...props.dataCliente,
                      factura_consfinal: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label="Factura A Cons Final"
            />
          </Grid>
        )}
        {parseInt(props.dataCliente.situacion_iva) === 1 && (
          <Grid item xs={3}>
            <FormControlLabel
              labelPlacement="right"
              control={
                <Checkbox
                  disabled={readOnly}
                  checked={props.dataCliente.facturacion_especial === 1}
                  onChange={(e) =>
                    props.setDataCliente({
                      ...props.dataCliente,
                      facturacion_especial: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label="Facturacion especial"
            />
          </Grid>
        )}
        <Grid item xs={9}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            variant={variant}
            size="small"
            required
            style={style}
            placeholder="Direccion Fiscal"
            id="direccion_fiscal"
            name="direccion_fiscal"
            label="Direccion Fiscal"
            color="primary"
            value={
              props.dataCliente.direccion_fiscal &&
              props.dataCliente.direccion_fiscal.replace(/\b\w/g, (l) =>
                l.toUpperCase()
              )
            }
            onChange={almacenar}
            inputProps={{ maxLength: 120, readOnly: readOnly }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel> Empresa </InputLabel>
            <Select
              label="Empresa"
              color="primary"
              name="id_empresa"
              value={props.dataCliente.id_empresa}
              onChange={(e) => {
                almacenar(e);
              }}
            >
              {empresas
                ? empresas.map((elemento) => (
                    <MenuItem
                      key={elemento.idempresa}
                      value={elemento.idempresa}
                    >
                      {elemento.empresa}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={!props.edicion ? 4.5 : 6}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel> Provincia </InputLabel>
            <Select
              label="Provincia"
              color="primary"
              name="id_provincia"
              value={props.dataCliente.id_provincia}
              onChange={(e) => {
                almacenar(e);
                setCambieProv(true);
              }}
              // onChange={(e) => {
              //   almacenar(e);
              //   setCambieProv(true);
              //   if (props.esDomEntrega) {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_provincia: e.target.value
              //     }));
              //   } else {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_provincia: ""
              //     }));
              //   }
              // }}
            >
              {provincias
                ? provincias.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.provincia}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={!props.edicion ? 4.5 : 6}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel>Localidad</InputLabel>
            <Select
              label="Localidad"
              name="id_localidad"
              value={props.dataCliente.id_localidad}
              onChange={almacenar}
              // onChange={(e)=>{
              //   almacenar(e)
              //   if (props.esDomEntrega) {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_localidad: e.target.value
              //     }));
              //   } else {
              //     props.setDataDir((prevDataDir) => ({
              //       ...prevDataDir,
              //       id_localidad: ""
              //     }));
              //   }
              // }}
            >
              {localidades
                ? localidades.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.localidad}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        {!props.edicion && (
          <Grid item xs={3}>
            <FormControlLabel
              labelPlacement="right"
              control={
                <Checkbox
                  checked={props.esDomEntrega}
                  onChange={(e) => props.setEsDomEntrega(e.target.checked)}
                />
              }
              label="Es Domicilio De Entrega"
            />
          </Grid>
        )}

        <Grid item xs={5}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel>Segmento</InputLabel>
            <Select
              label="Segmento"
              name="id_segmento"
              value={props.dataCliente.id_segmento}
              onChange={(e) => {
                almacenar(e);
                setCambieSeg(true);
              }}
            >
              {segmentos
                ? segmentos.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.segmento} - {elemento.codigo}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl
            focused={txtProps.focused}
            required
            style={style}
            variant={variant}
            size="small"
            disabled={readOnly}
          >
            <InputLabel>Subsegmento</InputLabel>
            <Select
              label="Subsegmento"
              name="id_subsegmento"
              value={props.dataCliente.id_subsegmento}
              onChange={almacenar}
            >
              {subsegmentos
                ? subsegmentos.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.subsegmento} - {elemento.codigo_subsegmento}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl style={style} size="small">
            <InputLabel> Unidad Fact: </InputLabel>
            <Select
              label="Facturacion En: "
              name="unidad_fact"
              variant={variant}
              value={props.dataCliente.unidad_fact}
              onChange={almacenar}
            >
              {unidadesFact
                ? unidadesFact.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.valor}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>

        {/* aca pongo la unidad_fact */}

        <Grid item xs={12}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            style={style}
            placeholder="Mails"
            name="mails"
            label="Mails"
            color="primary"
            value={props.dataCliente.mails}
            onChange={almacenar}
            inputProps={{ maxLength: 500, readOnly: readOnly }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            fullWidth
            type="text"
            color="primary"
            placeholder="Telefono 1"
            name="telefono1"
            label="Telefono 1"
            onChange={almacenar}
            value={props.dataCliente.telefono1}
            inputProps={{
              maxLength: 10,
              readOnly: readOnly,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{props.codArg}</InputAdornment>
              ),
            }}
            helperText="Complete código de área (sin 0) + el número sin el 15. Ej:341xxxxxx "
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            fullWidth
            type="text"
            color="primary"
            placeholder="Telefono 2"
            name="telefono2"
            label="Telefono 2"
            onChange={almacenar}
            value={props.dataCliente.telefono2}
            inputProps={{
              maxLength: 10,
              readOnly: readOnly,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{props.codArg}</InputAdornment>
              ),
            }}
            helperText="Complete código de área (sin 0) + el número sin el 15. Ej:341xxxxxx "
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                props.setActiveTab("domicilio");
              }
            }}
            focused={txtProps.focused}
            autoComplete="off"
            size="small"
            variant={variant}
            fullWidth
            type="text"
            color="primary"
            placeholder="Telefono 3"
            name="telefono3"
            label="Telefono 3"
            onChange={almacenar}
            value={props.dataCliente.telefono3}
            inputProps={{
              maxLength: 10,
              readOnly: readOnly,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{props.codArg}</InputAdornment>
              ),
            }}
            helperText="Complete código de área (sin 0) + el número sin el 15. Ej:341xxxxxx "
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default FormCliente;
