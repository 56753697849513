import { UrlFacturacion } from "../../../../../services/apirest";

export async function enviarNC(datos, setNotify, setConfirmDialog) {
  console.log("la concha de tu madre all bouys");
  try {
    const response = await fetch(UrlFacturacion + "/facturar/nota_credito", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(datos),
    });

    if (!response.ok) {
      const errorData = await response.json();

      // Manejar el error recibido del backend
      throw new Error(errorData.error || "Error desconocido");
    }
    const result = await response.text();
    setNotify({
      open: true,
      severity: "success",
      color: "#D4EFDF",
      mensaje: "Guardado",
    });

    // console.log("Resultado:", result);
  } catch (error) {
    // console.error("Error en la solicitud:", error.message);
    // Mostrar el mensaje de error al usuario
    // alert(`ATENCIÓN: LA NOTA DE CRÉDITO SE APROBÓ POR AFIP, PERO HUBO UN ERROR GUARDANDO LOS DATOS DEL COMPROBANTE EN LA BASE DE DATOS.`);
    setConfirmDialog({ open: false });
    alert(`Error: ${error.message}`);
  }

  // const response = await fetch(UrlFacturacion + "/facturar/nota_credito", {
  //   method: "PUT",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify(datos),
  // });
  // // console.log(response)
  // if (response.ok === false) {
  //     console.log("entro aca")
  //     setNotify({
  //       open: true,
  //       severity: "error",
  //       color: "#FADBD8",
  //       mensaje: "Error al guardar",
  //     });
  //   } else {
  //     try {
  //       const json = await response.json();
  //       if (json.message) {
  //         setNotify({
  //           open: true,
  //           severity: "success",
  //           color: "#D4EFDF",
  //           mensaje: "Guardado",
  //         });
  //       } else if (json.sqlMessage) {
  //         console.log("entro acaaa")
  //         setNotify({
  //           open: true,
  //           severity: "error",
  //           color: "#FADBD8",
  //           mensaje: "Error al guardar",
  //         });
  //       } else {
  //         // console.log("entro en else")
  //         setNotify({
  //           open: true,
  //           severity: "error",
  //           color: "#FADBD8",
  //           mensaje: "Error al guardar",
  //         });
  //       }
  //     } catch (error) {
  //       if (error.toString().includes("is not valid JSON")) {
  //         setNotify({
  //           open: true,
  //           severity: "success",
  //           color: "#D4EFDF",
  //           mensaje: "Guardado",
  //         });
  //       } else {
  //         setNotify({
  //           open: true,
  //           severity: "error",
  //           color: "#FADBD8",
  //           mensaje: "Error al guardar",
  //         });
  //       }
  //     }
  //   }
}
