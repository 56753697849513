import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, TextField, DialogContentText, Grid, Box, Typography, Container, Button, Alert, AlertTitle } from '@mui/material';
import Notify from "../../../../Notify";
import { postData } from "../../../../../services/library";
import { logEventos } from "../../../../../services/services";
import moment from "moment";
import { PropaneSharp } from "@mui/icons-material";

export default function ModalAddlistaprecio(props) {
    const [datoslista, setDatoslista] = useState({
        id_empresa:null,
        id_sucursal: null,
        id_segmento: null,
        precio_litro_con_iva: "",
        precio_litro_sin_iva: "",
        precio_kilo_con_iva: "",
        precio_kilo_sin_iva: "",
        vigencia: "",
        id_producto: "",
        usuario: localStorage.getItem('user'),
        fin_vigencia: ""
    });
    const [disabledGuardar, setDisabledGuardar] = useState(false);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    const today = new Date().toISOString().split("T")[0]; // Formato YYYY-MM-DD

    // console.log("direccion", props.direccion)

    const almacenar = (event) => {
        const { name, value } = event.target;
        let newValue = { ...datoslista, [name]: value };

        const densidad = props.datos.id_empresa === 1 
        ? props.direccion.densidad_hipergas 
        : props.direccion.densidad_autogas;

        if (name === "precio_litro_sin_iva") {
            const precioConIva = parseFloat(value) * props.direccion.valor;
            newValue.precio_litro_con_iva = precioConIva.toFixed(4);
            newValue.precio_kilo_con_iva = (precioConIva / densidad).toFixed(4);
            newValue.precio_kilo_sin_iva = (newValue.precio_kilo_con_iva / props.direccion.valor).toFixed(4);
        }

        if (name === "precio_kilo_sin_iva") {
            const precioConIva = parseFloat(value) * props.direccion.valor;
            newValue.precio_kilo_con_iva = precioConIva.toFixed(4);
            newValue.precio_litro_con_iva = (precioConIva * densidad).toFixed(4);
            newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / props.direccion.valor).toFixed(4);
        }

        if (name === "precio_litro_con_iva") {
            const precioConIva = parseFloat(value) / props.direccion.valor;
            newValue.precio_litro_sin_iva = precioConIva.toFixed(4);
            newValue.precio_kilo_sin_iva = (precioConIva / densidad).toFixed(4);
            newValue.precio_kilo_con_iva = (newValue.precio_kilo_sin_iva * props.direccion.valor).toFixed(4);
        }

        if (name === "precio_kilo_con_iva") {
            const precioConIva = parseFloat(value) / props.direccion.valor;
            newValue.precio_kilo_sin_iva = precioConIva.toFixed(4);
            newValue.precio_litro_con_iva = (precioConIva * densidad).toFixed(4);
            newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / props.direccion.valor).toFixed(4);
        }

        setDatoslista(newValue);

    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const size = "small";

    const guardarPrecio = async (e) => {
        logEventos(
            "Guardar nuevo precio",
            "Lista de Precios",
            "Se guardo el nuevo precio",
            localStorage.getItem("user")
        );
        setDisabledGuardar(true)

        postData(e,
            '/lista_precios',
            {
                id_sucursal: props.direccion.id_sucursal,
                id_segmento: props.datos.id_segmento,
                id_producto: props.direccion.id_producto,
                id_empresa :props.datos.id_empresa,
                precio_litro_con_iva: datoslista.precio_litro_con_iva,
                precio_litro_sin_iva: datoslista.precio_litro_sin_iva,
                precio_kilo_con_iva: datoslista.precio_kilo_con_iva,
                precio_kilo_sin_iva: datoslista.precio_kilo_sin_iva,
                vigencia: datoslista.vigencia,
                usuario: localStorage.getItem("user"),
            },
            setNotificacion,
            props.actualizar()
        );
    };

    useEffect(() => {
        if (datoslista.precio_litro_con_iva === "" || datoslista.precio_litro_sin_iva === "" || datoslista.precio_kilo_con_iva === "" || datoslista.precio_kilo_sin_iva === "" || datoslista.vigencia === "") {
            setDisabledGuardar(true);
        }

        else {
            setDisabledGuardar(false);
        }
    }, [datoslista.precio_litro_con_iva, datoslista.precio_litro_sin_iva, datoslista.precio_kilo_con_iva, datoslista.precio_kilo_sin_iva, datoslista.vigencia]);


    return (
        <Dialog maxWidth="xl" open={props.open}>
            <DialogContent>
                <Alert severity="warning">
                    <AlertTitle>Atención</AlertTitle>
                        No hay una lista de precios registrada para empresa: {props.datos.empresa} Sucursal: {props.direccion.sucursal} Segmento: {props.datos.segmento} Producto: {props.direccion.nombre_producto}
                </Alert>
                <DialogContentText>
                    <Box p={2}>
                        <Typography variant="caption" display="block" gutterBottom>
                            A continuación, puede registrar una nueva
                        </Typography>
                    </Box>
                </DialogContentText>
               
                <form onSubmit={guardarPrecio} autoComplete="off">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_litro_sin_iva"
                                label="Precio litro sin IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_litro_sin_iva) ? 0 : datoslista.precio_litro_sin_iva}
                                onChange={almacenar}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_litro_con_iva"
                                label="Precio litro con IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_litro_con_iva) ? 0 : datoslista.precio_litro_con_iva}
                                onChange={almacenar}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_kilo_sin_iva"
                                label="Precio kilo sin IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_kilo_sin_iva) ? 0 : datoslista.precio_kilo_sin_iva}
                                onChange={almacenar}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_kilo_con_iva"
                                label="Precio kilo con IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_kilo_con_iva) ? 0 : datoslista.precio_kilo_con_iva}
                                onChange={almacenar}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                type="date"
                                name="vigencia"
                                label="Vigencia"
                                variant="outlined"
                                value={datoslista.vigencia}
                                onChange={almacenar}
                                InputProps={{
                                    inputProps: {
                                        min: today
                                    }
                                }}
                            />
                        </Grid>


                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        mt={2}
                    >
                        <Button variant="contained" onClick={props.onClose} color="error">
                            Volver
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            color={"success"}
                            onClick={guardarPrecio}
                            disabled={disabledGuardar}
                        >
                            Guardar Precio
                        </Button>
                    </Grid>
                </form>
                {notificacion && notificacion.open ? (
                    <Notify
                        mensaje={notificacion.mensaje}
                        open={notificacion.open}
                        color={notificacion.color}
                        handleClose={props.onClose}
                        severity={notificacion.severity}
                    />
                ) : null}

            </DialogContent>
        </Dialog>
    )
}
