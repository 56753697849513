import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputMask from "react-input-mask";
import { UrlApi } from "../../../services/apirest";
import Notify from "../../../components/Notify";
import Alerta from "../../../components/Alerta";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function DialogEntregado(props) {
  const [datosPedidoEntregado, setDatosPedidoEntregado] = useState({
    nro_remito: "",
    kgentregados: "",
    fecha_real_entrega: "",
    //Datos de pago
    id_formapago: 153,
    usuario_choferentrega: "",
    ltsentregados: ""
  });

  const [showDatosPago, setShowDatosPago] = useState(false);
  const [bancos, setBancos] = useState([{}]);
  const [choferes, setChoferes] = useState([{}]);

  const [formasPago, setFormasPago] = useState([{}]);

  //Buscar FORMAS DE PAGO
  const getFormasPago = async () => {
    fetch(UrlApi + "/desplegables/formasPagoDelivery")
      .then((data) => data.json())
      .then((data) => setFormasPago(data));
  };

  const getBancos = async () => {
    fetch(UrlApi + "/bancos")
      .then((data) => data.json())
      .then((data) => setBancos(data));
  };

  //Buscar FORMAS DE PAGO
  const getChoferes = async () => {
    fetch(UrlApi + "/choferes/granel")
      .then((data) => data.json())
      // .then((data) => setChoferes(data.filter((chofer)=>chofer.username)));
      .then((data) => setChoferes(data.filter((chofer) => chofer.username)))
    // .then((data)=>console.log(data.filter((chofer)=>chofer.username)))

  };

  useEffect(() => {
    getFormasPago();
    getBancos();
    getChoferes();
  }, []);

  const almacenar = (e) => {
    setDatosPedidoEntregado({
      ...datosPedidoEntregado,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  //estados para manejo de errores
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [colormensaje, setColorMensaje] = useState(""); //define el color que tendrá la notificación
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [disBtn, setDisBtn] = useState(false);
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [dataNotDelivered, setDataNotDelivered] = useState({ id_estado: 41, obs_chofer: "", usuario_choferentrega: "" })

  async function guardarPedidoEntregado(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/pedidos/" + props.pedido.id_pedido,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          nro_remito: datosPedidoEntregado.nro_remito,
          kgentregados: datosPedidoEntregado.kgentregados || null,
          ltsentregados: datosPedidoEntregado.ltsentregados || null,
          fecha_real_entrega: datosPedidoEntregado.fecha_real_entrega,
          id_estado: 40,
          importe_efectivo:
            datosPedidoEntregado.importe_efectivo === ""
              ? null
              : datosPedidoEntregado.importe_efectivo,
          importe_cheque:
            datosPedidoEntregado.importe_cheque === ""
              ? null
              : datosPedidoEntregado.importe_cheque,
          nro_cheque: datosPedidoEntregado.nro_cheque,
          id_banco:
            datosPedidoEntregado.id_banco === ""
              ? null
              : datosPedidoEntregado.id_banco,
          nro_recibo: datosPedidoEntregado.nro_recibo,
          nro_factura: datosPedidoEntregado.nro_factura,
          usuario_choferentrega: datosPedidoEntregado.usuario_choferentrega,
          importe_transferencia: datosPedidoEntregado.importe_transferencia,
          cuit_abona: datosPedidoEntregado.cuit_abona
        }),
      }
    );
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            props.actualizar();
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            props.actualizar();
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          props.actualizar();
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  async function guardarObs(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/pedidos/" + props.pedido.id_pedido,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataNotDelivered),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        const json = await response.json();
        if (json.message) {
          if (json.message === "OK") {
            props.actualizar();
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          props.actualizar();
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  const guardar = (e) => {
    delivered ? guardarPedidoEntregado(e) : guardarObs(e)
  }

  // useEffect(() => {
  //   if (datosPedidoEntregado.ltsentregados) {
  //     setDatosPedidoEntregado({
  //       ...datosPedidoEntregado,
  //       kgentregados: (datosPedidoEntregado.ltsentregados * 0.52).toFixed(0),
  //     });
  //   } else {
  //     setDatosPedidoEntregado({ ...datosPedidoEntregado, kgentregados: "" });
  //   }
  // }, [datosPedidoEntregado.ltsentregados]);

  useEffect(() => {
    if (finalizar === 1) props.setOpen(false);
  }, [finalizar]);


  const [delivered, setDelivered] = useState(true)
  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <Grid container direcion="row" backgroundColor="#1976d2" color="white">
          <Grid item>
            <Typography variant="h6" component="div">
              <DialogTitle> Pedido Nro: {props.pedido.id_pedido} </DialogTitle>
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={guardar}>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              mt={-1}
              mb={2}
            >
              <FormControl component="fieldset" variant="standard">
                <FormControlLabel
                  control={
                    <Switch
                      checked={delivered}
                      onChange={(event) => setDelivered(event.target.checked)}
                    />
                  }
                  label="Entregado"
                />
              </FormControl>
            </Grid>

            {delivered ? (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  spacing={2}
                >
                  <Grid item xs={2}>
                    <TextField
                      required
                      name="fecha_real_entrega"
                      focused
                      type="date"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      margin="none"
                      size="small"
                      label="Fecha Real Entrega"
                      value={moment(
                        datosPedidoEntregado.fecha_real_entrega
                      ).format("YYYY-MM-DD")}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputMask
                      mask="99999-99999999"
                      value={datosPedidoEntregado.nro_remito}
                      disabled={false}
                      maskChar=" "
                      onChange={almacenar}
                    >
                      {() => (
                        <TextField
                          required
                          size="small"
                          fullWidth
                          defaultValue=""
                          type="text"
                          focused
                          name="nro_remito"
                          label="Numero Remito"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={datosPedidoEntregado.ltsentregados}
                      required
                      name="kgentregados"
                      focused
                      fullWidth
                      variant="outlined"
                      color="primary"
                      margin="none"
                      size="small"
                      label="KG Entregados"
                      value={datosPedidoEntregado.kgentregados}
                      onChange={almacenar}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={
                        datosPedidoEntregado.kgentregados &&
                        !datosPedidoEntregado.ltsentregados
                      }
                      // required
                      name="ltsentregados"
                      focused
                      fullWidth
                      variant="outlined"
                      color="primary"
                      margin="none"
                      size="small"
                      label="LTS Entregados"
                      value={datosPedidoEntregado.ltsentregados}
                      onChange={almacenar}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      style={{ backgroundColor: "#ffffff", width: "100%" }}
                      required
                      focused
                      size="small"
                    >
                      <InputLabel>Visitado Por: </InputLabel>
                      <Select
                        label="Visitado Por:"
                        name="usuario_choferentrega"
                        value={datosPedidoEntregado.usuario_choferentrega}
                        onChange={(e) => setDatosPedidoEntregado({ ...datosPedidoEntregado, "usuario_choferentrega": e.target.value })}
                      >
                        {choferes
                          ? choferes.map((elemento) => (
                            <MenuItem value={elemento.username} >
                              {elemento.nombre_apellido}
                            </MenuItem>
                          ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      onClick={() => {
                        setShowDatosPago(!showDatosPago);
                        setDatosPedidoEntregado({
                          ...datosPedidoEntregado,
                          id_formapago: 153,
                          importe_efectivo: null,
                          importe_cheque: null,
                          nro_cheque: null,
                          id_banco: null,
                          nro_recibo: null,
                          nro_factura: null,
                        });
                      }}
                    >
                      {showDatosPago ? "Cancelar" : "Añadir Datos Pago"}
                    </Button>
                  </Grid>
                </Grid>

                {showDatosPago ? (
                  <>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={3}>
                        <FormControl fullWidth size="small" focused>
                          <InputLabel>Forma Pago</InputLabel>
                          <Select
                            name="id_formapago"
                            value={datosPedidoEntregado.id_formapago}
                            label="Forma Pago"
                            onChange={(e) => {
                              setDatosPedidoEntregado({
                                ...datosPedidoEntregado,
                                id_formapago: e.target.value,
                                importe_cheque: null,
                                importe_efectivo: null,
                                nro_cheque: null,
                                id_banco: null,
                              });
                            }}
                          >
                            {formasPago
                              ? formasPago.map((elemento) => (
                                <MenuItem
                                  key={elemento.id}
                                  value={elemento.id}
                                >
                                  {elemento.valor}
                                </MenuItem>
                              ))
                              : null}
                          </Select>
                        </FormControl>
                      </Grid>
                      {datosPedidoEntregado.id_formapago === 153 ? (
                        <Grid item xs={9}>
                          <TextField
                            // style={{width:"33.33%"}}
                            name="importe_efectivo"
                            focused
                            fullWidth
                            required={datosPedidoEntregado.id_formapago === 153}
                            variant="outlined"
                            color="primary"
                            margin="none"
                            size="small"
                            label="Importe Efectivo"
                            value={datosPedidoEntregado.importe_efectivo}
                            onChange={almacenar}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{
                              maxLength: 7,
                            }}
                          />
                        </Grid>
                      ) : null}
                      {datosPedidoEntregado.id_formapago === 154 ? (
                        <>
                          <Grid item xs={3}>
                            <TextField
                              name="importe_cheque"
                              focused
                              fullWidth
                              required
                              variant="outlined"
                              color="primary"
                              margin="none"
                              size="small"
                              label="Importe Cheque"
                              value={datosPedidoEntregado.importe_cheque}
                              onChange={almacenar}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              inputProps={{
                                maxLength: 7,
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              name="nro_cheque"
                              focused
                              required
                              fullWidth
                              variant="outlined"
                              color="primary"
                              margin="none"
                              size="small"
                              label="Nro Cheque"
                              value={datosPedidoEntregado.nro_cheque}
                              onChange={almacenar}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              inputProps={{
                                maxLength: 7,
                              }}
                            />
                          </Grid>

                          {/* ACA TENGO QUE MODIFICAR LAS COSAS */}
                          <Grid item xs={3}>
                            <FormControl fullWidth size="small" focused required>
                              <InputLabel>Banco</InputLabel>
                              <Select
                                name="id_banco"
                                value={datosPedidoEntregado.id_banco}
                                label="Banco"
                                onChange={almacenar}
                              >
                                {bancos
                                  ? bancos.map((elemento) => (
                                    <MenuItem
                                      key={elemento.id}
                                      value={elemento.id}
                                    >
                                      {elemento.nombre}
                                    </MenuItem>
                                  ))
                                  : null}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : null}

                      {datosPedidoEntregado.id_formapago === 262 ? ( 
                        <>
                         <Grid item xs={4.5}>
                          <TextField
                            // style={{ width: "33.33%" }}
                            name="importe_transferencia"
                            focused
                            required
                            fullWidth
                            variant="outlined"
                            color="primary"
                            margin="none"
                            size="small"
                            label="Importe"
                            value={datosPedidoEntregado.importe_transferencia}
                            onChange={almacenar}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{
                              maxLength: 7,
                            }}
                          />
                          </Grid>
                          <Grid item xs={4.5}>
                          <TextField
                            // style={{width:"33.33%"}}
                            name="cuit_abona"
                            focused
                            fullWidth
                            variant="outlined"
                            required
                            color="primary"
                            margin="none"
                            size="small"
                            label="DNI/CUIT"
                            value={datosPedidoEntregado.cuit_abona}
                            onChange={almacenar}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{
                              maxLength: 7,
                            }}
                          />
                          </Grid>
                        </>
                      ) :null }
                      <Grid item xs={3}>
                        <InputLabel focused fullWidth>
                          Comprobante Entregado:{" "}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={4.5}>
                        <InputMask
                          mask="99999-99999999"
                          value={datosPedidoEntregado.nro_recibo}
                          maskChar=" "
                          onChange={almacenar}
                          disabled={datosPedidoEntregado.nro_factura}
                        >
                          {() => (
                            <TextField
                              size="small"
                              fullWidth
                              defaultValue=""
                              type="text"
                              focused
                              name="nro_recibo"
                              label="Numero Recibo"
                            />
                          )}
                        </InputMask>
                      </Grid>

                      {/* <Grid item xs={4.5}>
                        <InputMask
                          mask="99999-99999999"
                          value={datosPedidoEntregado.nro_factura}
                          // disabled={false}
                          maskChar=" "
                          onChange={almacenar}
                          disabled={datosPedidoEntregado.nro_recibo}
                        >
                          {() => (
                            <TextField
                              // required
                              size="small"
                              fullWidth
                              defaultValue=""
                              type="text"
                              focused
                              name="nro_factura"
                              label="Numero Factura"
                            />
                          )}
                        </InputMask>
                      </Grid> */}
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              // el pedido no se entregó:
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={1.7}>
                  <InputLabel focused>Observación/Motivo:</InputLabel>
                </Grid>
                <Grid item xs={6.3}>
                  <TextField
                    size="small"
                    required={!delivered}
                    multiline
                    fullWidth
                    defaultValue=""
                    type="text"
                    focused
                    name="obs_chofer"
                    onChange={(e) =>
                      setDataNotDelivered({
                        ...dataNotDelivered,
                        obs_chofer: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    style={{ backgroundColor: "#ffffff", width: "100%" }}
                    required
                    focused
                    size="small"
                  >
                    <InputLabel>Visitado Por: </InputLabel>
                    <Select
                      label="Visitado Por:"
                      name="usuario_choferentrega"
                      value={dataNotDelivered.usuario_choferentrega}
                      onChange={(e) => setDataNotDelivered({ ...dataNotDelivered, "usuario_choferentrega": e.target.value })}
                    >
                      {choferes
                        ? choferes.map((elemento) => (
                          <MenuItem value={elemento.username} >
                            {elemento.nombre_apellido}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            )}
          </DialogContent>
          <Grid
            p={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => props.setOpen(false)}
            >
              cancelar
            </Button>
            <Button
              disabled={disBtn}
              type="submit"
              variant="contained"
              color="success"
            >
              Guardar
            </Button>
          </Grid>
        </form>
      </Dialog>
    </div>
  );
}

export default DialogEntregado;
