export function validar(clienteIIBB,excencionesDePadron,excencionesFueraPadron){

    const excenciones=[...excencionesDePadron, ...excencionesFueraPadron]
    const errores= []

    const provFaltantes=clienteIIBB.filter((reg)=> (!!reg.percepcion || !!reg.porcentaje_excencion || !!reg.vto_excencion) && (!reg.id_provincia || !reg.id_provincia==="") )
    if(provFaltantes.length>0){
        errores.push("Completar Provincia")
    }

    const percepcionesFaltantes=clienteIIBB.filter((reg)=> (!!reg.id_provincia || !!reg.porcentaje_excencion || !!reg.vto_excencion)&& !reg.percepcion || reg.percepcion <= 0)
    if(percepcionesFaltantes.length>0){
        errores.push("Completar Alícuota Percepción")
    }

    const erroresPorcentajesExcencion=excenciones.filter((excencion)=>excencion.porcentaje_excencion > 100 || excencion.porcentaje_excencion==="0" || excencion.porcentaje_excencion==="00" || excencion.porcentaje_excencion==="000" )
    if(erroresPorcentajesExcencion.length>0){
        errores.push("Ningun porcentaje debe ser 0 o mayor a 100")
    }

    const faltaVtoExcencion=excenciones.filter((excencion)=>!!excencion.porcentaje_excencion && !excencion.vto_excencion)
    if(faltaVtoExcencion.length>0){
        errores.push("Completar Fecha Vencimiento Excención")
    }
    
    const faltaPorcExcencion=excenciones.filter((excencion)=>!!excencion.vto_excencion && !excencion.porcentaje_excencion)
    if(faltaPorcExcencion.length>0){
        errores.push("Completar Porcentaje Excención")
    }
    return errores;
}
