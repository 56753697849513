import React, { useState, useEffect } from "react";
import { getDataIx0, getData } from "../../../services/library";
import { Container, Grid, Typography, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import TabPanel from "../components/TabPanel";
import { getCodeArg } from "../../../services/library";
import ErrorModal from "../../ErrorModal";
import Notify from "../../Notify";
import { UrlApi } from "../../../services/apirest";
import CircularProgress from "@mui/material/CircularProgress";
import FormCliente from "../formularios/FormCliente";
import FormCostos from "../formularios/FormCostos";
import FormDir from "../formularios/FormDir";
import FormArtefactos from "../formularios/FormArtefactos";
import FormTanques from "../formularios/FormTanques";
import PanelAnalisis from "../components/PanelAnalisis";
import * as Validaciones from "../validaciones";
import {
  putFactibilidades,
  putFactibilidadesClienteExistente,
} from "../savefunctions";
import TitleUploaded from "../../../components/TitleUploaded";

function ModFact(props) {
  
  //Datos
  const [reqBody, setReqBody] = useState();
  const [factConfig, setFactConfig] = useState();
  const [dataFact, setDataFact] = useState({
    ...props.fact,
    precioporkg_siniva: parseFloat((props.fact.precio_kg / 1.105).toFixed(4)),
  });
  const [calculos, setCalculos] = useState({
    preciokg_siniva: parseFloat((props.fact.precio_kg / 1.105).toFixed(4)),
    costot1: 0,
    costot2: 0,
    costotranstks: 0,
    costototaltks: 0,
    costosabsxlaempresa: 0,
    inversiontotal: 0,
    margen: 0,
    kganual: 0,
    kganualminimo: 0,
    cierreestimado: 0,
    cierreminimo: 0,
    costos_operativos_xtn_pesos: 0,
  });
  // console.log("PROPS EN MOD FACT: " , props)
  const [dataCliente, setDataCliente] = useState(
    props.fact.id_tipofact !== 235 ? { razon_social: props.fact.razon_social, cuit:props.fact.cuit, sisven_id:props.fact.sisven_id}:null //ACÁ!
      
  );
    // console.log(dataCliente)

  const [tipo, setTipo] = useState();
  const [dataDir, setDataDir] = useState(null);
  const [dataSucursal, setDataSucursal] = useState({});
  const [listaArtefactos, setListaArtefactos] = useState(null);
  const usuario = localStorage.getItem("user");
  const [codArg, setCodArg] = useState("");

  //Validaciones
  const [validacionDiasHs, setValidacionDiasHs] = useState({
    errorHs: [],
    errorDias: [],
  });
  const [cliOK, setCliOK] = useState(props.fact.id_tipofact !== 235);
  const [dirOK, setDirOK] = useState(false);
  const [tksOK, setTksOK] = useState();
  const [artOK, setArtOK] = useState(false);
  const [costosOK, setCostosOK] = useState();

  //Parámetros para otros componentes
  const readOnly = props.readOnly;
  const [mapParams, setMapParams] = useState({
    zoom: 3,
    center: {
      lat: -38.416097,
      lng: -63.616672,
    },
    // mapSearch: "",
  });

  const [latLongParams, setLatLongParams] = useState({
    latOK: true,
    longOK: true,
    readOnly: false,
  });

  //Manejadores de componentes visuales.
  const [activeTab, setActiveTab] = useState(
    props.fact.id_tipofact === 235 ? "cliente" : "domicilio"
  );
  const [attach, setAttach] = useState(false);
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });
  const [showModalError, setShowModalError] = useState({
    open: false,
    title: "",
    description: "",
  });

  //Funciones para obtener de la bd los los datos que necesito:
  useEffect(() => {
    //Cosas que me traigo si o si , independientemente del tipo de fact
    getData("/fact_artefactos/" + props.fact.factuuid, setListaArtefactos);
    getFactConfig();
    getCodeArg(setCodArg);
    //fin

    if (props.fact.id_tipofact !== 236) {
      //Si la factibilidad NO es una ampliacion en un domicilio que ya existia:
      // 1 - obtengo los datos del domicilio de entrega por el uuid d la factibilidad
      getDataIx0("/direccionesentrega/uuid/" + props.fact.factuuid, setDataDir);
      //Si es una factibilidad por nuevo cliente, si o si , obtengo los datos del cliente
      if (props.fact.id_tipofact === 235) {
        // console.log("La factibilidad es un 235")
        getDataIx0("/clientes/uuid/" + props.fact.factuuid, setDataCliente);
      }
    } else {
      // console.log("tambien entro en este if")
      // o sea, si la fact es id_tipofact===236
      // busco la direccion por el id_domicilio_ampliacion
      getDataIx0(
        "/direccionesentrega/factibilidades/id/" +
          props.fact.id_domicilio_ampliacion,
        setDataDir
      );
    }
  }, []);

  console.log(dataCliente)

  const getDataSucursal = () => {
    fetch(UrlApi + "/sucursales/" + dataDir.id_sucursal)
      .then((data) => data.json())
      .then((data) => {
        setDataSucursal(data[0]);
        !dataDir.latitud &&
          !dataDir.longitud &&
          setMapParams({
            ...mapParams,
            center: {
              lat: data[0].latitud * 1,
              lng: data[0].longitud * 1,
            },
          });
      });
  };

  const getFactConfig = () => {
    fetch(UrlApi + "/factconfig")
      .then((data) => data.json())
      .then((data) => {
        setFactConfig(data[0]);
        setCalculos((calculos) => ({
          ...calculos,
          costos_operativos_xtn_pesos:
            props.fact.precio_dolar * data[0].costos_operativos,
        }));
        // setCalculos(props.fact.precio_dolar * data[0].costos_operativos)
      });
  };

  useEffect(() => {
    props.fact.id_tipofact === 235 &&
      dataCliente &&
      Validaciones.validarCliMod(dataCliente, setCliOK);
  }, [dataCliente]);

  useEffect(() => {
    if (!!dataDir) {
      dataDir && Validaciones.validarDirMod(dataDir, setDirOK);
      dataDir.id_sucursal && getDataSucursal();
    }
  }, [dataDir]);

  useEffect(() => {
    if (dataFact) {
      Validaciones.validarCostosMod(dataFact, setCostosOK);
      calcularCostosAbsXEmpresa();
    }
  }, [
    dataFact.motivo1,
    dataFact.cantmotivo1,
    dataFact.motivo2,
    dataFact.cantmotivo2,
    dataFact.motivo3,
    dataFact.cantmotivo3,
    dataFact.motivocli1,
    dataFact.motivocli2,
    dataFact.motivocli3,
    dataFact.cantmotivocli1,
    dataFact.cantmotivocli2,
    dataFact.cantmotivocli3,
  ]);

  const calculart1 = () => {
    setCalculos((calculos) => ({
      ...calculos,
      costot1:
        parseFloat(
          (
            (dataSucursal.distanciabocacarga * 2 * dataFact.costotranspxkm) /
            21000
          ).toFixed(4)
        ) || 0,
    }));
  };

  const calculart2 = () => {
    setCalculos((calculos) => ({
      ...calculos,
      costot2: parseFloat(
        ((dataFact.dist_suc_cli * 2 * dataFact.costotranspxkm) / 5800).toFixed(
          4
        ) || 0
      ),
      costotranstks: dataFact.dist_suc_cli * 2 * dataFact.costotranspxkm,
    }));
  };

  useEffect(() => {
    !!dataSucursal.distanciabocacarga && calculart1();
    calculart2();
  }, [
    dataFact.costotranspxkm,
    dataSucursal.distanciabocacarga,
    dataFact.dist_suc_cli,
  ]);

  const calcularCostoTotalTks = () => {
    const costoTotal =
      dataFact.canttk_05 * factConfig.costo_tk05 * dataFact.precio_dolar +
      dataFact.canttk_1 * factConfig.costo_tk1 * dataFact.precio_dolar +
      dataFact.canttk_2 * factConfig.costo_tk2 * dataFact.precio_dolar +
      dataFact.canttk_4 * factConfig.costo_tk4 * dataFact.precio_dolar +
      dataFact.canttk_7 * factConfig.costo_tk7 * dataFact.precio_dolar +
      dataFact.canttk_otros * dataFact.precio_dolar;
    setCalculos((calculos) => ({ ...calculos, costototaltks: costoTotal }));
  };

  useEffect(() => {
    !!factConfig && !!dataFact.precio_dolar && calcularCostoTotalTks();
    Validaciones.validarTksMod(dataFact, setTksOK);
  }, [
    factConfig,
    dataFact.canttk_05,
    dataFact.canttk_1,
    dataFact.canttk_2,
    dataFact.canttk_4,
    dataFact.canttk_7,
    dataFact.precio_dolar,
  ]);

  const calcularCostosAbsXEmpresa = () => {
    const costosAbsXEmpresa =
      parseInt(dataFact.cantmotivo1 || 0) +
      parseInt(dataFact.cantmotivo2 || 0) +
      parseInt(dataFact.cantmotivo3 || 0);
    setCalculos((calculos) => ({
      ...calculos,
      costosabsxlaempresa: costosAbsXEmpresa,
    }));
  };

  useEffect(() => {
    calcularCostosAbsXEmpresa();
  }, [dataFact.cantmotivo1, dataFact.cantmotivo2, dataFact.cantmotivo3]);

  /* INVERSION TOTAL*/
  const calcularInversionTotal = () => {
    const inversionTotal =
      (calculos.costos_operativos_total || 0) +
      (calculos.costosabsxlaempresa || 0) +
      (calculos.costototaltks || 0) +
      (calculos.costotranstks || 0);
    const inversionTotalFixed = parseFloat(inversionTotal.toFixed(2));
    setCalculos((calculos) => ({
      ...calculos,
      inversiontotal: inversionTotalFixed,
    }));
  };

  useEffect(() => {
    calcularInversionTotal();
  }, [
    calculos.costos_operativos_total,
    calculos.costosabsxlaempresa,
    calculos.costototaltks,
    calculos.costotranstks,
  ]);

  const calcularMargen = () => {
    if (!!calculos.preciokg_siniva && !!dataFact.costo_prod_tn) {
      const margenKg =
        (calculos.preciokg_siniva * 1000 - dataFact.costo_prod_tn) / 1000;
      setCalculos((calculos) => ({ ...calculos, margen: margenKg }));
    } else {
      setCalculos((calculos) => ({ ...calculos, margen: 0 }));
    }
  };

  useEffect(() => {
    calcularMargen();
  }, [calculos.preciokg_siniva, dataFact.costo_prod_tn]);

  //------------------- ARTEFACTOS --------------- //
  const almacenarArtefactos = () => {
    const values = [...listaArtefactos];
    values.push({
      factuuid: props.uuid,
      artefacto: "",
      cantidad: 0,
      kcalh: "",
      horasdia: "",
      diasanio: "",
      kcalsanual: "",
      consumoanual: "",
    });
    setListaArtefactos(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...listaArtefactos];
    const updatedValue = event.target.name;
    values[index][updatedValue] =
      event.target.value === "" ? null : event.target.value;

    //La siguiente línea, hace el cáculo de las kcal que consume al año el artefacto.
    values[index].kcalsanual =
      values[index].cantidad *
      values[index].kcalh *
      values[index].horasdia *
      values[index].diasanio;

    // La siguiente línea calcula el consumoanual en kg por cada artefacto:
    values[index].consumoanual = (values[index].kcalsanual / 12000).toFixed(2);

    // Calcular y actualizar el consumo total anual
    const totalAnual = values.reduce(
      (total, artefacto) => total + parseFloat(artefacto.consumoanual || 0),
      0
    );
    setCalculos((calculos) => ({
      ...calculos,
      kganual: parseFloat(totalAnual.toFixed(2)),
      costos_operativos_total: parseFloat(
        ((totalAnual / 1000) * calculos.costos_operativos_xtn_pesos).toFixed(1)
      ),
    }));

    if (event.target.name === "horasdia") {
      Validaciones.validarHs(
        index,
        event.target.value,
        validacionDiasHs,
        setValidacionDiasHs
      );
    } else if (event.target.name === "diasanio") {
      Validaciones.validarDias(
        index,
        event.target.value,
        validacionDiasHs,
        setValidacionDiasHs
      );
    }
  };

  const validarArtefactos = () => {
    const horasvalidadas = validacionDiasHs.errorHs.every(
      (error) => error !== true
    );
    const diasvalidados = validacionDiasHs.errorDias.every(
      (error) => error !== true
    );
    const horasydias = horasvalidadas && diasvalidados;
    const OK = horasydias && !!calculos.kganual;
    setArtOK(OK);
  };

  useEffect(() => {
    validarArtefactos();
  }, [calculos.kganual, validacionDiasHs]);

  //Esto no sé que tan correcto es pero es de la unica forma que funciona
  useEffect(() => {
    if (!!listaArtefactos) {
      const totalAnual = listaArtefactos.reduce(
        (total, artefacto) => total + parseFloat(artefacto.consumoanual || 0),
        0
      );
      setCalculos((calculos) => ({
        ...calculos,
        kganual: parseFloat(totalAnual.toFixed(2)),
        costos_operativos_total: parseFloat(
          (
            (totalAnual / 1000) * calculos.costos_operativos_xtn_pesos || 0
          ).toFixed(1)
        ),
      }));
    } else {
      setDataFact({ ...dataFact, kganual: 0 });
    }
  }, [listaArtefactos, calculos.costos_operativos_xtn_pesos]);

  /* KG MININMO ANUAL */
  const calcularConsumoMinimo = () => {
    if (!!calculos.kganual && !!dataFact.variacion_porcentual) {
      const exp1 = (calculos.kganual * dataFact.variacion_porcentual) / 100;
      const consMinimo = parseFloat((calculos.kganual - exp1).toFixed(4));
      setCalculos((calculos) => ({ ...calculos, kganualminimo: consMinimo }));
    } else {
      setCalculos((calculos) => ({ ...calculos, kganualminimo: 0 }));
    }
  };

  useEffect(() => {
    calcularConsumoMinimo();
  }, [calculos.kganual, dataFact.variacion_porcentual]);

  /* CIERRE X VOLUMEN ESTIMADO*/
  const calcularCierreXVolEstimado = () => {
    if (
      !!calculos.inversiontotal &&
      !!calculos.kganual &&
      !!calculos.margen &&
      !!calculos.costot1 &&
      !!calculos.costot2
    ) {
      const cierreXVolEst =
        calculos.inversiontotal /
        (calculos.kganual *
          (calculos.margen - calculos.costot1 - calculos.costot2));
      setCalculos((calculos) => ({
        ...calculos,
        cierreestimado: parseFloat(cierreXVolEst.toFixed(2)),
      }));
    }
  };

  /* CIERRE X VOLUMEN ESTIMADO*/
  const calcularCierreXVolMinimo = () => {
    if (
      !!calculos.inversiontotal &&
      !!calculos.kganualminimo &&
      !!calculos.margen &&
      !!calculos.costot1 &&
      !!calculos.costot2
    ) {
      const cierreXVolMin =
        calculos.inversiontotal /
        (calculos.kganualminimo *
          (calculos.margen - calculos.costot1 - calculos.costot2));
      setCalculos((calculos) => ({
        ...calculos,
        cierreminimo: parseFloat(cierreXVolMin.toFixed(2)),
      }));
    } else {
      setDataFact((dataFact) => ({ ...dataFact, cierrevolumenminimo: 0 }));
    }
  };

  useEffect(() => {
    calcularCierreXVolEstimado();
    calcularCierreXVolMinimo();
  }, [
    calculos.inversiontotal,
    calculos.kganual,
    calculos.kganualminimo,
    calculos.margen,
    calculos.costot1,
    calculos.costot2,
  ]);

  const handleClose = () => {
    setNotify({
      open: false,
      severity: "",
      color: "",
      mensaje: "",
    });
    props.setShowMod(false);
    props.setShowTable(true);
  };

  const clienteData = {
    razon_social: dataCliente && dataCliente.razon_social,
    direccion_fiscal: dataCliente && dataCliente.direccion_fiscal,
    telefono1: dataCliente && dataCliente.telefono1,
    telefono2: dataCliente && dataCliente.telefono2,
    telefono3: dataCliente && dataCliente.telefono3,
    mails: dataCliente && dataCliente.mails,
    cuit: dataCliente && dataCliente.cuit,
    id_sucursal: dataCliente && dataCliente.id_sucursal,
    id_localidad: dataCliente && dataCliente.id_localidad,
    id_comercial: dataCliente && dataCliente.id_comercial,
    id_segmento: dataCliente && dataCliente.id_segmento,
    id_subsegmento: dataCliente && dataCliente.id_subsegmento,
    usuario: usuario,
    id_provincia: dataCliente && dataCliente.id_provincia,
    situacion_iva: dataCliente && dataCliente.situacion_iva,
    id_condicionpago: dataCliente && dataCliente.id_condicionpago,
    tipo_doc_afip: dataCliente && dataCliente.tipo_doc_afip,
    facturacion_especial: dataCliente && dataCliente.facturacion_especial,
    factura_consfinal: dataCliente && dataCliente.factura_consfinal,
    unidad_fact:dataCliente&&dataCliente.unidad_fact,
    id_empresa:dataCliente&&dataCliente.id_empresa
  };

  useEffect(() => {
    // !!dataCliente &&
    !!dataDir &&
      !!dataFact &&
      setReqBody({
        clienteData: props.fact.id_tipofact === 235 ? {...clienteData, 
          porc_perc_iva: parseInt(dataCliente&&dataCliente.situacion_iva)===1 ? 1.75 : null , 
          facturacion_especial: parseInt(dataCliente&&dataCliente.situacion_iva)===1 ? (dataCliente&&dataCliente.facturacion_especial || null ): null,
          factura_consfinal: parseInt(dataCliente&&dataCliente.situacion_iva) === 4 ? (dataCliente&&dataCliente.factura_consfinal || null )  : null
        } : {},
        dirData: {
          direccion_entrega: dataDir.direccion_entrega,
          nombre_direccion: dataDir.nombre_direccion,
          contacto: dataDir.contacto,
          telefono1: dataDir.telefono1,
          telefono2: dataDir.telefono2,
          telefono3: dataDir.telefono3,
          mails: dataDir.mails,
          latitud: dataDir.latitud,
          longitud: dataDir.longitud,
          id_localidad: dataDir.id_localidad,
          usuario: usuario,
          id_provincia: dataDir.id_provincia,
          id_sucursal: dataDir.id_sucursal,
          id_producto: dataDir.id_producto,
          id_empresa:dataDir.id_empresa
        },
        factData: {
          id_sucatiende: dataDir && dataDir.id_sucursal,
          //Parámetros:
          precio_kg: dataFact.precio_kg,
          //INPUTS
          canttk_05: dataFact.canttk_05,
          canttk_1: dataFact.canttk_1,
          canttk_2: dataFact.canttk_2,
          canttk_4: dataFact.canttk_4,
          canttk_7: dataFact.canttk_7,
          canttk_otros: dataFact.canttk_otros,
          variacion_porcentual: dataFact.variacion_porcentual,
          motivo1: dataFact.motivo1,
          motivo2: dataFact.motivo2,
          motivo3: dataFact.motivo3,
          cantmotivo1: dataFact.cantmotivo1,
          cantmotivo2: dataFact.cantmotivo2,
          cantmotivo3: dataFact.cantmotivo3,
          motivocli1: dataFact.motivocli1,
          motivocli2: dataFact.motivocli2,
          motivocli3: dataFact.motivocli3,
          cantmotivocli1: dataFact.cantmotivocli1,
          cantmotivocli2: dataFact.cantmotivocli2,
          cantmotivocli3: dataFact.cantmotivocli3,
          // Distancias:
          dist_suc_cli: dataFact.dist_suc_cli, //Distancia cliente-sucursal
          dist_prov_suc: dataSucursal.distanciabocacarga, //Distancia proveedor-sucursal
          usuario_modifica: usuario,
          id_estado: 198,
        },
        artData: listaArtefactos,
      });
  }, [dataCliente, dataDir, dataFact]);

  useEffect(() => {
    dataCliente &&
      dataCliente.cuit &&
      setTipo(dataCliente.cuit.includes(".") ? 0 : 1);
  }, [dataCliente]);

  async function guardar(e) {
    setDisabledGuardar(true);
    if (props.fact.id_tipofact === 235 || props.fact.id_tipofact === 237) {
      // console.log("Es con cliente nuevo")
      putFactibilidades(
        e,
        "/factibilidades/" + dataFact.factuuid,
        reqBody,
        setNotify
      );
    } else {
      putFactibilidadesClienteExistente(
        e,
        "/factibilidades/clienteexistente/" +
          dataFact.factuuid +
          "/" +
          dataDir.nro_dir,
        reqBody,
        setNotify
      );
    }
  }

  // console.log(dataDir)

  /* OBTENGO EL CORREO ELECTRÓNICO DEL COMERCIAL QUE CARGÓ LA FACTIBILIDAD EN CASO DE QUE SE APRUEBE. ES NECESARIO PARA EL ENVÍO DEL MAIL QUE NOTIFICA QUE LA FACT SE APROBÓ */
  const [mailSolicitante, setMailSolicitante] = useState("");
  const getMailSolicitante = () => {
    fetch(UrlApi + "/representantes/getid/" + dataFact.usuario_registra)
      .then((data) => data.json())
      .then((data) => setMailSolicitante(data[0].mail || ""));
  };
  
  useEffect(() => {
    dataFact.usuario_registra && getMailSolicitante();
  }, [dataFact.usuario_registra]);

  // console.log(dataCliente)

  return (
    <div>
      <Container maxWidth="xl">
        <TitleUploaded
          title={
            props.readOnly
              ? "Visualización de Factibilidad"
              : "Modificar Factibilidad"
          }
          handleClose={() => {
            props.setShowMod(false);
            props.setShowTable(true);
          }}
        />
        <form onSubmit={guardar}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={8.9}
              borderRadius={5}
              style={{ height: "473px" }}
              borderColor="#B2BABB"
            >
              <Paper
                variant="elevation"
                elevation={24}
                style={{ height: "473px", width: "100%", borderRadius: 20 }}
                px={5}
              >
                {notify.open ? (
                  <Notify
                    open={notify.open}
                    color={notify.color}
                    severity={notify.severity}
                    mensaje={notify.mensaje}
                    handleClose={() => handleClose()}
                  />
                ) : null}
                <TabPanel
                  dataFact={dataFact}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  validado={{
                    cliOK: cliOK,
                    dirOK: dirOK,
                    artOK: artOK,
                    costosOK: costosOK,
                    tksOK: tksOK,
                  }}
                />
                <Box>
                  {activeTab === "cliente" && dataCliente ? (
                    <FormCliente
                      dataCliente={dataCliente}
                      setDataCliente={setDataCliente}
                      codArg={codArg}
                      validacion={{
                        error: false,
                        leyenda: "",
                      }}
                      tipo={tipo}
                      setTipo={setTipo}
                      readOnly={props.readOnly}
                      setDataDir={setDataDir} //21-2
                      edicion={true}
                    />
                  ) : null}
                  {activeTab === "domicilio" && dataDir && dataFact ? (
                    <FormDir
                      dataSucursal={dataSucursal}
                      setDataSucursal={setDataSucursal}
                      dataCliente={dataCliente}
                      dataDir={dataDir}
                      setDataDir={setDataDir}
                      mapParams={mapParams}
                      setMapParams={setMapParams}
                      codArg={codArg}
                      dirOK={dirOK}
                      dataFact={dataFact}
                      setDataFact={setDataFact}
                      latLongParams={latLongParams}
                      setLatLongParams={setLatLongParams}
                      readOnly={props.readOnly}
                    />
                  ) : null}
                  {activeTab === "artefactos" && listaArtefactos && dataFact ? (
                    <FormArtefactos
                      listaArtefactos={listaArtefactos}
                      setListaArtefactos={setListaArtefactos}
                      almacenarArtefactos={almacenarArtefactos}
                      handleInputChange={handleInputChange}
                      dataFact={dataFact}
                      setDataFact={setDataFact}
                      edicion={true}
                      validacionDiasHs={validacionDiasHs}
                      readOnly={props.readOnly}
                    />
                  ) : null}
                  {activeTab === "tanques" && dataFact ? (
                    <FormTanques
                      dataFact={dataFact}
                      setDataFact={setDataFact}
                      calculos={calculos}
                      readOnly={props.readOnly}
                    />
                  ) : null}
                  {activeTab === "costos" ? (
                    <FormCostos
                      dataFact={dataFact}
                      setDataFact={setDataFact}
                      readOnly={props.readOnly}
                    />
                  ) : null}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              {(!!calculos.costot1 || calculos.costot1 === 0) &&
              (!!calculos.costot2 || calculos.costot2 === 0) ? (
                <PanelAnalisis
                  attach={attach}
                  setAttach={setAttach}
                  costoT1={calculos.costot1} //?
                  costoT2={calculos.costot2} //?
                  costoTransTk={calculos.costotranstks}
                  validado={{
                    cliOK: cliOK,
                    artOK: artOK,
                    dirOK: dirOK,
                    costosOK: costosOK,
                    tksOK: tksOK,
                  }}
                  dataFact={dataFact}
                  setDataFact={setDataFact}
                  calculos={calculos}
                  setCalculos={setCalculos}
                  disabledGuardar={disabledGuardar}
                  setShowForm={props.setShowMod}
                  setShowTable={props.setShowTable}
                  readOnly={props.readOnly}
                  receptor={factConfig && factConfig.mail_administracion}
                  autorizante={factConfig && factConfig.mail_autorizante}
                  // cliente={dataCliente && dataCliente.razon_social}
                  cliente={dataCliente}
                  mailSolicitante={mailSolicitante}
                  direccion={dataDir && dataDir.direccion_entrega}
                  guardar={guardar}
                  factConfig={factConfig}
                  valorDolar={props.fact.precio_dolar}
                  dataDir={dataDir}
                  listaArtefactos={listaArtefactos}
                />
              ) : (
                <>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    backgroundColor="#E5E7E9"
                    pb={10}
                    style={{ height: "100vh" }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        m={2}
                        fontSize={22}
                        variant="h2"
                        color="initial"
                      >
                        Cargando análisis de factibilidad
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CircularProgress size="7rem" color="primary" />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            {showModalError.open ? (
              <ErrorModal
                open={showModalError.open}
                handleClose={() =>
                  setShowModalError({ open: false, title: "", description: "" })
                }
                title={showModalError.title}
                description={showModalError.description}
              />
            ) : null}
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default ModFact;
