import React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Notify from "../../../../Notify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SaveIcon from "@mui/icons-material/Save";
import WarningIcon from "@mui/icons-material/Warning";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import InputMask from "react-input-mask";
import { getData, putData, getCodeArg } from "../../../../../services/library"; //,
import ConfirmDialog from "../../../../Dialogs/ConfirmDialog";
import { logEventos } from "../../../../../services/services";
import { putClientes } from "../../datoscliente/savefuncions";
import Slide from "@mui/material/Slide";

function DialogEdicion(props) {
  const [dataCliente, setDataCliente] = useState({
    ...props.cliente,
    telefono1: props.cliente.telefono1
      ? props.cliente.telefono1.replace(/^(\+?549)?(\d{10})$/, "$2")
      : "",
    telefono2: props.cliente.telefono2
      ? props.cliente.telefono2.replace(/^(\+?549)?(\d{10})$/, "$2")
      : "",
    telefono3: props.cliente.telefono3
      ? props.cliente.telefono3.replace(/^(\+?549)?(\d{10})$/, "$2")
      : "",
  });
  const [sucursales, setSucursales] = useState(null);
  const [localidades, setLocalidades] = useState(null);
  const [comerciales, setComerciales] = useState(null);
  const [segmentos, setSegmentos] = useState(null);
  const [subsegmentos, setSubsegmentos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [tipo, setTipo] = useState(1); //tipo=1 (cuit) || tipo=0 (dni)
  const [condiciones, setCondiciones] = useState(null);
  const [categorias, setCategorias] = useState(null);
  const [sitIva, setSitIva] = useState(null);
  const [tipoCliente, setTipoCliente] = useState(null);
  const [disBtn, setDisBtn] = useState(false);
  const [condActual, setCondActual] = useState(dataCliente.situacion_iva);
  const [newCond, setNewCond] = useState(dataCliente.situacion_iva);
  const [modalCambioIva, setModalCambioIva] = useState(false);
  const iconWarning = <WarningIcon sx={{ fontSize: 40 }} />;

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const almacenar = (e) => {
    setDataCliente({
      ...dataCliente,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const handleSituacionIvaChange = (event) => {
    const value = event.target.value;
    setNewCond(value);
    almacenar(event);
  };

  //************************************************* ******************************************************* */
  //Fetchs para traer datos de la api a los desplegables
  const [codArg, setCodArg] = useState("");
  const [unidadesFact, setUnidadesFact] = useState(null);

  // useEffect(() => {
  //   if (dataCliente.id_tipocliente === 1) {
  //     getData("/desplegables/unidadFact", setUnidadesFact);
  //   } else {
  //     setDataCliente((dataCliente) => ({ ...dataCliente, unidad_fact: "" }));
  //   }
  // }, [dataCliente.id_tipocliente]);

  const [empresas, setEmpresas] = useState();

  useEffect(() => {
    getData("/categorias", setCategorias);
    getData("/representantes/activos", setComerciales);
    getData("/segmentos", setSegmentos);
    getData("/provincias", setProvincias);
    getData("/condicion_pago", setCondiciones);
    getData("/condiciones_iva", setSitIva);
    getData("/tipocliente", setTipoCliente);
    getData("/desplegables/unidadFact", setUnidadesFact);
    getCodeArg(setCodArg);
    getData("/empresas", setEmpresas);
  }, []);

  useEffect(() => {
    getData("/localidades/prov/" + dataCliente.id_provincia, setLocalidades);
  }, [dataCliente.id_provincia]);

  useEffect(() => {
    getData("/subsegmentos/seg/" + dataCliente.id_segmento, setSubsegmentos);
  }, [dataCliente.id_provincia]);

  useEffect(() => {
    getData("/subsegmentos/seg/" + dataCliente.id_segmento, setSubsegmentos);
    if (!(props.cliente.id_segmento === dataCliente.id_segmento)) {
      dataCliente.id_subsegmento = "";
    }
  }, [dataCliente.id_segmento]);

  useEffect(() => {
    getData("/localidades/prov/" + dataCliente.id_provincia, setLocalidades);

    if (!(props.cliente.id_provincia === dataCliente.id_provincia)) {
      dataCliente.id_localidad = "";
    }
  }, [dataCliente.id_provincia]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const [modalIvaParams, setModalIvaParams] = useState({
    open: false,
    notify: notificacion,
    setNotificacion: setNotificacion,
  });

  async function guardarCliente(e) {
    e.preventDefault();
    const sitIvaOriginal = parseInt(props.cliente.situacion_iva);
    // console.log(sitIvaOriginal);

    const sitIvaActual = parseInt(dataCliente.situacion_iva); // esto es una formalidad porque si cambia ya se guarda como entero.

    if (sitIvaOriginal !== sitIvaActual) {
      if (sitIvaOriginal === 1) {
        setModalIvaParams({
          open: true,
          title: "Cambio de situación de IVA",
          subtitle:
            "Se borrarán los impuestos (percepciones de ingresos brutos, percepcion de iva, etc) asociados al cliente. Presione guardar para confirmar los cambios.",
          icon: iconWarning,
          color: "#f53649",
          guardar: (e) => save(e, 1), //este uno que le mando es una bandera para indicar que tiene que "borrar" o poner inactivos los iibb cargados a manopla
        });
      } else {
        if (sitIvaActual === 1) {
          setModalIvaParams({
            open: true,
            title: "Cambio de situación de IVA",
            subtitle:
              "Es necesario ingresar los impuestos. Dirigirse a 'Administrar datos -> Impuestos'. Presione guardar para confirmar los cambios.",
            icon: iconWarning,
            color: "#f53649",
            guardar: (e) => save(e, 0),
          });
        } else {
          save(e, 0);
        }
      }
    } else {
      save(e, 0);
    }
    props.actualizar();
  }

  function save(e, borrariibb) {
    setDisBtn(true);
    putClientes(
      e,
      borrariibb,
      codArg,
      props.cliente.id,
      { ...dataCliente, usuario: localStorage.getItem("user") }, // seguir aca con la facturacion especial, preuguntar la situacion de iva y en base a eso ver si le saco o no la facturacion especial
      setNotificacion
    );
  }

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen
        open={props.open}
        onClose={() => handleClose()}
        maxWidth="xl"
      >
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={() => {
              props.setOpen(false);
              props.actualizar();
            }}
            severity={notificacion.severity}
          />
        ) : null}
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography fontSize={20} align="center" color="#ffffff">
              Editar Cliente {dataCliente.razon_social}
            </Typography>
          </DialogTitle>
        </Box>
        <form onSubmit={guardarCliente}>
          <DialogContent>
            <Box
              backgroundColor="#F4F6F6"
              px={2}
              py={2}
              border={0.5}
              borderColor={"#ABB2B9"}
              borderRadius={3}
            >
              <Grid container spacing={1.2}>
                <Grid item xs={4.5}>
                  <TextField
                    size="small"
                    focused
                    style={style}
                    placeholder="Razon Social"
                    type="text"
                    required
                    name="razon_social"
                    label="Razon Social"
                    onChange={almacenar}
                    value={
                      dataCliente.razon_social &&
                      dataCliente.razon_social.replace(/\b\w/g, (l) =>
                        l.toUpperCase()
                      )
                    }
                    inputProps={{ maxLength: 200 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <TextField
                    size="small"
                    style={style}
                    placeholder="Sisven ID"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ maxLength: 6 }} //solo 2 numeros
                    focused
                    name="sisven_id"
                    label="Sisven ID"
                    color="primary"
                    onChange={almacenar}
                    value={dataCliente.sisven_id}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MeetingRoomIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={1.5}>
                  <FormControl
                    style={style}
                    required
                    focused
                    size="small"
                    disabled
                  >
                    <InputLabel> Tipo Cliente</InputLabel>
                    <Select
                      label="Tipo Cliente"
                      name="id_tipocliente"
                      value={dataCliente.id_tipocliente}
                      onChange={almacenar}
                    >
                      {tipoCliente
                        ? tipoCliente.map((elemento) => (
                            <MenuItem
                              key={elemento.idtipocliente}
                              value={elemento.idtipocliente}
                            >
                              {elemento.nombre}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={1.5}>
                  <FormControl style={style} focused size="small">
                    <InputLabel> Facturación En: </InputLabel>
                    <Select
                      label="Facturación En: "
                      name="unidad_fact"
                      value={dataCliente.unidad_fact}
                      onChange={almacenar}
                    >
                      {unidadesFact
                        ? unidadesFact.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl required style={style} focused size="small">
                    <InputLabel> Situacion Iva</InputLabel>
                    <Select
                      label="Situacion Iva"
                      name="situacion_iva"
                      value={dataCliente.situacion_iva}
                      onChange={almacenar}
                    >
                      {sitIva
                        ? sitIva.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.descripcion}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl required style={style} focused size="small">
                    <InputLabel>Categoría Crediticia</InputLabel>
                    <Select
                      name="id_categoria"
                      value={dataCliente.id_categoria}
                      label="Categoria crediticia"
                      onChange={almacenar}
                    >
                      {categorias
                        ? categorias.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.categoria} ({elemento.descripcion})
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl required style={style} focused size="small">
                    <InputLabel>Cuit/Dni</InputLabel>
                    <Select
                      name="tipo_doc_afip"
                      labelId="Cuit/Dni"
                      value={dataCliente.tipo_doc_afip}
                      label="Cuit/Dni"
                      onChange={almacenar}
                    >
                      <MenuItem value={80}>Cuit</MenuItem>
                      <MenuItem value={96}>Dni</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {dataCliente.tipo_doc_afip === 80 ? (
                  <Grid item xs={2}>
                    <InputMask
                      mask="99-99999999-9"
                      value={dataCliente.cuit}
                      disabled={false}
                      maskChar=" "
                      onChange={almacenar}
                    >
                      {() => (
                        <TextField
                          size="small"
                          style={style}
                          type="text"
                          focused
                          name="cuit"
                          label="Numero Cuit"
                        />
                      )}
                    </InputMask>
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <InputMask
                      mask="99.999.999"
                      value={dataCliente.cuit}
                      disabled={false}
                      maskChar=" "
                      onChange={almacenar}
                    >
                      {() => (
                        <TextField
                          size="small"
                          style={style}
                          type="text"
                          focused
                          name="cuit"
                          label="Numero Dni"
                        />
                      )}
                    </InputMask>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <FormControl required style={style} focused size="small">
                    <InputLabel>Condición de pago</InputLabel>
                    <Select
                      name="id_condicionpago"
                      labelId="id_condicionpago"
                      value={dataCliente.id_condicionpago}
                      label="Condición de pago"
                      onChange={almacenar}
                    >
                      {condiciones
                        ? condiciones.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.condicion}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    style={style}
                    fullWidth
                    placeholder="100000"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ maxLength: 9 }} //solo 2 numeros
                    focused
                    name="limite_credito"
                    label="Límite de crédito"
                    color="primary"
                    onChange={almacenar}
                    value={dataCliente.limite_credito}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    style={style}
                    focused
                    placeholder="Direccion Fiscal"
                    id="direccion_fiscal"
                    name="direccion_fiscal"
                    label="Direccion Fiscal"
                    color="primary"
                    value={
                      dataCliente.direccion_fiscal &&
                      dataCliente.direccion_fiscal.replace(/\b\w/g, (l) =>
                        l.toUpperCase()
                      )
                    }
                    onChange={almacenar}
                    inputProps={{ maxLength: 120 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl required style={style} size="small" focused>
                    <InputLabel> Provincia </InputLabel>
                    <Select
                      style={{ backgroundColor: "#FFFFFF" }}
                      label="Provincia"
                      color="primary"
                      name="id_provincia"
                      value={dataCliente.id_provincia}
                      onChange={almacenar}
                    >
                      {provincias
                        ? provincias.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.provincia}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl required style={style} size="small" focused>
                    <InputLabel>Localidad</InputLabel>
                    <Select
                      style={{ backgroundColor: "#FFFFFF" }}
                      label="Localidad"
                      name="id_localidad"
                      value={dataCliente.id_localidad}
                      onChange={almacenar}
                    >
                      {localidades
                        ? localidades.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.localidad}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl required fullWidth size="small" focused>
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      style={{ backgroundColor: "#FFFFFF" }}
                      name="id_empresa"
                      value={dataCliente.id_empresa}
                      label="Empresa"
                      onChange={almacenar}
                    >
                      {empresas
                        ? empresas.map((elemento) => (
                            <MenuItem
                              key={elemento.idempresa}
                              value={elemento.idempresa}
                            >
                              {elemento.empresa}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                  {/* aca va la sucursal */}
                </Grid>
                <Grid item xs={4}>
                  <FormControl required style={style} size="small" focused>
                    <InputLabel>Segmento</InputLabel>
                    <Select
                      style={{ backgroundColor: "#FFFFFF" }}
                      label="Segmento"
                      name="id_segmento"
                      value={dataCliente.id_segmento}
                      onChange={almacenar}
                    >
                      {segmentos
                        ? segmentos.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.segmento} - {elemento.codigo}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl required style={style} size="small" focused>
                    <InputLabel>Subsegmento</InputLabel>
                    <Select
                      style={{ backgroundColor: "#FFFFFF" }}
                      label="Subsegmento"
                      name="id_subsegmento"
                      value={dataCliente.id_subsegmento}
                      onChange={almacenar}
                    >
                      {subsegmentos
                        ? subsegmentos.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.subsegmento} -{" "}
                              {elemento.codigo_subsegmento}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl required style={style} size="small" focused>
                    <InputLabel>Comercial</InputLabel>
                    <Select
                      style={{ backgroundColor: "#FFFFFF" }}
                      label="Comercial"
                      name="id_comercial"
                      value={dataCliente.id_comercial}
                      onChange={almacenar}
                    >
                      {comerciales
                        ? comerciales.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.nombre_apellido}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>

                {/* aca va el comercial  */}

                <Grid item xs={6}>
                  <TextField
                    style={style}
                    size="small"
                    focused
                    placeholder="Mails"
                    id="mails"
                    name="mails"
                    label="Mails"
                    color="primary"
                    value={dataCliente.mails}
                    onChange={almacenar}
                    inputProps={{ maxLength: 500 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ContactMailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    style={style}
                    size="small"
                    type="text"
                    focused
                    color="primary"
                    placeholder="Telefono 1"
                    name="telefono1"
                    id="telefono1"
                    label="Telefono 1"
                    onChange={almacenar}
                    value={dataCliente.telefono1}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    style={style}
                    size="small"
                    type="text"
                    focused
                    color="primary"
                    placeholder="Telefono 2"
                    name="telefono2"
                    label="Telefono 2"
                    onChange={almacenar}
                    value={dataCliente.telefono2}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    style={style}
                    size="small"
                    type="text"
                    focused
                    color="primary"
                    placeholder="Telefono 3"
                    name="telefono3"
                    label="Telefono 3"
                    onChange={almacenar}
                    value={dataCliente.telefono3}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={9}>
                  <TextField
                    style={style}
                    size="small"
                    name="observaciones"
                    type="text"
                    inputProps={{ maxLength: 600 }}
                    focused
                    label="Observaciones"
                    value={dataCliente.observaciones}
                    onChange={almacenar}
                  />
                </Grid>
                {parseInt(dataCliente.situacion_iva) === 4 && (
                  <Grid item xs={3}>
                    <FormControlLabel
                      // disabled={ROnly}
                      control={
                        <Checkbox
                          checked={dataCliente.factura_consfinal === 1}
                          onChange={(e) =>
                            setDataCliente({
                              ...dataCliente,
                              factura_consfinal: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      }
                      label="Factura A Consumidor Final"
                    />
                  </Grid>
                )}

                {parseInt(dataCliente.situacion_iva) === 1 && (
                  <Grid item xs={3}>
                    <FormControlLabel
                      // disabled={ROnly}
                      checked={dataCliente.facturacion_especial === 1}
                      onChange={(e) =>
                        setDataCliente({
                          ...dataCliente,
                          facturacion_especial: e.target.checked ? 1 : 0,
                        })
                      }
                      control={<Checkbox />}
                      label="Facturación Especial"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />
                  </Grid>
                )}

                {dataCliente.id_tipocliente === 2 ||
                dataCliente.id_tipocliente === 1 ? (
                  <>
                    <Grid item xs={3}>
                      <FormControlLabel
                        // disabled={ROnly}
                        checked={dataCliente.pesada_cliente}
                        onChange={(e) =>
                          setDataCliente({
                            ...dataCliente,
                            ["pesada_cliente"]: e.target.checked ? 1 : 0,
                          })
                        }
                        control={<Checkbox />}
                        label="PESADA CLIENTE"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                      />
                    </Grid>
                  </>
                ) : null}

                {/* {dataCliente.id_tipocliente === 2 ||
                dataCliente.id_tipocliente === 1 ? (
                  <>
                    <Grid item xs={3}>
                      <FormControlLabel
                        checked={dataCliente.pesada_cliente === 1}
                        onChange={(e) =>
                          setDataCliente({
                            ...dataCliente,
                            pesada_cliente: e.target.checked ? 1 : 0,
                          })
                        }
                        control={<Checkbox />}
                        label="PESADA CLIENTE"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                      />
                    </Grid>
                  </>
                ) : null} */}
                {/* {parseInt(dataCliente.situacion_iva) === 4 ? (
                  <Grid item xs={3}>
                    <FormControlLabel
                      labelPlacement="right"
                      control={
                        <Checkbox
                          checked={dataCliente.factura_consfinal === 1}
                          onChange={(e) =>
                            setDataCliente({
                              ...dataCliente,
                              factura_consfinal: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      }
                      label="FACTURA CONSUMIDOR FINAL"
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3}>
                    <FormControlLabel
                      checked={dataCliente.facturacion_especial === 1}
                      onChange={(e) =>
                        setDataCliente({
                          ...dataCliente,
                          facturacion_especial: e.target.checked ? 1 : 0,
                        })
                      }
                      control={<Checkbox />}
                      label="FACTURACION ESPECIAL"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />
                  </Grid>
                )} */}
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleClose()}
                    style={{ backgroundColor: "#fff" }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    // onClick={guardarCliente}
                    color="success"
                    variant="contained"
                    disabled={disBtn}
                    endIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
      {modalIvaParams.open === true && (
        <ConfirmDialog
          open={modalIvaParams.open}
          title={modalIvaParams.title}
          subtitle={modalIvaParams.subtitle}
          setOpen={() => setModalIvaParams({ ...modalIvaParams, open: false })}
          guardar={(e) => modalIvaParams.guardar(e)}
          notify={notificacion}
          setNotify={setNotificacion}
          icon={modalIvaParams.icon}
          color={modalIvaParams.color}
        />
      )}
    </div>
  );
}
export default DialogEdicion;

//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Magali Perea
// Creation Date : NO SE AH RE
// Version       : 1.0  
// Description   :  DIALOGO EDICION CLIENTE
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 24/7/2024  
// Change by     : Candela Grosso
// Change description:  Agregué dialogo para validar cambio de situacion de iva y
 update de impuestos en caso de ser consumidor final o monotributisita
//========================================================================*/
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 06/9/2024
// Change by     : Magali Perea
// Change description: Se cambiaron algunas partes del componente por los cambios en la parte impositiva del cliente.
//========================================================================*/
