import React from "react";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { getDataIx0, putData } from "../../../../services/library";
import DireccionesEntrega from "./direccionescliente/DireccionesEntrega";
import TablaTanques from "./tanquescliente/TablaTanques";
import ListContratos from "./contratos/ListContratos";
import PedidosCli from "./PedidosCli";
import DatosCli from "./DatosCli";
import Title from "../../../Title";
import ReclamosCli from "./reclamos/ReclamosCli";
import ImpuestosCliente from "./impuestos/ImpuestosCliente";
import Comprobantes from "./cuentacorriente/Comprobantes"


function FichaCliente(props) {
  const id_cliente = props.datos.id;
  const [datosformulario, setDatosformulario] = useState(null);

  /************************************************************************************************/
  //Maneja TAB
  const [opcion, setOpcion] = useState("datos");
  const handleChange = (event, newValue) => {
    setOpcion(newValue);
  };

  //Cierra la ficha del cliente
  const handleClose = () => {
    props.ficha(false);
    props.tabla(true);
  };

  useEffect(()=>{
    getDataIx0("/clientes/" + id_cliente, setDatosformulario);
  },[])

  const obtenerDataCliente = () => {
    setDatosformulario(null)
    getDataIx0("/clientes/" + id_cliente, setDatosformulario);
    
  };

  return (
    <div>
      <Container maxWidth="xl">
        {datosformulario ? (
          <>
            <Title
              titulo={` ${datosformulario.razon_social} (${
                datosformulario.sisven_id
                  ? datosformulario.sisven_id
                  : "Sisven Id No Registrado"
              })  `}
              handleClose={handleClose}
            />
            <Box sx={{ width: "100%" }}>
              <Tabs
                variant="fullWidth"
                value={opcion}
                onChange={handleChange}
                textColor="primary"
                indicatorColor={"primary"}
                aria-label="Clientes"
              >
                <Tab value="datos" label="Datos" />
                {(datosformulario.situacion_iva === "1" ||
                  datosformulario.situacion_iva === "3") && (
                  <Tab value="impuestos" label="Impuestos" />
                )}
                <Tab value="direcciones" label="Direcciones de entrega" />
                <Tab value="tanques" label="Tanques" />
                {/* <Tab value="contratos" label="Contratos" /> */}
                <Tab value="pedidos" label="Pedidos" />
                <Tab value="reclamos" label="Reclamos" />
                <Tab value="cc" label="Cuenta Corriente" />
              </Tabs>
              <br />
            </Box>
            {opcion === "datos" ? (
              <DatosCli
                datos={datosformulario}
                ficha={props.ficha}
                tabla={props.tabla}
                actualizar={props.actualizar}
                obtenerDatos={obtenerDataCliente}
              />
            ) : null}
            {opcion === "impuestos" ? (
              <ImpuestosCliente cliente={datosformulario} />
            ) : null}
            {opcion === "direcciones" ? (
              <DireccionesEntrega cliente={datosformulario} />
            ) : null}
            {opcion === "tanques" ? (
              <TablaTanques datos={datosformulario} />
            ) : null}
            {opcion === "contratos" ? (
              <ListContratos datos={datosformulario} />
            ) : null}
            {opcion === "pedidos" ? (
              <PedidosCli datos={datosformulario} opcion={"pedidos"} />
            ) : null}
            {opcion === "reclamos" ? (
              <ReclamosCli cliente={datosformulario} />
            ) : null}
            {opcion === "cc" ? (
              <Comprobantes cliente={datosformulario} />
            ) : null}
          </>
        ) : (
          <>
          <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <Grid item xs>
            <Typography m={6} fontSize={30} variant="h2" color="initial">
              Cargando información de cliente...
            </Typography>
          </Grid>
          <Grid item>
            <CircularProgress size="7rem" color="primary" />
          </Grid>
        </Grid>
        </>
        )}
      </Container>
    </div>
  );
}

export default FichaCliente;
