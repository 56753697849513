import { UrlApi } from "./apirest";

//Función para obtener datos de la API.
//Necesita recibir como parámetro:
//  - la ruta de la api (NO LA URL PORQUE YA LA TIENE, solo el "/pedidos" por ejemplo, o los parámetros que deseen)
//  - la función que setea el estado donde guardarán los datos devueltos por la API.
export async function getData(ruta, setData) {
  // console.log("Obteniendo datos solicitados..")
  fetch(UrlApi + ruta)
    .then((data) => data.json())
    .then((data) => {
      // console.log(data)
      setData(data);
    });
}

//Get data INDICE 0
export async function getDataIx0(ruta, setData) {
  // console.log("Obteniendo datos solicitados..")
  fetch(UrlApi + ruta)
    .then((data) => data.json())
    .then((data) => {
      // console.log(data)
      setData(data[0]);
    });
}
//Función para ACTUALIZAR registros (PUT).
// Necesita como parámetros:
//  - e
//  - la ruta que utilizarán (Ejemplo: Si van a modificar el cliente de ID 1, deberían pasarle: "/clientes/1", o como tengan definida la ruta y la forma en que le pasan los parámetros.
//  - data: son los datos que modificaran (lo que generalmente guardan en "datosformulario". O por ejemplo, si van a dar de baja un cliente, le pasan : {activo:0}
//  - setNoficacion: es un json donde se definen los parámetros para la notificación que se muestra.
export async function putData(e, ruta, data, setNotificacion) {
  //lo unico que no maneja es que la respuesta no vuelva en json
  // console.log(setNotificacion);
  e.preventDefault();
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    setNotificacion({
      open: true,
      mensaje: "Error al conectar con la base de datos.",
      severity: "error",
      color: "#FADBD8",
    });
    throw new Error(
      `Error al realizar la solicitud: ${response.status} ${response.statusText}`
    );
  } else {
    const json = await response.json();
    if (json.sqlMessage) {
      setNotificacion({
        open: true,
        mensaje: "Error SQL. Contacte a soporte.",
        severity: "error",
        color: "#FADBD8",
      });
      throw new Error(`SQL incorrecto (${json.errno})`);
    } else if (json.message) {
      setNotificacion({
        open: true,
        mensaje: "Guardado.",
        severity: "success",
        color: "#D4EFDF",
      });
    } else {
      console.log("error desconocido");
    }
  }
}

export async function postData(e, ruta, data, setNotificacion) {
  // console.log("POST DATA")
  e.preventDefault();
  const Url = UrlApi + ruta;
  const response = await fetch(Url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    setNotificacion({
      open: true,
      mensaje: "Error al conectar con la base de datos.",
      severity: "error",
      color: "#FADBD8",
    });
    throw new Error(
      `Error al realizar la solicitud: ${response.status} ${response.statusText}`
    );
  } else {
    const json = await response.json();
    if (json.sqlMessage) {
      setNotificacion({
        open: true,
        mensaje: "Error SQL. Contacte a soporte.",
        severity: "error",
        color: "#FADBD8",
      });
      throw new Error(`SQL incorrecto (${json.errno})`);
    } else if (json.message) {
      setNotificacion({
        open: true,
        mensaje: "Guardado.",
        severity: "success",
        color: "#D4EFDF",
      });
    } else {
      console.log("error desconocido");
    }
  }
}

export async function getCodeArg(setData) {
  // console.log("Obteniendo código telefónico de país...")
  fetch(UrlApi + "/desplegables/codarg")
    .then((data) => data.json())
    .then((data) => setData(data && data[0].valor));
}

