//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 05/09/2024
// Version : 1
// Description : pantalla de informacion de los tanques
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, Grid, TextField } from "@mui/material";
import moment from "moment";
//adjuntado
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR ARCHIVOS
import FileUploadTanque from "./FileUploadTanque";
import TablaServicios from "./TablaServicios";
import TablaTareas from "./TablaTareas";

function Info_tanque(props) {
  const [datosTanque, setDatosTanque] = useState(props.datos); // viene del motivos_salida {editar}
  const [info, setInfo] = useState(props.info); //Estado q mostrará o no el form de edicion de choferes

  const [newUuid, setNewUuid] = useState(""); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  //adjunto
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  useEffect(() => {
    setDatosTanque({
      ...props.datos,
    });
  }, [props.datos]);

  const handleClose = () => {
    setInfo(!info);
    if (props.onClose) {
      props.onClose();
    }
  };
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  //console.log("INFO TANQUE", datosTanque);

  return (
    <div>
      <Dialog
        open={props.info}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Información del tanque : {datosTanque.patente}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} mt={3}>
            <Box
              backgroundColor="#F4F6F6"
              p={5}
              border={0.5}
              borderColor={"#A5A5A5"}
              borderRadius={4}
              maxWidth
              mt={3}
              ml={3}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, mt: -2, textDecoration: "underline" }}
              >
                Identificación :
              </Typography>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={1}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="matricula"
                    label="Matrícula"
                    variant="outlined"
                    value={datosTanque.matricula}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="num_auditoria"
                    label="N° Auditoría"
                    variant="outlined"
                    value={datosTanque.num_auditoria}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="fabricante"
                    label="Fabricante"
                    variant="outlined"
                    value={datosTanque.fabricante}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="patente"
                    label="Patente"
                    variant="outlined"
                    value={datosTanque.patente}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="volumen"
                    label="Vol.(m3)"
                    variant="outlined"
                    value={datosTanque.volumen}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    required
                    name="datos"
                    label="Datos"
                    variant="outlined"
                    value={datosTanque.datos}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center" mt={2}>
                <Button
                  onClick={() => {
                    setNewUuid(datosTanque.uuid);
                    setOpenCloseAdj(!openCloseAdj);
                  }}
                  variant="outlined"
                  size="medium"
                  startIcon={<AttachFileIcon />}
                >
                  ARCHIVOS ADJUNTOS
                </Button>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} mt={3}>
            <Box
              backgroundColor="#F4F6F6"
              p={5}
              border={0.5}
              borderColor={"#A5A5A5"}
              borderRadius={4}
              maxWidth
              mt={3}
              mr={3}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, mt: -3, textDecoration: "underline" }}
              >
                Fechas de revisión :
              </Typography>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={1}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Última revisión quinquenal"
                    variant="outlined"
                    value={moment(datosTanque.fecha_ultima).format(
                      "DD-MM-YYYY"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Próxima revisión quinquenal"
                    variant="outlined"
                    value={moment(datosTanque.prox_ultima).format("DD-MM-YYYY")}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Última revisión de válvulas de seguridad"
                    variant="outlined"
                    value={moment(datosTanque.fecha_rev_val_seg).format(
                      "DD-MM-YYYY"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Próxima revisión de válvulas de seguridad"
                    variant="outlined"
                    value={moment(datosTanque.prox_rev_val_seg).format(
                      "DD-MM-YYYY"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Última revisión de válvulas de exceso"
                    variant="outlined"
                    value={moment(datosTanque.fecha_rev_val_exc).format(
                      "DD-MM-YYYY"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Próxima revisión de válvulas de exceso"
                    variant="outlined"
                    value={moment(datosTanque.prox_rev_val_exc).format(
                      "DD-MM-YYYY"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Última revisión de mangueras"
                    variant="outlined"
                    value={moment(datosTanque.fecha_rev_man).format(
                      "DD-MM-YYYY"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={size}
                    style={style}
                    focused
                    color="primary"
                    label="Próxima revisión de mangueras"
                    variant="outlined"
                    value={moment(datosTanque.prox_rev_man).format(
                      "DD-MM-YYYY"
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TablaServicios datosTanque={datosTanque} />
          </Grid>
          <Grid item xs={12}>
            <TablaTareas datosTanque={datosTanque} />
          </Grid>
        </Grid>
      </Dialog>

      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadTanque
          fileId={newUuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </div>
  );
}

export default Info_tanque;
