import React, { useState, useEffect } from "react";
import { postData } from "../../../../services/library";
import {
  Container, Typography, Grid, Box, TextField, IconButton, Button, InputAdornment, Autocomplete,
  Stack, FormControl, InputLabel, Select, MenuItem, DialogContent, Dialog, DialogTitle
} from "@mui/material";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";

function AddPrecioAlta(props) {
  const [datoslista, setDatoslista] = useState({
    // uuid_direccionentrega: "",
    id_cliente: props.datos.id,
    lt_con_iva: "",
    lt_sin_iva: "",
    kg_con_iva: "",
    kg_sin_iva: "",
    vigencia: ""
  });

  //boton
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  //**********************************************************************************************************************************//
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  // console.log("empresa",props.direccion)
  console.log("props.datos", props.datos);
  

  //*************************** GUARDAR *******************************************************************************************************//
  const guardarPrecio = async (e) => {
    setDisabledGuardar(false);
    postData(e, '/precios_especiales',
      {
        // uuid_direccionentrega: props.direccion.nro_dir,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
      },
      setNotificacion
    )
    logEventos("Guardar nuevo precio especial", "Precio especiales", "referencias tabla", localStorage.getItem('user'));
    props.onClose()
  };

  const almacenar = (event) => {
    //a medida que voy llenando los datos, hago directamente el calculo 

    const { name, value } = event.target;
    let newValue = { ...datoslista, [name]: value };

    //entonces en cada campo hago lo siguiente mi reynis
    //depende en el cmampo que estoy hago el calculo con el valor del porcentaje de iva del producto
    // que obtengo de la direccion de entrega seleccionada ja ja ja y  ademas con la densidad base del prducto lleno todo porque soy muy inteligente 

    const densidad = props.datos && props.datos.id_empresa === 1
      ? props.datos.densidad_hipergas
      : props.datos.densidad_autogas;

    if (name === "lt_sin_iva") {
      const precioConIva = parseFloat(value) * props.datos.iva_producto
      newValue.lt_con_iva = precioConIva.toFixed(4);
      newValue.kg_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.kg_sin_iva = (newValue.kg_con_iva / props.datos.iva_producto).toFixed(4)
    }

    if (name === "kg_sin_iva") {
      const precioConIva = parseFloat(value) * props.datos.iva_producto
      newValue.kg_con_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva * densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / props.direccion.iva_producto).toFixed(4);
    }

    if (name === "kg_con_iva") {
      const precioConIva = parseFloat(value) / props.datos.iva_producto
      newValue.kg_sin_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / props.datos.iva_producto).toFixed(4);
    }

    if (name === "lt_con_iva") {
      const precioConIva = parseFloat(value) / props.datos.iva_producto
      newValue.lt_sin_iva = precioConIva.toFixed(4);
      newValue.kg_sin_iva = (precioConIva * densidad).toFixed(4);
      newValue.kg_con_iva = (newValue.kg_sin_iva * props.datos.iva_producto).toFixed(4);
    };

    setDatoslista(newValue)
  };

  //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR ***************************************************************************************//
  useEffect(() => {
    if (datoslista.lt_con_iva === "" || datoslista.lt_sin_iva === "" || datoslista.kg_con_iva === "" || datoslista.kg_sin_iva === "" || datoslista.vigencia === "") {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [datoslista]);


  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={props.open}
        onClose={() => props.onClose(false)}
        style={{ zIndex: 1 }}
      >
        <Box justifyContent="center" backgroundColor="#1F618D">
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Precio especial para cliente {props.datos.razon_social}
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
          <Container>
            <form onSubmit={guardarPrecio} autoComplete="off">
              <Box
                backgroundColor="#F4F6F6"
                mt={2}
                p={3}
                border={0.5}
                borderColor={"#ABB2B9"}
                borderRadius={4}
              >

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  mt={1}
                >
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      size={size}
                      style={style}
                      focused
                      color="primary"
                      required
                      name="lt_sin_iva"
                      label="Precio litro sin IVA"
                      variant="outlined"
                      value={datoslista.lt_sin_iva}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      size={size}
                      style={style}
                      focused
                      color="primary"
                      required
                      name="lt_con_iva"
                      label="Precio litro con IVA"
                      variant="outlined"
                      value={datoslista.lt_con_iva}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      size={size}
                      style={style}
                      focused
                      color="primary"
                      required
                      name="kg_sin_iva"
                      label="Precio kilo sin IVA"
                      variant="outlined"
                      value={datoslista.kg_sin_iva}
                      onChange={almacenar}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      size={size}
                      style={style}
                      focused
                      color="primary"
                      required
                      name="kg_con_iva"
                      label="Precio kilo con IVA"
                      variant="outlined"
                      value={datoslista.kg_con_iva}
                      onChange={almacenar}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      size={size}
                      style={style}
                      focused
                      color="primary"
                      required
                      type="date"
                      name="vigencia"
                      label="Vigencia"
                      variant="outlined"
                      value={datoslista.vigencia}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mt={2}
                >
                  <Button variant="contained" onClick={() => props.onClose(false)} color="error">
                    Volver
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    color="success"
                    disabled={disabledGuardar}
                  >
                    Guardar Precio
                  </Button>
                </Grid>
              </Box>
            </form>
            {notificacion && notificacion.open ? (
              <Notify
                mensaje={notificacion.mensaje}
                open={notificacion.open}
                color={notificacion.color}
                handleClose={props.onClose}
                severity={notificacion.severity}
              />
            ) : null}
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddPrecioAlta

//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date :19/9/2024
// Change by : Candela Grosso
// Change description: Modifique el orden y ciertas cosas 
// Change Date :
// Change by :
// Change description:
//========================================================================/