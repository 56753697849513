//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date : 06/09/2024
// Version : 1
// Description : agregar tarea
//------Changes -------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../services/apirest";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Autocomplete,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Notify from "../Notify";
import Alerta from "../Alerta";
import Title from "../Title";
import { logEventos } from "../../services/services";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { enviarmails } from "../../services/services";
import { postData } from "../../services/library";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN

function Agregar_tarea_check(props) {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [datosTanque, setDatosTanque] = useState(props.datos); // viene del motivos_salida {editar}
  const [tarea, setTarea] = useState(props.tarea);
  const [estadoTarea, setEstadoTarea] = useState(1); // Inicialmente en 'Finalizada'
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [datosTarea, setDatosTarea] = useState({
    id_tanque: props.datos.id_tanque || null,
    tarea_desde: "",
    tarea_hasta: "",
    detalle_tarea: "",
    motivo_tarea: "",
    lugar_tarea: "",
    encargado: "",
    proviene: 1,
    repuesto: "",
    cantidad: "",
    usuario: localStorage.getItem("user"),
  });

  /*------------------- STOCK DE LA SUCURSAL ---------------------------*/
  const [sucursales, setSucursales] = useState([]);

  //funcion para obtener sucursal del usuario logueado
  const obtenerSucursales = () => {
    fetch(UrlApi + "/stock_sucursales/" + localStorage.getItem("sucursal"))
      .then((data) => data.json())
      .then((data) => setSucursales(data));
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  // console.log("sucursales", sucursales);
  /*------------------ ARTICULOS DE LA SUCURSAL ---------------------- */
  const [artSuc, setArtSuc] = useState();
  const [articuloSeleccionado, setArticuloSeleccionado] = useState("");
  //funcion para obtener los repuestos x sucursal
  const obtenerArticulosSuc = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/inventarioSucursal/" +
        localStorage.getItem("sucursal")
    )
      .then((data) => data.json())
      .then((data) => setArtSuc(data));
  };

  useEffect(() => {
    obtenerArticulosSuc();
  }, []);
  // console.log("INVENTARIO", artSuc);
  //console.log("repuesto seleccionado", articuloSeleccionado);

  // LIMPIAR AUTOCOMPLETE
  useEffect(() => {
    setAlmacenSeleccionado("");
  }, [articuloSeleccionado]);
  // console.log("articulo seleccionadoo", articuloSeleccionado);

  /*---------------------------- ALMACENES DE LA SUCURSAL ----------------*/
  const [almacen, setAlmacen] = useState();
  const [almacenSeleccionado, setAlmacenSeleccionado] = useState("");

  //-----------funcion para obtener almacenes de la sucursal
  const obtenerAlmacenesSuc = () => {
    fetch(
      UrlApi + "/stock_almacenes/xsucursal/" + localStorage.getItem("sucursal")
    )
      .then((data) => data.json())
      .then((data) => setAlmacen(data));
  };

  useEffect(() => {
    obtenerAlmacenesSuc();
  }, []);
  /*---------------------------- ALMACEN CON ARTICULOS -----------------------*/
  const [almacenConArt, setAlmacenConArt] = useState();

  //funcion para obtener almacenes de la sucursal
  const obtenerAlmacenesSucConArt = () => {
    fetch(
      UrlApi +
        "/stock_almacenes/xsucursal2/" +
        localStorage.getItem("sucursal") +
        "/" +
        articuloSeleccionado.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setAlmacenConArt(data));
  };
  useEffect(() => {
    obtenerAlmacenesSucConArt();
    //   console.log("OBTENGO ALMACENES CON ARTICULOS SELECCIONADOS");
  }, [articuloSeleccionado]);

  // console.log("ALMACEN", almacen);
  // console.log("almacen seleccionado", almacenSeleccionado);
  // console.log("almacen con articulos", almacenConArt);

  /*-------------------- VERIFICA EXISTENCIA DE ART EN EL ALMACEN ------------ */
  const [existe, setExiste] = useState();

  const obtenerExiste = () => {
    fetch(
      UrlApi +
        "/stock_inventarios/verificaExiste/" +
        articuloSeleccionado.id_articulo +
        "/" +
        almacenSeleccionado.id_almacen
    )
      .then((data) => data.json())
      .then((data) => setExiste(data));
  };
  useEffect(() => {
    obtenerExiste();
  }, [articuloSeleccionado, almacenSeleccionado]);

  // console.log("EXISTE", existe);

  /*------------------ lo de siempre -----------------------------*/
  useEffect(() => {
    setDatosTanque({
      ...props.datos,
    });
    setDatosTarea((prev) => ({
      ...prev,
      id_tanque: props.datos.id_tanque || null, // Asigna el id_tanque cuando cambian los datos del tanque
    }));
  }, [props.datos]);

  const handleClose = () => {
    setTarea(!tarea);
    if (props.onClose) {
      props.onClose();
    }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const almacenar = (e) => {
    const { name, value } = e.target;
    setDatosTarea((prev) => ({
      ...prev,
      [name]: value || null,
    }));
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1890ff",
          ...theme.applyStyles("dark", {
            backgroundColor: "#177ddc",
          }),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
      ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255,255,255,.35)",
      }),
    },
  }));

  /*----------------- MANEJO DE BLOQUEO DEL BOTON ------------------*/
  useEffect(() => {
    // Deshabilita el botón si no hay artículo o almacén seleccionado, si no existe stock, o si la cantidad usada es mayor al stock disponible
    if (
      !articuloSeleccionado ||
      !almacenSeleccionado ||
      !existe ||
      existe.length === 0 ||
      (almacenConArt.length > 0 &&
        datosTarea.cantidad > almacenConArt[0].cantidad)
    ) {
      setDisabledGuardar(true); // Deshabilita el botón
    } else {
      setDisabledGuardar(false); // Habilita el botón
    }
  }, [
    articuloSeleccionado,
    almacenSeleccionado,
    existe,
    datosTarea,
    almacenConArt,
  ]);

  /*--------------------------------------------PARTE DE BAJA DE STOCK /MAIL /REQUERIMIENTO----------------------*/
  const [uuid, setUuid] = useState(uuidv4());

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  console.log("FECHA", date);

  const [consumo, setConsumo] = useState({
    fecha_transaccion: date,
    cantidad_enviada: datosTarea.cantidad,
  });

  //-------------- obtener usuario compras para el mail ------------//
  const [usuarioCompras, setUsuarioCompras] = useState([]);

  const obtenerUsuarioCompras = () => {
    fetch(UrlApi + "/userdata/sector/4/")
      .then((data) => data.json())
      .then((data) => setUsuarioCompras(data));
  };

  useEffect(() => {
    obtenerUsuarioCompras();
  }, []);
  // console.log("usuario compras", usuarioCompras);

  //-------------- ver si existen requerimientos-------------------------//
  const [reqExiste, setReqExiste] = useState([]);

  const obtenerRequerimientoExistente = () => {
    fetch(
      UrlApi +
        "/requerimientos_materiales/reqexiste/" +
        localStorage.getItem("sucursal") +
        "/" +
        articuloSeleccionado.id_articulo
    )
      .then((data) => data.json())
      .then((data) => setReqExiste(data));
  };

  useEffect(() => {
    obtenerRequerimientoExistente();
  }, [articuloSeleccionado]);

  //console.log("REQUERIMIENTO EXISTENTE", reqExiste);

  //---------- calculo de cantidad de salida-------------------------//
  const [cantidadSalida, setCantidadSalida] = useState(0);

  useEffect(() => {
    setCantidadSalida(
      almacenConArt && almacenConArt[0]
        ? almacenConArt[0].cantidad - datosTarea.cantidad
        : null
    );
  }, [almacenConArt, datosTarea]);

  // console.log(
  //   "datos art cantidad",
  //   almacenConArt && almacenConArt[0] ? almacenConArt[0].cantidad : null
  // );
  // console.log("datos tarea cant", datosTarea.cantidad);
  // console.log("resta", cantidadSalida);

  //---------------------- funcion mail ---------------------- //

  const envioMail = async (e) => {
    usuarioCompras &&
      usuarioCompras.length > 0 &&
      usuarioCompras.map((usuario) => {
        if (usuario.email) {
          if (
            cantidadSalida < almacenConArt[0].alerta_minima &&
            cantidadSalida > almacenConArt[0].valor_minimo
          ) {
            // console.log("EMAIL de CONSUMO", usuario.email);
            enviarmails(
              usuario.email,
              "Advertencia de Nivel de Stock ",
              `<p>El artículo <b>${almacenConArt[0].articulo}</b> del almacén <b>${almacenConArt[0].almacen}</b> esta por debajo del punto de pedido.<br/></p>`
            );
          } else if (cantidadSalida < almacenConArt[0].valor_minimo) {
            enviarmails(
              usuario.email,
              "Alerta de Stock por Debajo del Nivel Mínimo",
              `<p>El artículo <b>${almacenConArt[0].articulo}</b> del almacén <b>${almacenConArt[0].almacen}</b> esta por debajo del stock mínimo.<br/></p>`
            );
          }
        }
      });
  };

  const [envioRequerimiento, setEnvioRequerimiento] = useState({
    id_movil: null,
    id_sucursal: localStorage.getItem("sucursal"),
    fecha_entrega: moment(consumo.fecha_transaccion).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    usuario_registra: localStorage.getItem("user"),
    usuario_autoriza: null,
    estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
    para_comprar: 1,
    observaciones: "",
    uuid_requerimiento: uuid,
    comentarios_internos: "Pedido Automático - Definir Cantidad",
    para_compras: 0,
    para_rrhh: 0,
    autorizado_jefe_compras: 0,
  });
  // console.log("envio requerimiento", envioRequerimiento);

  const [datosLista, setDatosLista] = useState([
    {
      material: null,
      usuario: localStorage.getItem("user"),
      cantidad: 1,
      // id_unidad_medida: datosArt.id_unidad_medida,
      uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
      id_articulo: articuloSeleccionado.id_articulo,
    },
  ]);

  // console.log("datos lista", datosLista);

  useEffect(() => {
    setDatosLista([
      {
        material: null,
        usuario: localStorage.getItem("user"),
        cantidad: 1,
        // id_unidad_medida: datosArt.id_unidad_medida,
        uuid_requerimiento: envioRequerimiento.uuid_requerimiento,
        id_articulo: articuloSeleccionado.id_articulo,
      },
    ]);
    setEnvioRequerimiento({
      id_movil: null,
      id_sucursal: localStorage.getItem("sucursal"),
      fecha_entrega: moment(consumo.fecha_transaccion).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      usuario_registra: localStorage.getItem("user"),
      usuario_autoriza: null,
      estado: 50, //cuando se carga el pedido se pone en estado "A autorizar"
      para_comprar: 1,
      observaciones: "",
      uuid_requerimiento: uuid,
      comentarios_internos: "Pedido Automático - Definir Cantidad",
      para_compras: 0,
      para_rrhh: 0,
      autorizado_jefe_compras: 0,
    });
  }, [sucursales, articuloSeleccionado]);

  //------------- funciones guardar -----------------------//

  const guardarListaMateriales = (e) => {
    postData(
      e,
      "/lista_materiales",
      {
        datosLista,
      },
      setNotificacion
    );
  };

  const guardarConsumo = async (e) => {
    // logEventos("Guardar consumo", "Stock Sucursales", "Se guardo el consumo", localStorage.getItem("user"))
    console.log("Estoy por guardar");
    e.preventDefault();
    await fetch(UrlApi + "/stock_transacciones", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_accion: 200,
        id_articulo: articuloSeleccionado.id_articulo,
        almacen_origen: almacenSeleccionado.id_almacen,
        cantidad_enviada: datosTarea.cantidad,
        fecha_transaccion: moment(consumo.fecha_transaccion).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        usuario: localStorage.getItem("user"),
      }),
    })
      .then((res) => {
        if (!res.err) {
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("El consumo se guardó correctamente!");
          console.log("Guardado");
        } else {
          setOpenSnack(false);
          setColorMensaje("#F1948A");
          setMensaje("Error al guardar el consumo");
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    await fetch(
      UrlApi + "/stock_inventarios/" + almacenConArt[0].id_inventario,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cantidad: cantidadSalida,
          usuario: localStorage.getItem("user"),
        }),
      }
    )
      .then((res) => {
        if (!res.err) {
          console.log("Guardado");
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    if (
      cantidadSalida < almacenConArt[0].alerta_minima &&
      cantidadSalida >= almacenConArt[0].valor_minimo
    ) {
      console.log("ADVERTENCIA AMARILLA");
      if (almacenConArt[0].pedido_automatico === 1 && reqExiste.length === 0) {
        console.log("ES AUTOMATICO AMARILLO");
        postData(
          e,
          "/requerimientos_materiales",
          {
            id_sucursal: envioRequerimiento.id_sucursal,
            id_movil: envioRequerimiento.id_movil,
            estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
            para_comprar: envioRequerimiento.para_comprar,
            para_compras: envioRequerimiento.para_compras,
            para_rrhh: envioRequerimiento.para_rrhh,
            fecha_entrega: envioRequerimiento.fecha_entrega,
            usuario_registra: envioRequerimiento.usuario_registra,
            usuario_autoriza: envioRequerimiento.username,
            observaciones: envioRequerimiento.observaciones,
            uuid_requerimiento: uuid,
            comentarios_internos: envioRequerimiento.comentarios_internos,
            autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e)
        )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    } else if (cantidadSalida < almacenConArt[0].valor_minimo) {
      console.log("ADVERTENCIA ROJA");
      if (almacenConArt[0].pedido_automatico === 1 && reqExiste.length === 0) {
        postData(
          e,
          "/requerimientos_materiales",
          {
            id_sucursal: envioRequerimiento.id_sucursal,
            id_movil: envioRequerimiento.id_movil,
            estado: 50, //cuando se carga el pedido se pone en estado "Pendiente autorizacion"
            para_comprar: envioRequerimiento.para_comprar,
            para_compras: envioRequerimiento.para_compras,
            para_rrhh: envioRequerimiento.para_rrhh,
            fecha_entrega: envioRequerimiento.fecha_entrega,
            usuario_registra: envioRequerimiento.usuario_registra,
            usuario_autoriza: envioRequerimiento.username,
            observaciones: envioRequerimiento.observaciones,
            uuid_requerimiento: uuid,
            comentarios_internos: envioRequerimiento.comentarios_internos,
            autorizado_jefe_compras: 0,
          },
          setNotificacion,
          guardarListaMateriales(e)
        )
          .then((response) => {
            console.log("Respuesta de postData en guardarCabecera:", response);
          })
          .catch((error) => {
            console.error("Error en postData en guardarCabecera:", error);
          });
      }
    }
    envioMail();
    handleClose();
  };

  const guardarTarea = async (e) => {
    // logEventos(
    //   "Guardar nuevo registro",
    //   "Cumpleaños",
    //   "Se guardo nuevo registro",
    //   localStorage.getItem("user")
    // );
    setDisabledGuardar(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/tanque_tarea", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tanque: datosTarea.id_tanque,
        tarea_desde: datosTarea.tarea_desde,
        tarea_hasta: datosTarea.tarea_hasta,
        detalle_tarea: datosTanque.definicion_item,
        motivo_tarea: datosTanque.nombre_item,
        lugar_tarea: datosTarea.lugar_tarea,
        encargado: datosTarea.encargado,
        finalizada: estadoTarea,
        proviene: 1,
        repuesto: articuloSeleccionado.id_articulo,
        cantidad: datosTarea.cantidad,
        almacen: almacenSeleccionado.id_almacen,
        usuario: localStorage.getItem("user"),
      }),
    });
    if (response.ok === false) {
      console.log("ERROR EN API");
      const error = response && response.json();
      setError(error);
      setSeverityAlerta("error");
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      console.log("Conexión con api OK");
      try {
        //Parseo respuesta de la api a json()
        const json = await response.json();
        //si api me devuelve lo q le puse en message
        if (json.message) {
          //Si ese mensaje es "OK"
          if (json.message === "OK") {
            console.log("Guardado Satisfactoriamente");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setMensaje("Guardado");
            setSeveritySnack("success");
          } else {
            console.log("res en api incorrecto. Revisar que sea 'OK'");
            setNotificacion(true);
            setOpenSnack(true);
            setColorMensaje("#D4EFDF");
            setSeveritySnack("error");
            setMensaje("Registro Guardado. REVISAR RESPUESTA DE API (E_S)");
          }
        } else if (json.sqlMessage) {
          json && setError(json);
          console.log(json);
          setMensajeAlerta(
            "SQL erróneo (" + json.code + " Errno: " + json.errno + " )"
          );
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          console.log(
            "Guardado. Advertencia: Actualizar respuesta de api. JSON invalido."
          );
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado");
          setSeveritySnack("success");
        } else {
          setMensajeAlerta("ERROR DESCONOCIDO.");
        }
      }
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={props.tarea}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Title
          titulo={`Cargar nueva tarea`}
          handleClose={() => {
            handleClose();
          }}
        />
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
        >
          <DialogContent>
            <Container maxWidth="xl">
              <form onSubmit={guardarTarea} autoComplete="off">
                <Grid
                  container
                  spacing={2}
                  marginTop={-3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Tarea desde"
                      name="tarea_desde"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTarea.tarea_desde).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={style}
                      label="Tarea hasta"
                      name="tarea_hasta"
                      size="small"
                      type="date"
                      focused
                      required
                      fullWidth
                      value={moment(datosTarea.tarea_hasta).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="lugar_tarea"
                      label="Lugar"
                      variant="outlined"
                      value={datosTarea.lugar_tarea}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      size={size}
                      style={style}
                      focused
                      color="primary"
                      required
                      name="motivo_tarea"
                      label="Motivo"
                      variant="outlined"
                      value={datosTanque.nombre_item}
                      InputProps={{
                        readOnly: true,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      size={size}
                      style={style}
                      focused
                      color="primary"
                      required
                      name="detalle_tarea"
                      label="Detalle"
                      variant="outlined"
                      value={datosTanque.definicion_item}
                      InputProps={{
                        readOnly: true,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      // disabled={!grupos}
                      disableClearable
                      size="small"
                      required
                      noOptionsText={"Repuesto INEXISTENTE"}
                      options={artSuc}
                      style={style}
                      autoHighlight
                      getOptionLabel={(rep) => rep.nombre}
                      onChange={(event, value) =>
                        setArticuloSeleccionado(value)
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.nombre}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          label="Repuesto utilizado"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      // disabled={!grupos}
                      disableClearable
                      size="small"
                      required
                      noOptionsText={"Almacén INEXISTENTE"}
                      options={almacenConArt}
                      style={style}
                      autoHighlight
                      getOptionLabel={(alm) => alm.almacen}
                      onChange={(event, value) => setAlmacenSeleccionado(value)}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.id}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.almacen}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          focused
                          size="small"
                          placeholder="Tipee para buscar..."
                          label="Almacén"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="encargado"
                      label="Persona que llevo a cabo la tarea"
                      variant="outlined"
                      value={datosTarea.encargado}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                    />
                  </Grid>

                  <Grid item xs={3.5}>
                    <TextField
                      fullWidth
                      size={size}
                      placeholder="....."
                      style={style}
                      focused
                      color="primary"
                      required
                      name="cantidad"
                      label="Cantidad usada"
                      variant="outlined"
                      value={datosTarea.cantidad}
                      inputProps={{
                        maxLength: 45,
                      }}
                      onChange={almacenar}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  {articuloSeleccionado && almacenSeleccionado ? (
                    <Grid item xs={2.5}>
                      <TextField
                        disabled
                        fullWidth
                        size={size}
                        placeholder="0"
                        style={style}
                        focused
                        color="primary"
                        name="cantidad"
                        label="Cantidad"
                        variant="outlined"
                        value={
                          almacenConArt.length > 0
                            ? almacenConArt[0].cantidad
                            : ""
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={6}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography>No Finalizada</Typography>
                      <AntSwitch
                        checked={estadoTarea === 1} // El switch estará en 'checked' si estadoTarea es 1
                        onChange={(e) => {
                          const nuevoEstado = e.target.checked ? 1 : 0;
                          setEstadoTarea(nuevoEstado);
                          setDatosTarea({
                            ...datosTarea,
                            finalizada: nuevoEstado,
                          });
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Finalizada</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction="row">
                  <Grid container direction={{ xs: "column", sm: "row" }}>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Volver
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          guardarConsumo(e);
                          guardarTarea(e);
                        }}
                        disabled={disabledGuardar}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Container>
          </DialogContent>
        </Box>
      </Dialog>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          finalizar={setFinalizar}
          severity={severitySnack}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={severityAlerta} />
      ) : null}
    </div>
  );
}

export default Agregar_tarea_check;
